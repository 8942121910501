import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styles from './error-page.module.css';

export const ErrorPage = ({ message }) => (
  <div className={styles.wrapper}>
    <div className={styles.notfound}>
      <h1 className={styles.notfound404}>
          4<span>0</span>4
      </h1>
      {message && (<p>{message}</p>)}
      {!message && (
        <p>
          The page you are looking for might have been removed had its name changed or is temporarily unavailable.
        </p>
      )}
      <Link className={styles.link} to="/login">Login</Link>
    </div>
  </div>
);

ErrorPage.propTypes = {
  message: PropTypes.string,
};
