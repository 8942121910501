import React, { useState, useEffect } from 'react';
import classNames from 'clsx';
import PropTypes from 'prop-types';
import { Header } from '../header';
import { Button } from '../button';
import { useUser } from '../../utils/use-user';
import styles from './projects-list.module.css';
import { ProjectsListItem } from './projects-list-item';

export const ProjectsList = ({ projects }) => {
  const [projectsToShow, setProjectsToShow] = useState(projects);
  const [searchTerm, setSearchTerm] = useState('');
  const [showOnlyFavorite, setShowOnlyFavorite] = useState(false);
  const { favoriteProjects, updateUser } = useUser(true);

  const handleSearch = (event) => {
    const searchString = event.target.value.toLowerCase();
    setSearchTerm(searchString);
  };

  const handleShowOnlyFavoriteChange = () => {
    setShowOnlyFavorite((prevShowOnlyFavorite) => !prevShowOnlyFavorite);
  };

  useEffect(() => {
    let newProjectsToShow = projects;
    if (showOnlyFavorite) {
      newProjectsToShow = newProjectsToShow.filter(({ id }) => favoriteProjects.includes(id));
    }

    if (searchTerm) {
      newProjectsToShow = newProjectsToShow.filter(({ name, product, program }) =>
        name.toLowerCase().includes(searchTerm) ||
        product.toLowerCase().includes(searchTerm) ||
        program.toLowerCase().includes(searchTerm)
      );
    }

    setProjectsToShow(newProjectsToShow);
  }, [projects, searchTerm, favoriteProjects, showOnlyFavorite]);

  return (
    <>
      <Header
        title="Project WorkSpace"
        renderBottom={() => (
          <div className={styles.search}>
            <div className={styles.inputTitle}>
              <h4>Search</h4>
            </div>
            <div className={styles.searchInput}>
              <input
                placeholder="Search projects"
                onChange={handleSearch}
                type="text"
                data-testid="projects-search"
              />
            </div>
            <div className={styles.filter}>
              <label className={styles.filterLabel}>
                <input
                  type="checkbox"
                  onChange={handleShowOnlyFavoriteChange}
                  checked={showOnlyFavorite}
                  className={styles.filterCheckbox}
                  data-testid="favorite-filter"
                />
                Show only favorite
              </label>
            </div>
          </div>
        )}
        renderRightColumn={() => (
          <div className={styles.navWrapper}>
            <Button href="/editor/projects/new" data-testid="add-project" variant="outline-secondary">
              <i className="fa fa-plus" /> Add
            </Button>
          </div>
        )}
      />

      <div className={classNames({
        [styles.projectsList]: projectsToShow.length > 0,
      })} data-testid="projects-list">
        {(projects.length > 0 && projectsToShow.length === 0) && (
          <div className={styles.noResults}>
            <h2>No projects found</h2>
          </div>
        )}

        {projectsToShow.map(({ id, name, attributes }) => (
          <ProjectsListItem
            key={id}
            id={id}
            name={name}
            attributes={attributes}
            isFavorite={favoriteProjects?.includes(id)}
            onFavoriteToggle={updateUser}
          />
        ))}
      </div>
    </>
  );
};

ProjectsList.propTypes = {
  projects: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    program: PropTypes.string,
    project: PropTypes.string,
  })),
  onOrderButtonClick: PropTypes.func,
};

ProjectsList.defaultProps = {
  projects: [],
};
