import React, { useState, useEffect, useMemo } from 'react';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import omit from 'lodash.omit';
import classNames from 'clsx';
import { NotificationManager } from 'react-notifications';
import { useMutation, useQuery } from '@apollo/client';
import { Button } from '../../components/button';
import { IconTextButton } from '../../components/icon-text-button';
import { isEmpty } from '../../utils/is-empty';
import { Spinner } from '../../components/spinner';
import { Header } from '../../components/header';
import { PageTitle } from '../../components/page-title';
import { ExpandableHighlights } from '../../components/expandable-highlights';
import { Tag, VARIANTS as TAG_VARIANTS } from '../../components/tag';
import { useUser } from '../../utils/use-user';
import { useImperativeQuery } from '../../utils/use-imperative-query';
import {
  GET_PROJECT,
  GET_REPORTS_FOR_PROJECT,
  ADD_RESULTS_SECTION,
  ADD_REPORT,
  DELETE_REPORT,
  DELETE_SECTION,
  ADD_KEY_VALUES_SECTION,
  ADD_MAIN_RISKS_SECTION,
  ADD_PERFORMANCE_SECTION,
  ADD_RESULTS_UPLOAD_SECTION,
} from './queries';
import { sortByDate } from './helpers';
import styles from './reports-page.module.css';

dayjs.extend(localizedFormat);

export const ReportsPage = ({ match, history }) => {
  const { projectId } = match.params;
  const { user } = useUser();

  const [isLoading, setIsLoading] = useState(false);
  const [isUserReportsShown, setIsUserReportsShown] = useState(false);
  const [reports, setReports] = useState([]);
  const [allReports, setAllReports] = useState([]);

  const { data: projectData, loading: projectLoading } = useQuery(GET_PROJECT, {
    fetchPolicy: 'cache-and-network',
    variables: {
      id: projectId,
    },
  });
  const { data: reportData, loading: reportsLoading } = useQuery(GET_REPORTS_FOR_PROJECT, {
    fetchPolicy: 'cache-and-network',
    variables: {
      id: projectId,
    },
  });
  const project = projectData?.getProject || {};
  const queryReports = useMemo(() => reportData?.getReportsForProject?.items, [reportData]);

  const [deleteSection] = useMutation(DELETE_SECTION);
  const [deleteReport] = useMutation(DELETE_REPORT);
  const [addPerformanceSection] = useMutation(ADD_PERFORMANCE_SECTION);
  const [addKeyValuesSection] = useMutation(ADD_KEY_VALUES_SECTION);
  const [addMainRisksSection] = useMutation(ADD_MAIN_RISKS_SECTION);
  const [addResultsSection] = useMutation(ADD_RESULTS_SECTION);
  const [addResultsUploadSection] = useMutation(ADD_RESULTS_UPLOAD_SECTION);
  const [addReport] = useMutation(ADD_REPORT);
  const [reloadReportsQuery, { loading: reportsReloading }] = useImperativeQuery(GET_REPORTS_FOR_PROJECT, {
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (queryReports?.length) {
      const extendedReports = queryReports.map((report) => ({
        ...report,
        isRemoved: report.isRemoved === null ? false : report.isRemoved,
        isLocked: report.isLocked === null ? false : report.isLocked,
      }));
      setReports(sortByDate(extendedReports));
      setAllReports(sortByDate(extendedReports));
    }
  }, [queryReports]);

  const reloadReports = async () => {
    const { data: reports } = await reloadReportsQuery({ variables: { id: projectId } });
    const extendedReports = reports?.getReportsForProject?.items.map((report) => ({
      ...report,
      isRemoved: report.isRemoved === null ? false : report.isRemoved,
      isLocked: report.isLocked === null ? false : report.isLocked,
    }));
    setReports(sortByDate(extendedReports));
    setAllReports(sortByDate(extendedReports));
  };

  const handleCreateNewReport = () => {
    history.push(`/editor/projects/${projectId}/reports/new`);
  };

  const unPublishReport = async (report) => {
    let reportSummary = {
      id: report.id,
      projectId: report.projectId,
      highlights: report.highlights,
      weekHighlight: report.weekHighlight,
      status: report.status,
      product: report.product,
      program: report.program,
      date: report.date,
      name: report.name,
      isRemoved: true,
      isLocked: false,
    };
    await addReport({ variables: { reportData: reportSummary } });
    await reloadReports();
    NotificationManager.warning('Report was un-published successfully', 'Done');
  };

  const unLockReport = async (report) => {
    if (window.confirm('Are you sure to unlock this report so others can edit it?')) {
      if (!report?.isLocked || (report.isLocked && report.metadata.lastModifiedBy === user.email)) {
        let reportSummary = {
          id: report.id,
          projectId: report.projectId,
          highlights: report.highlights,
          weekHighlight: report.weekHighlight,
          status: report.status,
          product: report.product,
          program: report.program,
          date: report.date,
          isRemoved: report.isRemoved,
          name: report.name,
          isLocked: false,
        };
        await addReport({ variables: { reportData: reportSummary } });
        NotificationManager.warning('Report was un-locked successfully', 'Done');
        await reloadReports();
      } else {
        NotificationManager.error('Report can be unlocked only by ' + report.metadata.lastModifiedBy, 'Sorry');
      }
    }
  };

  const deleteReportFromDB = async (report) => {
    setIsLoading(true);
    await Promise.all([
      deleteSection({ variables: { id: `${report.id}_PERFORMANCE` } }),
      deleteSection({ variables: { id: `${report.id}_KEYFEATURES` } }),
      deleteSection({ variables: { id: `${report.id}_KEYVALUES` } }),
      deleteSection({ variables: { id: `${report.id}_MAINRISKS` } }),
      deleteSection({ variables: { id: `${report.id}_RESULTS` } }),
      deleteSection({ variables: { id: `${report.id}_RESULTS_UPLOAD` } }),
      deleteReport({ variables: { id: report.id } }),
    ]);
    await reloadReports();
    setIsLoading(false);
    NotificationManager.info('Report was removed successfully', 'Done');
  };

  const publishReport = async (report) => {
    let reportSummary = {
      id: report.id,
      projectId: report.projectId,
      highlights: report.highlights,
      weekHighlight: report.weekHighlight,
      status: report.status,
      product: report.product,
      program: report.program,
      date: report.date,
      name: report.name,
      isRemoved: false,
      isLocked: false,
    };
    await addReport({ variables: { reportData: reportSummary } });
    await reloadReports();
    NotificationManager.info('Report was published successfully', 'Done');
  };

  const isReportLockedByCurrentUser = (report) => report.isLocked && report.metadata.lastModifiedBy === user.email;
  const canReportBeEditedByCurrentUser = (report) => !report.isLocked || isReportLockedByCurrentUser(report);
  const generateCopyReportName = (report) => report?.name ? `${report.name}_copy` : `${report.date}_copy`;

  const handleReportCopyClick = async (report) => {
    let copyReport = omit(JSON.parse(JSON.stringify(report)), 'id');
    copyReport.name = generateCopyReportName(copyReport);
    await saveAllSections(copyReport);
  };

  const handleReportCopyView = (report) => {
    history.push(`/report/${report.id}`);
  };

  const handleReportClick = async (report, action) => {
    const canBeChanged = canReportBeEditedByCurrentUser(report);
    if (!canBeChanged) {
      NotificationManager.error('Report is currently being edited by ' + report.metadata.lastModifiedBy, 'Sorry');
      return;
    }

    if (action === 'UNPUBLISH') {
      await unPublishReport(report);
    } else if (action === 'EDIT') {
      history.push(`/editor/projects/${report.project.id}/reports/${report.id}/edit`);
    } else if (action === 'PUBLISH') {
      await publishReport(report);
    } else if (action === 'DELETE') {
      if (window.confirm('Are your sure to delete this report permanently?')) {
        await deleteReportFromDB(report);
      }
    }
  };

  const saveAllSections = async (reportToPublish) => {
    setIsLoading(true);
    let reportSummary = {
      attributeValues: reportToPublish.project?.attributes?.map(({ attributeCode, id }) => ({ attributeCode, attributeValue: id })),
      projectId: reportToPublish.projectId,
      highlights: reportToPublish.highlights,
      weekHighlight: reportToPublish.weekHighlight,
      status: reportToPublish.status,
      product: reportToPublish.product,
      program: reportToPublish.program,
      date: reportToPublish.date,
      name: reportToPublish.name,
      isRemoved: true,
      isLocked: false,
    };

    const { data } = await addReport({ variables: { reportData: reportSummary } });
    const reportId = data?.addReport?.id;

    if (reportId) {
      if (reportToPublish.performanceSection) {
        let performanceDetails = JSON.parse(JSON.stringify(reportToPublish.performanceSection));
        delete performanceDetails.__typename;
        performanceDetails.items.map((item) => delete item.__typename);
        performanceDetails.id = `${reportId}_PERFORMANCE`;
        await addPerformanceSection({
          variables: {
            reportData: performanceDetails,
          },
        });
      }
      if (reportToPublish.keyValuesSection) {
        let keyValuesDetails = JSON.parse(JSON.stringify(reportToPublish.keyValuesSection));
        delete keyValuesDetails.__typename;
        keyValuesDetails.items.forEach((item) => {
          delete item.__typename;
          if (!isEmpty(item.valueDrops)) {
            item.valueDrops.forEach((valueDrop) => {
              delete valueDrop.__typename;
            });
          }
        });
        keyValuesDetails.id = `${reportId}_KEYVALUES`;
        await addKeyValuesSection({
          variables: {
            reportData: keyValuesDetails,
          },
        });
      }
      if (reportToPublish.mainRisksSection) {
        let mainRisksDetails = JSON.parse(JSON.stringify(reportToPublish.mainRisksSection));
        delete mainRisksDetails.__typename;
        mainRisksDetails.risks.map((item) => delete item.__typename);
        mainRisksDetails.id = `${reportId}_MAINRISKS`;
        await addMainRisksSection({
          variables: {
            reportData: mainRisksDetails,
          },
        });
      }
      if (reportToPublish.resultsSection) {
        let resultsDetails = JSON.parse(JSON.stringify(reportToPublish.resultsSection));
        delete resultsDetails.__typename;
        resultsDetails.results.forEach((result) => {
          delete result.__typename;
          result.items.forEach((item) => {
            delete item.__typename;
          });
        });
        resultsDetails.id = `${reportId}_RESULTS`;
        await addResultsSection({
          variables: {
            reportData: resultsDetails,
          },
        });
      }
      if (reportToPublish.resultsUploadSection) {
        let resultsUploadDetails = JSON.parse(JSON.stringify(reportToPublish.resultsUploadSection));
        delete resultsUploadDetails.__typename;
        resultsUploadDetails.details.forEach((detail) => {
          delete detail.__typename;
          delete detail.metadata.__typename;
        });
        resultsUploadDetails.id = `${reportId}_RESULTS_UPLOAD`;
        await addResultsUploadSection({
          variables: {
            reportData: resultsUploadDetails,
          },
        });
      }
    }
    setIsLoading(false);
    NotificationManager.info('Report was cloned successfully', 'Done Cloning');
    history.push(`/editor/projects/${reportToPublish.project.id}/reports/${reportId}/edit`);
  };

  const handleSearch = ({ target: { value } }) => {
    const searchString = value.toLowerCase();
    const activeReports = isUserReportsShown
    ? allReports.filter((report) => report.metadata.createdBy === user.email)
    : allReports;

    const searchedReports = activeReports.filter((report) =>
      report.date.toLowerCase().includes(searchString) ||
      report.highlights.toLowerCase().includes(searchString) ||
      report.metadata.lastModifiedBy.toLowerCase().includes(searchString) ||
      report.metadata.createdBy.toLowerCase().includes(searchString)
    );

    setReports(searchedReports);
  };

  const showUserReports = () => {
    if (!isUserReportsShown) {
      const userReports = allReports.filter(({ metadata }) => metadata.createdBy === user.email);
      setReports(userReports);
      setIsUserReportsShown(true);
    } else {
      setReports(allReports);
      setIsUserReportsShown(false);
    }
  };

  if (isLoading || projectLoading || reportsLoading || reportsReloading) {
    return <Spinner title="Please wait.." />;
  }

  return (
    <div className={styles.reportsPage}>
      <Header
        title={project?.name}
        backButtonHref={'/editor/projects'}
        renderBottom={() => (
          <div className={styles.search}>
            <div className={styles.inputTitle}>
              <h4>Search</h4>
            </div>
            <div className={styles.inputWrapper}>
              <input
                className={styles.searchInput}
                placeholder="Search by date/highlights/email"
                onChange={handleSearch}
                type="text"
              />
            </div>
            <div className={styles.actions}>
              <Button
                onClick={() => showUserReports(false)}
                variant={isUserReportsShown ? 'primary' : 'outline-primary'}
                className={classNames(styles.myReport, { [styles.activeMyReport]: isUserReportsShown })}
              >
                <i className="fa fa-id-badge" title="Created By Me" />
                <span className={styles.buttonText}>My Reports</span>
              </Button>
              <Button
                onClick={handleCreateNewReport}
                className={styles.buttonSuccess}
                variant="outline-success"
                data-testid="create-new"
              >
                <i className="fa fa-plus" title="Create new report" />
                <span className={styles.buttonText}>Create New</span>
              </Button>
            </div>
          </div>
        )}
      />

      {reports.length === 0 && (
        <div className={styles.noReportsWrapper}>
          <PageTitle title="No reports found" />
        </div>
      )}
      <div className={styles.reportsWrapper}>
        {reports.map((report) => (
          <div key={report.id} data-testid="report-card">
            <div className={styles.report}>
              <div className={styles.reportStatus}>
                <div className={styles.circleStatus} style={{ backgroundColor: report.status }} />
              </div>
              <div className={styles.reportInfo}>
                {report.name ? (
                  <span>
                    <span className={styles.reportTitle}>{report.name}</span>
                    <br />
                    <span className={styles.reportSubTitle}>{report.date}</span>
                  </span>
                ) : (
                  <span className={styles.reportTitle}>{report.date}</span>
                )}
                <span className={styles.chipsWrapper}>
                  <Tag variant={report.isRemoved ? TAG_VARIANTS.red : TAG_VARIANTS.green}>
                    {report.isRemoved ? 'unpublished' : 'published'}
                  </Tag>

                  {report.isLocked && (
                    <Tag onClick={() => unLockReport(report)}>
                      locked <i className="fa fa-lock" title="unlock" />
                    </Tag>
                  )}
                </span>

                <ExpandableHighlights highlights={report.highlights} />

                {report.metadata && (
                  <div>
                    <div className={styles.metaItem}>
                      <span className={styles.metaItemWrapper}>
                        <span className={styles.metaTitle}>Created By:</span>
                        <span className={styles.metaData}>{report.metadata.createdBy}</span>
                      </span>
                      <span className={styles.metaItemWrapper}>
                        <span className={styles.metaTitle}>On:</span>
                        <span className={styles.metaData}>
                          {dayjs(Number(report.metadata.createdOn)).format('ll LTS')}
                        </span>
                      </span>
                    </div>

                    <div className={styles.metaItem}>
                      <span className={styles.metaItemWrapper}>
                        <span className={styles.metaTitle}>Last Modified By:</span>
                        <span className={styles.metaData}>{report.metadata.lastModifiedBy}</span>
                      </span>
                      <span className={styles.metaItemWrapper}>
                      <span className={styles.metaTitle}>On:</span>
                        <span className={styles.metaData}>
                          {dayjs(Number(report.metadata.lastModified)).format('ll LTS')}
                        </span>
                      </span>
                    </div>
                  </div>
                )}
              </div>
              <div className={styles.buttonsWrapper}>
                <IconTextButton
                  title="Edit"
                  variant="outline-primary"
                  onClick={() => handleReportClick(report, 'EDIT')}
                  btnClassName={styles.buttonSuccessPrimary}
                >
                  <i className="fa fa-edit" />
                </IconTextButton>
                <IconTextButton
                  title="Copy"
                  variant="outline-success"
                  onClick={() => handleReportCopyClick(report)}
                >
                  <i className="fa fa-copy" />
                </IconTextButton>
                <IconTextButton
                  title="View"
                  variant="outline-secondary"
                  onClick={() => handleReportCopyView(report)}
                >
                  <i className="fa fa-eye" />
                </IconTextButton>
                <IconTextButton
                  title={report.isRemoved ? 'Publish' : 'Unpublish'}
                  variant={report.isRemoved ? 'outline-primary' : 'outline-danger'}
                  onClick={() => handleReportClick(report, report.isRemoved ? 'PUBLISH' : 'UNPUBLISH')}
                >
                  <i className={report.isRemoved ? 'fa fa-upload' : 'fa fa-ban'} />
                </IconTextButton>
                <IconTextButton
                  title="Delete"
                  variant="outline-danger"
                  onClick={() => handleReportClick(report, 'DELETE')}
                >
                  <i className="fa fa-trash" />
                </IconTextButton>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
