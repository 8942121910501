import gql from 'graphql-tag';

export const GET_ATTRIBUTES = gql`
  query getAttributes {
    getAttributes {
      attributes {
        id
        name
        values {
          id
          name
        }
      }
    }
  }
`;

export const ADD_REPORT = gql`
  mutation addReport($reportData: ReportRequest!) {
    addReport(reportData: $reportData) {
      id
      metadata {
        createdBy
      }
      project {
        id
      }
      attributes {
        id
        attributeCode
        attribute {
          id
          name
          values {
            id
            name
          }
        }
      }
    }
  }
`;

export const DELETE_SECTION = gql`
  mutation deleteSection($id: String!) {
    deleteSection(id: $id)
  }
`;
