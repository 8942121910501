export const pluralize = (number, singular, plural, none = '') => {
  switch (number) {
    case 0:
      return `${Boolean(none) ? none : 0} ${plural}`;
    case 1:
      return `${number} ${singular}`;
    default:
      return `${number} ${plural}`;
  }
};
