import React, { useEffect } from 'react';
import { useHistory, Prompt } from 'react-router-dom';
import { addWindowEventListener } from '../../utils/window-event-listener';

export const NavigationPrompt = ({ onNavigationBlock, shouldBlockPageUnload, redirectTo, isConfirmed }) => {
  const history = useHistory();

  const handleNavigationBlock = (nextLocation) => {
    if (isConfirmed) {
      return true;
    }
    onNavigationBlock(nextLocation);
    return false;
  };

  useEffect(() => {
    if (isConfirmed) {
      history.push(redirectTo);
    }
  }, [isConfirmed, redirectTo, history]);

  useEffect(() => {
    const handlePageUnload = (event) => {
      if (shouldBlockPageUnload) {
        event.preventDefault();
        event.returnValue = '';
      }
    };

    const removeUnloadListener = addWindowEventListener('beforeunload', handlePageUnload);

    return () => {
      removeUnloadListener();
    };
  }, [shouldBlockPageUnload]);

  return (
    <Prompt message={handleNavigationBlock} when={shouldBlockPageUnload} />
  );
};
