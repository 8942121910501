import React from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import { Field } from 'formik';

export const TextareaField = ({ name, minRows = 3, testId }) => (
  <Field name={name} type="hidden">
    {({ field: { value }, form: { setFieldValue } }) => (
      <TextareaAutosize
        value={value}
        minRows={minRows}
        maxRows={10}
        onChange={(event) => setFieldValue(name, event.target.value)}
        data-testid={testId}
      />
    )}
  </Field>
);
