import { NO_SHOW_LIST_LOCALSTORAGE_KEY } from './constants';

export const getNoShowList = () => {
  return localStorage.getItem(NO_SHOW_LIST_LOCALSTORAGE_KEY)?.split(',') || [];
};

export const isIdInNoShowList = (id) => {
  return getNoShowList().some((noShowId) => noShowId === id);
};

export const setNoShowList = (idList) => {
  localStorage.setItem(NO_SHOW_LIST_LOCALSTORAGE_KEY, Array.from(idList).join());
};

export const addIdToNoShowList = (id) => {
  const noShowList = new Set(getNoShowList());
  noShowList.add(id);
  setNoShowList(noShowList);
};

export const removeRestrictedCharacters = (id) => id.replace(/,/gi, '');
