import React from 'react';
import styles from './report-status-picker.module.css';

export const ReportStatusPicker = ({ value, colors, onChange }) => {
  const handleChange = (event) => {
    onChange(event.target.value);
  };

  return (
    <>
      {colors.map((color, index) => (
        <label key={index} className={styles.label}>
          <input
            className={styles.status}
            type="radio"
            name="colorPicker"
            value={color}
            checked={color === value}
            onChange={handleChange}
          />
          <span className={styles.customRadio} style={{ backgroundColor: color === value ? color : 'transparent', borderColor: color }}/>
        </label>
      ))}
    </>
  );
};
