import gql from 'graphql-tag';

export const REPORT_FRAGMENT = gql`
  fragment ReportFields on Report {
    id
    name
    projectId
    highlights
    weekHighlight
    status
    product
    program
    date
    isRemoved
    isClone
    name
    isLocked
    metadata {
      id
      lastModified
      createdOn
      createdBy
      lastModifiedBy
    }
    attributes {
      id
      attributeCode
      attribute {
        id
        name
        values {
          id
          name
        }
      }
    }
    project {
      id
      name
      product
      program
      attributes {
        id
        attributeCode
        attribute {
          id
          name
          values {
            id
            name
          }
        }
      }
      metadata {
        id
        lastModified
        createdOn
        createdBy
        lastModifiedBy
      }
    }
    performanceSection {
      id
      items {
        title
        values
      }
    }
    keyValuesSection {
      id
      items {
        title
        status
        date
        valueDrops {
          text
          status
        }
      }
    }
    keyFeaturesSection {
      id
      items {
        title
        features {
          key
          value
          progress
        }
        overallProgress
      }
    }
    mainRisksSection {
      id
      risks {
        risk
        impact
        helpNeeded
        isResolved
        description
        mitigation
        due
        owner
      }
    }
    resultsSection {
      id
      results {
        title
        items {
          key
          value1
          value2
          value3
          value1Color
          value2Color
          value3Color
          value1Query
        }
      }
    }
    resultsUploadSection {
      id
      details {
        id
        metadata {
          id
          lastModified
          createdOn
          createdBy
          lastModifiedBy
        }
        fileName
        fileAlias
        filePath
      }
    }
  }
`;
