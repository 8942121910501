import React from 'react';
import PropTypes from 'prop-types';
import ReactQuill from 'react-quill';
import { CONFIG, FORMATS } from './constans';
import 'react-quill/dist/quill.snow.css';
import './quill-editor.css';

export const QuillEditor = ({ value, placeholder, onChange, name }) => {
  const handleChange = (currentValue) => {
    onChange(name, currentValue);
  };

  return (
    <ReactQuill
      modules={CONFIG}
      formats={FORMATS}
      placeholder={placeholder}
      value={value}
      onChange={handleChange}
    />
  );
};

QuillEditor.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  placeholder: PropTypes.string,
};

QuillEditor.defaultProps = {
  value: '',
  placeholder: '',
};
