import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'clsx';
import { useMutation } from '@apollo/client';
import { TOGGLE_FAVORITE_PROJECT } from './queries';
import styles from './projects-list.module.css';

export const ProjectsListItem = ({ name, id, attributes, isFavorite, onFavoriteToggle }) => {
  const [toggleFavoriteProject, { loading }] = useMutation(TOGGLE_FAVORITE_PROJECT, {
    onCompleted: ({ toggleFavoriteProject: newFavoriteProjects }) => {
      onFavoriteToggle(newFavoriteProjects);
    },
  });

  const handleFavoriteButtonClick = () => {
    toggleFavoriteProject({
      variables: {
        projectId: id,
      },
    });
  };

  return (
    <div className={styles.projectGrid} data-testid="project">
      <div className={styles.projectsHeading}>
        <span data-testid="project-title">{name}</span>
        <Link
          to={`/editor/projects/${id}/edit`}
          className={classNames('fa fa-edit', styles.projectsEditButton)}
          title="Edit Project"
          data-testid="edit-project"
        />
        <button
          type="button"
          className={styles.favoriteButton}
          onClick={handleFavoriteButtonClick}
          disabled={loading}
          data-testid="favorite"
          title={isFavorite ? 'Remove from favorites' : 'Add to favorites'}
        >
          <span className={classNames('fa', {
            'fa-star': isFavorite && !loading,
            'fa-star-o': !isFavorite && !loading,
            'fa-spinner fa-pulse': loading,
          })}/>
        </button>
      </div>

      {Boolean(attributes?.length) && (
        <div className={styles.attributes}>
          {attributes.map(({ attribute, id }, index) => (
            <div key={index}>
              <i className={classNames('fa fa-briefcase', styles.icon)} />
              <span className={styles.reportDataTitle}>{attribute.name}:</span>
              {attribute.values.find(({ id: valueId }) => id === valueId )?.name || '-'}
            </div>
          ))}
        </div>
      )}

      <div className={styles.projectActions}>
        <div className={styles.projectActionWrapper}>
          <Link
            to={`/editor/projects/${id}/reports/new`}
            className={classNames(styles.projectButton, styles.btnNew)}
          >
            <i className="fa fa-plus" title="Create New Report" />
          </Link>
          <p className={styles.projectActionText}>
            New
          </p>
        </div>
        <div className={styles.projectActionWrapper}>
          <Link
            to={`/editor/projects/${id}/reports`}
            className={classNames(styles.projectButton, styles.btnReports)}
          >
            <i className="fa fa-edit" title="View Reports" />
          </Link>
          <p className={styles.projectActionText}>
            Reports
          </p>
        </div>
      </div>
    </div>
  );
};
