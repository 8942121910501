import React from 'react';
import { Header } from '../header';
import { ReportThreeDots } from '../report-three-dots';
import styles from './report-section-title.module.css';

export const ReportSectionTitle = ({
  showPreviousButton,
  prevReportClick,
  showNextButton,
  nextReportClick,
  projectName,
  reportShareClick,
  reportId,
  onExportClick,
  onReportSelect,
  isReportSelected,
  hasSelectedReports,
  isExportInProgress,
  onPrintClick,
}) => (
  <>
    <Header
      title={projectName}
      renderLeftColumn={() => (
        <div className={styles.nav}>
          {prevReportClick && (
            <button
              className={styles.prevButton}
              disabled={!showPreviousButton}
              onClick={prevReportClick}
              data-testid="prev"
            >
              <i className="fa fa-chevron-left" />
            </button>
          )}
          {nextReportClick && (
            <button
              className={styles.nextButton}
              disabled={!showNextButton}
              onClick={nextReportClick}
              data-testid="next"
            >
              <i className="fa fa-chevron-right" />
            </button>
          )}
        </div>
      )}
      renderRightColumn={() => (
        <div className={styles.actions}>
          <ReportThreeDots
            onExportClick={onExportClick}
            onShareClick={reportShareClick}
            reportId={reportId}
            onReportSelect={onReportSelect}
            isReportSelected={isReportSelected}
            hasSelectedReports={hasSelectedReports}
            isExportInProgress={isExportInProgress}
            onPrintClick={onPrintClick}
          />
        </div>
      )}
    />
  </>
);
