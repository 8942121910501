import React, { useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import { CONSTANTS } from '../../constants';
import { Spinner } from '../../components/spinner';
import { ProjectsList } from '../../components/projects-list';
import { useUser } from '../../utils/use-user';
import { buildSortedProjects } from '../../utils/build-sorted-projects';
import { GET_ALL_PROJECTS } from './queries';
import styles from './projects-workspace.module.css';

export const ProjectsWorkspace = ({ history }) => {
  const [allProjects, setAllProjects] = useState([]);
  const { user } = useUser();

  const [loadProjects, { data, loading }] = useLazyQuery(GET_ALL_PROJECTS, {
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (!user.groups || !user.groups.includes(CONSTANTS.USER_GROUP)) {
      history.push('/error-page');
    } else {
      loadProjects();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadProjects, history]);

  useEffect(() => {
    const projects = data?.getAllProjects?.items;
    const sortedIds = localStorage.getItem(CONSTANTS.LOCAL_STORAGE_ORDER_KEY)?.split(',');
    setAllProjects(buildSortedProjects(projects, sortedIds));
  }, [data]);

  if (loading) {
    return <Spinner title="Please wait..." />;
  }

  return (
    <div className={styles.workspace}>
      <ProjectsList projects={allProjects} />
    </div>
  );
};
