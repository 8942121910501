import React from 'react';
import styles from './report-results-section.module.css';

const colorMap = {
  red: '#ff0000',
  yellow: '#ff8e00',
  green: '#008000',
  black: '#000',
};

export const ReportResultsSection = ({ resultsData }) => (
  <div className={styles.results}>
    {resultsData.results.map(({ title, items }, index) => (
      <div key={index} className={styles.resultsCard}>
        <h2 className={styles.resultsTitle}>
          {title}
        </h2>
        <table>
          <thead>
            <tr>
              <th />
              <th>This Week</th>
              <th>VS Target</th>
              <th>VS Last Week</th>
            </tr>
          </thead>
          <tbody>
            {items.map(({ key, value1, value1Color, value2, value2Color, value3, value3Color }, valueIndex) => (
              <tr key={valueIndex} className={styles.tableDateRow}>
                <td>{key}</td>
                <td style={{ color: colorMap[value1Color.toLowerCase()] }}>
                  {value1}
                </td>
                <td style={{ color: colorMap[value2Color.toLowerCase()] }}>
                  {value2}
                </td>
                <td style={{ color: colorMap[value3Color.toLowerCase()] }}>
                  {value3}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    ))}
  </div>
);
