import { noop } from '../noop';

let events = {};

export const clearEvents = () => {
  events = {};
};

export const notifyListeners = (eventName) => (event) => {
  if (events[eventName]) {
    events[eventName].listeners.forEach((listener) => {
      try {
        if (events[eventName].listeners.includes(listener)) {
          listener(event);
        }
      } catch (e) {
        // TODO log error
      }
    });
  }
  return events;
};

export const addListener = (eventName, callback, useCapture) => {
  if(events[eventName]) {
    events[eventName].listeners.push(callback);
  } else {
    events[eventName] = {
      listeners: [callback],
      notifyListeners: notifyListeners(eventName),
    };
    window.addEventListener(eventName, events[eventName].notifyListeners, useCapture);
  }
  return events;
};

export const removeListener = (eventName, callback) => () => {
  if(events[eventName]) {
    events[eventName].listeners = events[eventName].listeners.filter((listener) => listener !== callback);

    if(events[eventName].listeners.length === 0) {
      window.removeEventListener(eventName, events[eventName].notifyListeners);
      events[eventName] = null;
    }
  }
  return events;
};

export const addWindowEventListener = (eventName, callback, useCapture) => {
  if (typeof window === 'undefined') {
    return noop;
  }
  addListener(eventName, callback, useCapture);
  return removeListener(eventName, callback);
};
