import React from 'react';
import classNames from 'clsx';
import { useMutation } from '@apollo/client';
import omit from 'lodash.omit';
import { Formik, Form, Field, ErrorMessage, FieldArray } from 'formik';
import { NotificationManager } from 'react-notifications';
import { Button } from '../button';
import { Spinner } from '../spinner';
import { ButtonsGroup } from '../buttons-group';
import { ValidationError } from '../validation-error';
import { StatusPicker } from '../status-picker';
import { StatusPickerLegend } from '../status-picker-legend';
import { STATUSES, UNKNOWN_STATUS } from '../../utils/statuses-constants';
import { CONSTANTS } from '../../constants';
import { InputDatepicker } from '../input-datepicker';
import { sortKeyValues } from './helpers';
import { ADD_KEY_VALUES_SECTION } from './queries';
import styles from './edit-report-key-values.module.css';

const DEFAULT_KEY_VALUE_ITEM = {
  title: '',
  status: '',
  date: '',
};

export const EditReportKeyValues = ({ keyValuesSection, reportId, onSave, onCancelClick, onChangesSkip }) => {
  const sectionId = keyValuesSection?.id || `${reportId}_KEYVALUES`;

  const [saveKeyValuesSection, { loading }] = useMutation(ADD_KEY_VALUES_SECTION);

  const handleValidation = ({ keyValues }) => {
    const errors = {};

    keyValues.forEach((keyValue, index) => {
      if (!keyValue.title) {
        errors[index] = {
          ...errors[index],
          title: CONSTANTS.REQUIRED_FIELD,
        };
      }

      // new keyValues
      if (!keyValue.valueDrops?.length && !keyValue.status) {
        errors[index] = {
          ...errors[index],
          status: CONSTANTS.REQUIRED_FIELD,
        };
      }
    });

    if (Object.keys(errors).length) {
      NotificationManager.error('Please enter all Required details', 'Sorry');
      return {
        keyValues: errors,
      };
    }

    return {};
  };

  const handleFormSubmit = async ({ keyValues }) => {
    const updatedKeyValues = keyValues.map((keyValue) => ({
      ...omit(keyValue, '__typename'),
      valueDrops: keyValue.valueDrops?.map((valueDrop) => omit(valueDrop, '__typename')),
    }));
    try {
      const newKeyValuesSection = {
        id: sectionId,
        items: updatedKeyValues,
      };
      await saveKeyValuesSection({
        variables: {
          reportData: newKeyValuesSection,
        },
      });
      onSave(newKeyValuesSection);
    } catch {
      NotificationManager.error('Error while saving key values section, please try again', 'Error');
    }
  };

  const initialValues = {
    keyValues: keyValuesSection.items?.sort(sortKeyValues) || [DEFAULT_KEY_VALUE_ITEM],
  };
  const statusIcons = STATUSES.reduce((acc, status) => {
    return {
      ...acc,
      [status.value]: status,
    };
  }, {});

  if (loading) {
    return <Spinner title="Please wait.." />;
  }

  return(
    <Formik
      initialValues={initialValues}
      onSubmit={handleFormSubmit}
      validate={handleValidation}
      enableReinitialize={true}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {({ values, setFieldValue }) => (
        <Form>
          <FieldArray
            name="keyValues"
            render={(arrayHelpers) => (
              <>
                <div className={styles.sectionHeading}>
                  <h3 className={styles.sectionTitle}>Key Values Section</h3>
                  <div className={styles.sectionActions}>
                    <Button
                      variant="outline-primary"
                      onClick={() => arrayHelpers.unshift(DEFAULT_KEY_VALUE_ITEM)}
                    >
                      Add More
                    </Button>
                  </div>
                </div>
                <div className={styles.wrapper}>
                  <div className={styles.legend}>
                    <StatusPickerLegend />
                    <span className="fa fa-info-circle" /> Key values without the date specified will be displayed in the "Backlog" group.
                  </div>
                  {values.keyValues.map(({ title, status, date, valueDrops }, index) => {
                    // WSR-30 introduced new key values format, but we keep legacy key values
                    if (valueDrops?.length > 0) {
                      return (
                        <div key={index} className={classNames(styles.keyValueOld, styles.keyValue)} data-testid="old-keyvalue">
                          <div className={styles.keyValueOldWarning}>This key value has old format and cannot be edited</div>
                          <div className={styles.deleteWrapper}>
                            <Button
                              variant="outline-danger"
                              onClick={() => arrayHelpers.remove(index)}
                              data-testid="remove-row"
                            >
                              <i className="fa fa-trash" title="Remove key value" />
                            </Button>
                          </div>
                          <div>
                            <Field name={`keyValues[${index}].title`} type="text" disabled />
                          </div>
                          {valueDrops.map(({ status, text }, dropIndex) => {
                            const statusIcon = statusIcons[status] || UNKNOWN_STATUS;
                            return (
                              <div key={dropIndex} className={styles.valueDropOld}>
                                <div className={styles.valueDropOldStatus}>
                                  <Field name={`keyValues[${index}].valueDrops[${dropIndex}].status`} value={valueDrops.status} type="hidden" />
                                  <div>Status</div>
                                  <div>
                                    <img className={classNames(styles.selectedStatusIcon, styles.selectedStatusIconDisabled)} alt={statusIcon?.title} src={statusIcon?.icon} title={statusIcon?.title} />
                                  </div>
                                </div>
                                <div className={styles.valueDropOldValue}>
                                  <div>Value</div>
                                  <div>
                                    <Field name={`keyValues[${index}].valueDrops[${dropIndex}].text`} type="text" disabled />
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      );
                    }

                    const STATUS_FIELD_NAME = `keyValues[${index}].status`;
                    const TITLE_FIELD_NAME = `keyValues[${index}].title`;
                    const DATE_FIELD_NAME = `keyValues[${index}].date`;

                    return (
                      <div key={index} className={classNames(styles.keyValue, styles.keyValueNew)} data-testid="new-keyvalue">
                        <div data-testid="status-field">
                          <label className={styles.label}>Status</label>
                          <div className={styles.status}>
                            <Field name={STATUS_FIELD_NAME} type="hidden">
                              {({ field: { value } }) => {
                                if (Boolean(value)) {
                                  const statusIcon = statusIcons[status] || UNKNOWN_STATUS;
                                  return (
                                    <img
                                      className={styles.selectedStatusIcon}
                                      alt={statusIcon?.title}
                                      src={statusIcon?.icon}
                                      title={statusIcon?.title}
                                      onClick={() => setFieldValue(STATUS_FIELD_NAME, '')}
                                    />
                                  );
                                }

                                return (
                                  <StatusPicker
                                    onSelectedCallback={(status) => setFieldValue(STATUS_FIELD_NAME, status)}
                                  />
                                );
                              }}
                            </Field>
                          </div>
                          <ErrorMessage name={STATUS_FIELD_NAME} component={ValidationError} />
                        </div>
                        <div className={styles.keyValueValue}>
                          <label className={styles.label}>Key Value</label>
                          <Field type="text" name={TITLE_FIELD_NAME} placeholder="Key Value" />
                          <ErrorMessage name={TITLE_FIELD_NAME} component={ValidationError} />
                        </div>
                        <div className={styles.keyValueDate}>
                          <label className={styles.label}>Date</label>
                          <Field type="hidden" name={DATE_FIELD_NAME}>
                            {({ field: { value } }) => (
                              <InputDatepicker
                                name={DATE_FIELD_NAME}
                                setFieldValue={setFieldValue}
                                value={value}
                                disallowUserInput
                                formatDisplayValue={(value) => value? (new Date(Number(value))).toLocaleDateString() : ''}
                                formatFieldValue={(date) => date.getTime().toString()}
                              />
                            )}
                          </Field>
                          <ErrorMessage name={DATE_FIELD_NAME} component={ValidationError} />
                        </div>
                        {values.keyValues?.length > 1 && (
                          <div className={styles.delete}>
                            <Button
                              variant="outline-danger"
                              onClick={() => arrayHelpers.remove(index)}
                              data-testid="remove-row"
                            >
                              <i className="fa fa-trash" title="Remove key value" />
                            </Button>
                          </div>
                        )}
                      </div>
                    );
                  })}
                  <ButtonsGroup onCancelClick={onCancelClick} onSkipClick={onChangesSkip} />
                </div>
              </>
            )}
          />
        </Form>
      )}
    </Formik>
  );
};
