import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { NotificationManager } from 'react-notifications';
import { ReportsView } from '../../components/report-view';
import { ReorderProjects } from '../../components/reorder-projects';
import { Spinner } from '../../components/spinner';
import calendarIcon from '../../images/calendar.png';
import { CONSTANTS } from '../../constants';
import { buildWeekDaysRange } from '../../utils/build-week-days-range';
import { convertDateToRetailWeek } from '../../utils/date-to-retail-week';
import { buildSortedProjects } from '../../utils/build-sorted-projects';
import { Header } from '../../components/header';
import { GET_REPORTS_BY_WEEK_AND_ATTRIBUTE, UPDATE_WEEK_HIGHLIGHT } from './queries';
import styles from './presentation-report-page.module.css';

export const PresentationReportPage = ({ location, history }) => {
  const urlParams = new URLSearchParams(location.search);
  const week = urlParams.get('week');
  const attributeValueId = urlParams.get('ids');

  const [allProjects, setAllProjects] = useState([]);
  const [sortedReports, setSortedReports] = useState([]);
  const [isReordering, setIsReordering] = useState(true);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const { data, loading } = useQuery(GET_REPORTS_BY_WEEK_AND_ATTRIBUTE, {
    fetchPolicy: 'cache-and-network',
    variables: {
      attributeValueId: attributeValueId.split(','),
      week,
    },
  });

  const [updateWeekHighlight, { data: newReport, loading: updateReportLoading }] = useMutation(UPDATE_WEEK_HIGHLIGHT, {
    onCompleted: () => {
      NotificationManager.success('Week highlight is updated', 'Success');
    },
  });

  const handleReportSave = (reportId, weekHighlight) => {
    updateWeekHighlight({ variables: { reportId, weekHighlight } });
  };

  useEffect(() => {
    if (newReport?.updateWeekHighlight) {
      const updatedReports = sortedReports.map((report) => {
        return report.id === newReport.updateWeekHighlight.id ? { ...report, ...newReport.updateWeekHighlight } : report;
      });
      setSortedReports(updatedReports);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newReport]);

  useEffect(() => {
    const reports = data?.getReportsByAttributeValueAndWeek?.items || [];
    const projects = [...new Set(reports.filter(({ report }) => !report.isRemoved).map(({ report }) => report.project))];
    const sortedIds = localStorage.getItem(CONSTANTS.LOCAL_STORAGE_ORDER_KEY)?.split(',');
    setAllProjects(buildSortedProjects(projects, sortedIds));
  }, [data]);

  const handleCancel = () => {
    history.push('presenter-filter/');
  };

  const handleProjectsReorder = (orderedProjects) => {
    const { items: reports } = data.getReportsByAttributeValueAndWeek;
    const orderedProjectsIds = orderedProjects.map(({ id }) => id);
    const sortedReports = [];
    orderedProjectsIds.forEach((currentId) => {
      const currentReports = reports
        .filter(({ report }) => !report.isRemoved)
        .filter(({ report }) => report.projectId === currentId)
        .map(({ report }) => report);
      sortedReports.push(...currentReports);
    });
    setSortedReports(sortedReports);
    setIsReordering(false);
  };

  const retailWeek = convertDateToRetailWeek(buildWeekDaysRange(week)[1]).formattedRetailWeek;

  if (loading || updateReportLoading) {
    return <Spinner title="Please wait..." />;
  }

  return (
    <>
      {isReordering ? (
        <div className={styles.reorder}>
          <Header title="Reorder Projects" renderRightColumn={() => (
            <button onClick={handleCancel} className={styles.calButton}>
              <img alt="calendar" src={calendarIcon} />
            </button>
          )}/>
          <ReorderProjects
            projects={allProjects}
            onSaveClick={handleProjectsReorder}
          />
        </div>
      ) : (
        <ReportsView
          reports={sortedReports}
          handleSaveReport={handleReportSave}
          retailWeek={retailWeek}
          onPrevClick={() => setSelectedIndex(selectedIndex - 1)}
          onNextClick={() => setSelectedIndex(selectedIndex + 1)}
          selectedIndex={selectedIndex}
        />
      )}
    </>
  );
};
