export const sortKeyValues = (a, b) => {
  if (!a.date) {
    return 1;
  }

  if (!b.date) {
    return -1;
  }

  return a.date - b.date;
};
