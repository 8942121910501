import React from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import omit from 'lodash.omit';
import { Formik, Form, Field, ErrorMessage, FieldArray } from 'formik';
import { NotificationManager } from 'react-notifications';
import { Button } from '../button';
import { ValidationError } from '../validation-error';
import { ButtonsGroup } from '../buttons-group';
import { InputDatepicker } from '../input-datepicker';
import { CONSTANTS } from '../../constants';
import { Spinner } from '../spinner';
import { TextareaField } from '../textarea-field';
import { ADD_MAIN_RISKS_SECTION } from './queries';
import styles from './edit-report-main-risks.module.css';

const DEFAULT_MAIN_RISK_ITEM = {
  risk: '',
  impact: 'RED',
  description: '',
  mitigation: '',
  due: '',
  owner: '',
  helpNeeded: null,
  isResolved: null,
};

export const EditReportMainRisks = ({ mainRisksSection, reportId, onSave, onCancelClick, onChangesSkip }) => {
  const sectionId = mainRisksSection?.id || `${reportId}_MAINRISKS`;

  const [saveMainRisksSection, { loading }] = useMutation(ADD_MAIN_RISKS_SECTION);

  const handleValidation = ({ risks }) => {
    const errors = {};
    const requiredFields = ['risk', 'impact', 'description', 'mitigation', 'due', 'owner'];

    risks.forEach((risk, index) => {
      requiredFields.forEach((requiredField) => {
        if (!risk[requiredField]) {
          errors[index] = {
            ...errors[index],
            [requiredField]: CONSTANTS.REQUIRED_FIELD,
          };
        }
      });
    });

    if (Object.keys(errors).length) {
      NotificationManager.error('Please enter all Required details', 'Sorry');
      return {
        risks: errors,
      };
    }

    return {};
  };

  const handleFormSubmit = async ({ risks }) => {
    const updatedRisks = risks.map((risk) => omit(risk, '__typename'));
    try {
      const newRisksSection =  {
        id: sectionId,
        risks: updatedRisks,
      };
      await saveMainRisksSection({
        variables: {
          reportData: newRisksSection,
        },
      });
      onSave(newRisksSection);
    } catch {
      NotificationManager.error('Error while saving main risks section, please try again', 'Error');
    }
  };

  let initialValues = {
    risks: mainRisksSection?.risks || [DEFAULT_MAIN_RISK_ITEM],
  };

  if (loading) {
    return <Spinner title="Please wait.." />;
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleFormSubmit}
      validate={handleValidation}
      enableReinitialize={true}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {({ values }) => (
        <Form>
          <FieldArray
            name="risks"
            render={(arrayHelpers) => (
              <>
                <div className={styles.sectionHeading}>
                  <h3 className={styles.sectionTitle}>Main Risks Section</h3>
                  <div className={styles.sectionActions}>
                    <Button
                      variant="outline-primary"
                      onClick={() => arrayHelpers.unshift(DEFAULT_MAIN_RISK_ITEM)}
                    >
                      Add More
                    </Button>
                  </div>
                </div>
                <div className={styles.wrapper}>
                  {values.risks.length > 0 && values.risks
                    .sort((a, b) => a.isResolved - b.isResolved)
                    .map(({ description, mitigation, due, owner }, index) => (
                    <div key={index} className={styles.riskItem}>
                      <div className={styles.riskItemActions}>
                        <Field name={`risks[${index}].isResolved`} type="hidden">
                        {({ field: { value }, form: { setFieldValue } }) => (
                          <Button
                            variant={value ? 'success' : 'outline-success'}
                            onClick={() => setFieldValue(`risks[${index}].isResolved`, !Boolean(value))}
                          >
                            <i className="fa fa-check-circle" title="Mark as resolved" />
                            <span className={styles.actionText}>Mark as resolved</span>
                          </Button>
                        )}
                        </Field>
                        {values.risks.length > 1 && (
                          <Button
                            variant="outline-danger"
                            onClick={() => arrayHelpers.remove(index)}
                            data-testid="remove-row"
                          >
                            <i className="fa fa-trash" title="Remove this Risk" />
                          </Button>
                        )}
                      </div>

                      <div className={styles.riskTitle} data-testid="table-row">
                        <div className={styles.label}>Risk Title (Required):</div>
                        <Field type="text" name={`risks[${index}].risk`} placeholder="Risk Title" />
                        <ErrorMessage name={`risks[${index}].risk`} component={ValidationError} />
                      </div>

                      <div className={styles.riskHelp}>
                        <div className={styles.label}>Help (Optional):</div>
                        <Field name={`risks[${index}].helpNeeded`} type="hidden">
                          {({ field: { value }, form: { setFieldValue } }) => (
                            <Button
                              variant={value ? 'danger' : 'outline-danger'}
                              onClick={() => setFieldValue(`risks[${index}].helpNeeded`, !Boolean(value))}
                              className={styles.helpButton}
                            >
                              <i className="fa fa-exclamation-circle" title="Help Needed" />
                              <span className={styles.actionText}>Help needed</span>
                            </Button>
                          )}
                        </Field>
                      </div>

                      <div className={styles.riskImpact}>
                        <div className={styles.label}>Impact (Required):</div>
                        <Field as="select" name={`risks[${index}].impact`}>
                          <option value="RED">Red</option>
                          <option value="YELLOW">Yellow</option>
                          <option value="GREEN">Green</option>
                        </Field>
                        <ErrorMessage name={`risks[${index}].impact`} component={ValidationError} />
                      </div>

                      <div className={styles.riskDueDate}>
                        <div className={styles.label}>Due Date (Required):</div>
                        <Field type="text" name={`risks[${index}].due`}>
                          {({ field: { value }, form: { setFieldValue } }) => (
                            <InputDatepicker
                              value={value}
                              name={`risks[${index}].due`}
                              setFieldValue={setFieldValue}
                            />
                          )}
                        </Field>
                        <ErrorMessage name={`risks[${index}].due`} component={ValidationError} />
                      </div>

                      <div className={styles.riskOwner}>
                        <div className={styles.label}>Owner (Required):</div>
                        <Field type="text" name={`risks[${index}].owner`} />
                        <ErrorMessage name={`risks[${index}].owner`} component={ValidationError} />
                      </div>

                      <div className={styles.riskDescription}>
                        <div className={styles.label}>Description (Required):</div>
                        <TextareaField name={`risks[${index}].description`} minRows={3} />
                        <ErrorMessage name={`risks[${index}].description`} component={ValidationError} />
                      </div>

                      <div className={styles.riskMitigation}>
                        <div className={styles.label}>Mitigation (Required):</div>
                        <TextareaField name={`risks[${index}].mitigation`} minRows={3} />
                        <ErrorMessage name={`risks[${index}].mitigation`} component={ValidationError} />
                      </div>

                    </div>
                  ))}
                </div>
              </>
            )}
          />
          <ButtonsGroup onCancelClick={onCancelClick} onSkipClick={onChangesSkip} />
        </Form>
      )}
    </Formik>
  );
};

EditReportMainRisks.propTypes = {
  mainRisksSection: PropTypes.shape({
    risks: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
    })),
  }),
  reportId: PropTypes.string.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancelClick: PropTypes.func.isRequired,
};

EditReportMainRisks.defaultProps = {
  mainRisksSection: {},
};
