import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '../button';
import styles from './buttons-group.module.css';

export const ButtonsGroup = ({ onCancelClick, onSaveClick, onSkipClick }) => (
  <div className={styles.actionsWrapper}>
    {onSkipClick && (
      <Button
        variant="info"
        className={styles.actionButton}
        onClick={onSkipClick}
      >
        <i className="fa fa-ban" title="Cancel changes" />
        <span className={styles.actionText}>Cancel</span>
      </Button>
    )}
    <Button
      variant="danger"
      className={styles.actionButton}
      onClick={onCancelClick}
    >
      <i className="fa fa-trash" title="Remove this section" />
      <span className={styles.actionText}>Remove</span>
    </Button>
    <Button
      variant="success"
      type="submit"
      className={styles.actionButton}
      onClick={onSaveClick}
    >
      <i className="fa fa-save" title="Save & Proceed" />
      <span className={styles.actionText}>Save</span>
    </Button>
  </div>
);

ButtonsGroup.propTypes = {
  onCancelClick: PropTypes.func.isRequired,
  onSkipClick: PropTypes.func,
  onSaveClick: PropTypes.func,
};

ButtonsGroup.defaultProps = {
  onSaveClick: () => {},
};
