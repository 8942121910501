import gql from 'graphql-tag';
import { REPORT_FRAGMENT } from '../../utils/fragments/report';

export const GET_PROJECT = gql`
  query($id: String!) {
    getProject(id: $id) {
      id
      name
      product
      program
      attributes {
        id
        attributeCode
      }
    }
  }
`;

export const GET_REPORTS_FOR_PROJECT = gql`
  query($id: String!) {
    getReportsForProject(projectId: $id) {
      items {
        ...ReportFields
      }
    }
  }
  ${REPORT_FRAGMENT}
`;

export const ADD_RESULTS_SECTION = gql`
  mutation addResultsSection($reportData: ResultsDataInput!) {
    addResultsSection(reportData: $reportData) {
      id
    }
  }
`;

export const ADD_REPORT = gql`
  mutation addReport($reportData: ReportRequest!) {
    addReport(reportData: $reportData) {
      id
      project {
        id
      }
      attributes {
        id
        attributeCode
        attribute {
          id
          name
          values {
            id
            name
          }
        }
      }
    }
  }
`;

export const DELETE_REPORT = gql`
  mutation deleteReport($id: String!) {
    deleteReport(id: $id)
  }
`;

export const DELETE_SECTION = gql`
  mutation deleteSection($id: String!) {
    deleteSection(id: $id)
  }
`;

export const ADD_KEY_VALUES_SECTION = gql`
  mutation addKeyValuesSection($reportData: KeyValuesDataInput!) {
    addKeyValuesSection(reportData: $reportData) {
      id
    }
  }
`;

export const ADD_MAIN_RISKS_SECTION = gql`
  mutation addMainRisksSection($reportData: MainRisksDataInput!) {
    addMainRisksSection(reportData: $reportData) {
      id
    }
  }
`;

export const ADD_PERFORMANCE_SECTION = gql`
  mutation addPerformanceSection($reportData: PerformanceDataInput!) {
    addPerformanceSection(reportData: $reportData) {
      id
    }
  }
`;

export const ADD_RESULTS_UPLOAD_SECTION = gql`
  mutation addResultsUploadSection($reportData: ResultsUploadDataInput!) {
    addResultsUploadSection(reportData: $reportData) {
      id
    }
  }
`;
