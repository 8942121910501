import React from 'react';
import classNames from 'clsx';
import { Button } from '../button';
import styles from './confirm-publish-modal.module.css';

export const ConfirmPublishModal = ({ onCancel, onPublish, onUnlock }) => {
  return (
    <div className={styles.wrapper} data-testid="modal">
      <div>You're leaving the report without publishing. Other users won't be able to edit it. How would you like to proceed?</div>
      <div className={styles.actions}>
        <Button variant="danger" onClick={onCancel} className={styles.action} data-testid="cancel">
          <span className={classNames(styles.actionIcon, 'fa fa-ban')} />
          Keep unpublished and locked
        </Button>
        <Button variant="info" onClick={onUnlock} className={styles.action} data-testid="unlock">
          <span className={classNames(styles.actionIcon, 'fa fa-close')} />
          Unlock, but keep unpublished
        </Button>
        <Button variant="success" onClick={onPublish} className={styles.action} data-testid="publish">
          <span className={classNames(styles.actionIcon, 'fa fa-check')} />
          Publish & Unlock
        </Button>
      </div>
    </div>
  );
};
