const DAY_IN_MS = 24 * 60 * 60 * 1000;
const WEEK_IN_MS = 7 * DAY_IN_MS;
const FISCAL_YEAR_START_WEEK_DAY_THRESHOLD = 5;

const getFirstWeekDay = (date) => {
  const currentDate = new Date(date);
  const firstWeekDayDiff = currentDate.getDate() - currentDate.getDay();
  return new Date(currentDate.setDate(firstWeekDayDiff));
};

const getFiscalYearFirstWeekDay = (date) => {
  const weekDay = date.getDay();
  const firstWeekDay = getFirstWeekDay(date);
  if (weekDay < FISCAL_YEAR_START_WEEK_DAY_THRESHOLD) {
    return firstWeekDay;
  } else {
    return new Date(firstWeekDay.setDate(firstWeekDay.getDate() + 7));
  }
};

export const convertDateToRetailWeek = (date) => {
  const year = date.getFullYear();
  const fiscalYearEdge = new Date(`June 1 ${year} 00:00:00`);
  const fiscalYearEdgeFirstWeekDay = getFiscalYearFirstWeekDay(fiscalYearEdge);

  const isNewFiscalYear = date.getTime() >= fiscalYearEdgeFirstWeekDay.getTime();
  const fiscalYear = isNewFiscalYear ? year + 1 : year;
  const fiscalYearNumber = String(fiscalYear).slice(2,4);

  const fiscalYearStart = getFiscalYearFirstWeekDay(new Date(`June 1 ${fiscalYear - 1} 00:00:00`));
  const dateDifference = (date.getTime() - fiscalYearStart.getTime()) || 1;
  const weekNumber = Math.ceil(Math.abs(dateDifference / WEEK_IN_MS));

  return {
    retailWeek: weekNumber,
    formattedRetailWeek: `WK${String(weekNumber).padStart(2, '0')}`,
    fiscalYear: fiscalYear,
    formattedFiscalYear: `FY${fiscalYearNumber}`,
    fullRetailWeek: `FY${fiscalYearNumber} WK${String(weekNumber).padStart(2, '0')}`,
  };
};
