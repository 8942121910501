import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useOktaAuth } from '@okta/okta-react';
import { CONSTANTS } from '../../constants';
import { Logo } from '../../components/logo';
import { Modal } from '../../components/modal';
import { CustomButton } from '../../components/custom-button';
import { useUser } from '../../utils/use-user';
import styles from './home-page.module.css';

export const HomePage = () => {
  const { user } = useUser();
  const { oktaAuth } = useOktaAuth();
  const [isModalShown, setIsModalShown] = useState(false);

  const isUserGroup = user?.groups?.includes(CONSTANTS.USER_GROUP);
  const isAdminGroup = user?.groups?.includes(CONSTANTS.ADMIN_GROUP);

  const handleRequestEditorClick = () => {
    setIsModalShown(true);//
  };

  const handleCloseModalClick = () => {
    setIsModalShown(false);
  };

  const handleLogoutClick = async () => oktaAuth.signOut('/');

  return (
    <div className={styles.homePage}>
      { isModalShown && (
        <Modal closeOnEsc closeOnOutsideClick onClose={handleCloseModalClick}>
          <p data-testid="request-modal">To get the access to Editor Mode, please follow these steps:</p>
          <ol>
            <li>Go to <a href="https://idlocker.nike.com/identityiq/accessRequest/accessRequest.jsf#/accessRequest/selectUser?quickLink=Request%20Access" target="_blank" rel="noopener noreferrer">IDLocker</a></li>
            <li>Select your user name and press "Next"</li>
            <li>Search for "Application.FutureRetail.Users" access, check it and press "Next"</li>
            <li>Submit your request to be reviewed by manager</li>
          </ol>
        </Modal>
      )}
      <div className={styles.wrapper}>
        <Logo noLink />
        <div className={styles.contentWrapper}>
          <h1 className={styles.heading}>{CONSTANTS.mainTitle}</h1>
          <div className={styles.navItem}>
            <CustomButton to="/presenter-filter/">{CONSTANTS.presentationTitle}</CustomButton>
          </div>
          {isUserGroup && (
            <div className={styles.navItem}>
              <CustomButton to="/editor/">{CONSTANTS.editorTitle}</CustomButton>
            </div>
          )}
          {isUserGroup && isAdminGroup && (
            <div className={styles.navItem}>
              <CustomButton to="/admin/">{CONSTANTS.adminTitle}</CustomButton>
            </div>
          )}
          <div className={styles.navItem}>
            <CustomButton onClick={handleLogoutClick}>
              <span className="fa fa-sign-out" /> Log out
            </CustomButton>
          </div>
        </div>
      </div>
      {!isUserGroup && (
        <div className={styles.footer}>
          <button className={styles.requestButton} onClick={handleRequestEditorClick}>Editor Access Request</button>
        </div>
      )}
    </div>
  );
};

HomePage.propTypes = {
  user: PropTypes.shape({
    groups: PropTypes.arrayOf(PropTypes.string),
  }),
};
