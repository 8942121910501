import React, { useState } from 'react';
import classNames from 'clsx';
import { STATUSES } from '../../utils/statuses-constants';
import styles from './status-picker-legend.module.css';

export const StatusPickerLegend = () => {
  const [isLegendOpen, setIsLegendOpen] = useState(true);

  const toggleLegend = () => {
    setIsLegendOpen(!isLegendOpen);
  };

  if (!isLegendOpen) {
    return (
      <span data-testid="icon">
        <i
          className={classNames('fa fa-question', styles.toggleLegendButton)}
          onClick={toggleLegend}
          data-testid="legend-open"
        />
      </span>
    );
  }

  return (
    <div data-testid="legend" className={styles.legend}>
      {STATUSES.map(({ title, icon }) => (
        <div key={icon} className={styles.wrapper}>
          <img src={icon} width="45px" height="45px" alt={title} />
          <h6 className={styles.title}>{title}</h6>
        </div>
      ))}
      <i
        className={classNames('fa fa-close', styles.close)}
        data-testid="legend-close"
        onClick={toggleLegend}
      />
    </div>
  );
};
