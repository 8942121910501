export const moveProject = (source = [], target = [], id) => {
  const project = source.find(({ id: projectId }) => projectId === id);
  let newSource = source;
  let newTarget = target;
  if (project) {
    newSource = source.filter(({ id: sourceId }) => sourceId !== id);
    newTarget = [...target, project];
  }
  return {
    source: newSource,
    target: newTarget,
  };
};
