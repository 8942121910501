import React, { useState } from 'react';
import DayPicker from 'react-day-picker';
import { useQuery } from '@apollo/client';
import 'react-day-picker/lib/style.css';
import { Logo } from '../../components/logo';
import { GroupSelector } from '../../components/group-selector';
import { Button } from '../../components/button';
import { getWeekRange } from '../../utils/get-week-range';
import { getWeekDays } from '../../utils/get-week-days';
import { convertToWeekString } from '../../utils/conwert-to-week-string';
import { convertDateToRetailWeek } from '../../utils/date-to-retail-week';
import { GET_ATTRIBUTES } from './queries';
import styles from './presentation-filter-page.module.css';

export const PresentationFilterPage = ({ history }) => {
  const [hoverRange, setHoverRange] = useState(undefined);
  const [selectedDays, setSelectedDays] = useState([]);
  const [activeFilter, setActiveFilter] = useState({ ids: [], week: '' });

  const { data, loading } = useQuery(GET_ATTRIBUTES, { fetchPolicy: 'cache-and-network' });

  const handleDayChange = (date) => {
    const weekDays = getWeekDays(getWeekRange(date).from);
    setSelectedDays(weekDays);
    const currentFilter = {
      ...activeFilter,
      week: convertToWeekString(weekDays),
    };
    setActiveFilter(currentFilter);
  };

  const handleDayEnter = (date) => {
    setHoverRange(getWeekRange(date));
  };

  const handleDayLeave = () => {
    setHoverRange(undefined);
  };

  const handleWeekClick = (weekNumber, days) => {
    setSelectedDays(days);
    const currentFilter = {
      ...activeFilter,
      week: convertToWeekString(days),
    };
    setActiveFilter(currentFilter);
  };

  const handleSetGroup = (ids) => {
    const currentFilter = {
      ...activeFilter,
      ids,
    };
    setActiveFilter(currentFilter);
  };

  const daysAreSelected = selectedDays.length > 0;

  const modifiers = {
    hoverRange,
    selectedRange: daysAreSelected && {
      from: selectedDays[0],
      to: selectedDays[6],
    },
    hoverRangeStart: hoverRange && hoverRange.from,
    hoverRangeEnd: hoverRange && hoverRange.to,
    selectedRangeStart: daysAreSelected && selectedDays[0],
    selectedRangeEnd: daysAreSelected && selectedDays[6],
  };

  const selectedDates = convertToWeekString(selectedDays);

  const groups = data?.getAttributes?.attributes || [];

  const handleRedirectClick = () => {
    const { ids, week } = activeFilter;
    const path = `?ids=${ids.join(',')}&week=${encodeURIComponent(week)}`;
    history.push(`/presenter${path}`);
  };

  return (
    <div className={styles.calendarPage}>
      <div className={styles.overlay} />
      <Logo noLink />
      <div className={styles.wrapper}>
        <div className={styles.column}>
          <GroupSelector
            groups={groups}
            loading={loading}
            handleSetGroup={handleSetGroup}
          />
        </div>
        <div className={styles.column}>
          <div className={styles.calendar}>
            <h4>Choose the week</h4>
            <DayPicker
              selectedDays={selectedDays}
              showWeekNumbers
              showOutsideDays
              modifiers={modifiers}
              onDayClick={handleDayChange}
              onDayMouseEnter={handleDayEnter}
              onDayMouseLeave={handleDayLeave}
              onWeekClick={handleWeekClick}
              renderWeek={(weekNumber, week) => (
                <>
                  {convertDateToRetailWeek(week[0]).fullRetailWeek}
                </>
              )}
            />
            {daysAreSelected && (
              <>
                <h5 className={styles.dates}>
                  {selectedDates}
                </h5>
                <Button
                  variant="success"
                  disabled={!activeFilter.ids.length}
                  onClick={handleRedirectClick}
                >
                  Done
                </Button>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
