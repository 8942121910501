export const RESULT_OPTIONS = [
  { value: 'red', title: 'Red' },
  { value: 'yellow', title: 'Yellow' },
  { value: 'green', title: 'Green' },
  { value: 'black', title: 'Black' },
];

export const DEFAULT_OPTION = RESULT_OPTIONS[3].value;

export const DEFAULT_RESULT_ITEM = {
  key: '',
  value1: '',
  value2: '',
  value3: '',
  value1Color: DEFAULT_OPTION,
  value2Color: DEFAULT_OPTION,
  value3Color: DEFAULT_OPTION,
};

export const DEFAULT_RESULT = {
  title: '',
  items: [DEFAULT_RESULT_ITEM],
};
