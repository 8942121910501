import React from 'react';
import { BrowserRouter as Router, Switch, Redirect, Route, useHistory } from 'react-router-dom';
import { SecureRoute, Security, LoginCallback, useOktaAuth } from '@okta/okta-react';
import { OktaAuth } from '@okta/okta-auth-js';
import { ApolloProvider } from '@apollo/client';
import { NotificationContainer } from 'react-notifications';
import { createClient } from './apollo-client';

import { HomePage } from './pages/home-page';
import { ErrorPage } from './pages/error-page';
import { LoginPage } from './pages/login-page';
import { AdminPage } from './pages/admin-page';
import { PresentationFilterPage } from './pages/presentation-filter-page';
import { PresentationReportPage } from './pages/presentation-report-page';
import { ReportPage } from './pages/report-page';
import { ProjectsWorkspace } from './pages/projects-workspace-page/projects-workspace-page';
import { ReportsPage } from './pages/reports-page';
import { EditorPage } from './pages/editor-page';
import { AddProjectPage } from './pages/add-project-page';
import { UserProvider } from './utils/use-user';
import { CONSTANTS } from './constants';
import 'react-notifications/lib/notifications.css';
import './App.css';

const ApolloProviderWithAuth = ({ children }) => {
  const { authState } = useOktaAuth();
  const apolloClient = createClient(authState?.accessToken?.value);

  return (
    <ApolloProvider client={apolloClient}>
      {children}
    </ApolloProvider>
  );
};

const OktaAuthProvider = ({ children }) => {
  const history = useHistory();

  const oktaAuth = new OktaAuth({
    issuer: CONSTANTS.oktaAuthURL,
    clientId: CONSTANTS.clientId,
    redirectUri: `${window.location.origin}/okta-redirect`,
    responseType: ['token', 'id_token'],
    pkce: false,
    scopes: ['profile', 'openid', 'email'],
  });

  const handleAuthRequired = () => {
    history.push('/login');
  };

  return (
    <Security oktaAuth={oktaAuth} onAuthRequired={handleAuthRequired}>
      {children}
    </Security>
  );
};

export const App = () => {
  return (
    <>
      <Router>
        <OktaAuthProvider>
          <ApolloProviderWithAuth>
            <UserProvider>
              <Switch>
                <Route path="/" exact component={() => (<Redirect to="/home" />)} />
                <Route path="/login" exact component={LoginPage} />
                <Route path="/okta-redirect" exact component={LoginCallback} />
                <SecureRoute path="/report/:reportId" exact component={ReportPage} />
                <SecureRoute path="/home" exact component={HomePage} />
                <SecureRoute path="/presenter-filter" exact component={PresentationFilterPage} />
                <SecureRoute path="/presenter" exact component={PresentationReportPage} />
                <SecureRoute path="/admin" exact component={AdminPage} />

                <SecureRoute path="/editor_after_auth" exact component={() => (<Redirect to="/editor/projects" />)} />
                <SecureRoute path="/editor" exact component={() => (<Redirect to="/editor/projects" />)} />
                <SecureRoute path="/editor/projects" exact component={ProjectsWorkspace} />
                <SecureRoute path="/editor/projects/new" exact component={AddProjectPage} />
                <SecureRoute path="/editor/projects/:projectId" exact component={({ match }) => (<Redirect to={`/editor/projects/${match.params.projectId}/reports`} />)} />
                <SecureRoute path="/editor/projects/:projectId/edit" exact component={AddProjectPage} />
                <SecureRoute path="/editor/projects/:projectId/reports" exact component={ReportsPage} />
                <SecureRoute path="/editor/projects/:projectId/reports/new" exact component={EditorPage} />
                <SecureRoute path="/editor/projects/:projectId/reports/:reportId" exact component={({ match }) => (<Redirect to={`/editor/projects/${match.params.projectId}/reports/${match.params.reportId}/edit`} />)} />
                <SecureRoute path="/editor/projects/:projectId/reports/:reportId/edit" exact component={EditorPage} />

                <Route path="*" exact component={ErrorPage} />
              </Switch>
            </UserProvider>
          </ApolloProviderWithAuth>
        </OktaAuthProvider>
      </Router>
      <NotificationContainer />
    </>
  );
};
