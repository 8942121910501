import gql from 'graphql-tag';
import { REPORT_FRAGMENT } from '../../utils/fragments/report';

export const GET_PROJECT = gql`
  query($id: String!) {
    getProject(id: $id) {
      id
      name
      product
      program
      metadata {
        id
        lastModified
        createdOn
        createdBy
        lastModifiedBy
      }
      attributes {
        id
        attributeCode
      }
    }
  }
`;

export const GET_ATTRIBUTES = gql`
  query getAttributes {
    getAttributes {
      attributes {
        id
        name
        values {
          id
          name
        }
      }
    }
  }
`;

export const ADD_PROJECT = gql`
  mutation addProject(
    $id: String
    $name: String!
    $product: String!
    $program: String!
    $attributeValues: [AttributeValuesInput]
  ) {
    addProject(
      id: $id,
      name: $name,
      product: $product, 
      program: $program,
      attributeValues: $attributeValues
    ) {
      id
      name
      product
      program
      attributes {
        id
        attributeCode
      }
    }
  }
`;

export const GET_REPORTS_FOR_PROJECT = gql`
  query($id: String!) {
    getReportsForProject(projectId: $id) {
      items {
        ...ReportFields
      }
    }
  }
  ${REPORT_FRAGMENT}
`;

export const DELETE_PROJECT = gql`
  mutation deleteProject($id: String!) {
    deleteProject(id: $id)
  }
`;

export const DELETE_SECTION = gql`
  mutation deleteSection($id: String!) {
    deleteSection(id: $id)
  }
`;

export const DELETE_REPORT = gql`
  mutation deleteReport($id: String!) {
    deleteReport(id: $id)
  }
`;
