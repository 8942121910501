import { ApolloClient, InMemoryCache, ApolloLink, createHttpLink } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { createAuthLink } from 'aws-appsync-auth-link';
import { NotificationManager } from 'react-notifications';
import debounce from 'lodash.debounce';

const showNotification = debounce(() => {
  NotificationManager.error('Unsaved data will be lost. Please click to update.', 'The session is expired.', 0, () => {
    document.location.reload();
  });
}, 600);

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors?.some(({ errorType }) => errorType === 'UnauthorizedException')) {
    showNotification();
  }

  if (networkError) {
    console.log(`[Network error]: ${networkError}`);
  }
});

export const createClient = (accessToken) => {
  const appSyncAuthParams = {
    url: process.env.REACT_APP_GRAPHQL_URL,
    region: process.env.REACT_APP_REGION,
    auth: {
      type: process.env.REACT_APP_AUTH_TYPE,
      jwtToken: accessToken,
    },
  };

  const link = ApolloLink.from([
    createAuthLink(appSyncAuthParams),
    errorLink,
    createHttpLink({ uri: process.env.REACT_APP_GRAPHQL_URL }),
  ]);

  return new ApolloClient({
    link,
    cache: new InMemoryCache(),
  });
};
