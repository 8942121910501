export const buildSortedProjects = (projects = [], sortedIds = []) => {
  if (sortedIds?.length === 0) {
    return projects;
  }

  return [...projects].sort((a, b) => {
    if (sortedIds.indexOf(a.id) === -1) {
      return 1;
    }

    if (sortedIds.indexOf(b.id) === -1) {
      return -1;
    }

    if (sortedIds.indexOf(a.id) >= sortedIds.indexOf(b.id)) {
      return 1;
    }

    return -1;
  });
};
