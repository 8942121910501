import { convertDateToRetailWeek } from '../../utils/date-to-retail-week';
import { CONSTANTS } from '../../constants';
import { buildWeekDaysRange } from '../../utils/build-week-days-range';
import { buildAttributesDefaultValues } from '../../utils/build-attributes-default-values';

export const buildReportName = (date, projectName) =>
  `${projectName} - Retail Week ${convertDateToRetailWeek(date).retailWeek}`;

export const buildInitialValues = (reportDetails, attributes) => {
  return {
    name: reportDetails?.name || '',
    product: reportDetails?.product,
    program: reportDetails?.program,
    highlights: reportDetails?.highlights || '',
    weekHighlight: reportDetails?.weekHighlight || '',
    status: reportDetails?.status || '',
    date:
      reportDetails?.date && reportDetails.date !== CONSTANTS.SELECT_DATE
        ? buildWeekDaysRange(reportDetails.date)
        : [],
    attributes: buildAttributesDefaultValues(attributes, reportDetails),
  };
};

export const buildDatePickerModifiers = (hoverRange, selectedDays) => {
  const daysAreSelected = selectedDays.length > 0;
  return {
    hoverRange,
    selectedRange: daysAreSelected && {
      from: selectedDays[0],
      to: selectedDays[6],
    },
    hoverRangeStart: hoverRange && hoverRange.from,
    hoverRangeEnd: hoverRange && hoverRange.to,
    selectedRangeStart: daysAreSelected && selectedDays[0],
    selectedRangeEnd: daysAreSelected && selectedDays[6],
  };
};
