import React, { useState, useEffect, useRef } from 'react';
import { NotificationManager } from 'react-notifications';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import DayPicker from 'react-day-picker';
import { useQuery } from '@apollo/client';
import { Button } from '../button';
import { Spinner } from '../spinner';
import { ValidationError } from '../validation-error';
import { ReportStatusPicker } from '../report-status-picker';
import { QuillEditor } from '../quill-editor';
import { buildAttributesArray } from '../../utils/build-attributes-array';
import { convertToWeekString } from '../../utils/conwert-to-week-string';
import { getWeekRange } from '../../utils/get-week-range';
import { getWeekDays } from '../../utils/get-week-days';
import { CONSTANTS } from '../../constants';
import { convertDateToRetailWeek } from '../../utils/date-to-retail-week';
import { buildWeekDaysRange } from '../../utils/build-week-days-range';
import { isEmpty } from '../../utils/is-empty';
import { buildDatePickerModifiers, buildInitialValues, buildReportName } from './helpers';
import { GET_ATTRIBUTES } from './queries';
import styles from './edit-report-details.module.css';

export const EditReportDetails = ({
  reportDetails: defaultReportDetails,
  isWeekSelected: isWeekSelectedDefault,
  projectName,
  onSubmit,
  onChangesSkip,
}) => {
  const [reportDetails, setReportDetails] = useState(defaultReportDetails);
  const [isWeekSelected, setIsWeekSelected] = useState(isWeekSelectedDefault);
  const [hoverRange, setHoverRange] = useState(undefined);
  const [selectedDays, setSelectedDays] = useState([]);
  const [isSaveAndProceed, setIsSaveAndProceed] = useState(true);
  const [selectedDate, setSelectedDate] = useState('');
  const [reportName, setReportName] = useState('');
  const [isNameTouched, setIsNameTouched] = useState(false);
  const [initialName, setInitialName] = useState('');
  const reportNameRef = useRef();

  const { data, loading } = useQuery(GET_ATTRIBUTES, { fetchPolicy: 'cache-and-network' });
  const attributes = data?.getAttributes?.attributes || [];

  useEffect(() => {
    if (!isEmpty(defaultReportDetails)) {
      setReportDetails(defaultReportDetails);
      setIsWeekSelected(true);
      if (defaultReportDetails.date && defaultReportDetails.date !== CONSTANTS.SELECT_DATE) {
        const weekRange = buildWeekDaysRange(defaultReportDetails.date);
        setSelectedDays(weekRange);
        setReportName(buildReportName(weekRange[1], projectName));
      }
    }
  }, [defaultReportDetails, projectName]);

  const handleDayChange = (date) => {
    setSelectedDays(getWeekDays(getWeekRange(date).from));
  };

  const handleDayEnter = (date) => {
    setHoverRange(getWeekRange(date));
  };

  const handleDayLeave = () => {
    setHoverRange(false);
  };

  const handleWeekClick = (weekNumber, days) => {
    setSelectedDays(days);
  };

  const handleNameFocus = () => {
    setInitialName(reportNameRef?.current?.value);
  };

  const handleNameBlur = () => {
    if (initialName !== reportNameRef?.current?.value) {
      setIsNameTouched(true);
    }
  };

  const handleSelectWeek = (setFieldValue) => {
    setIsWeekSelected(true);

    if (selectedDate) {
      const reportName = buildReportName(selectedDate, projectName);
      setReportName(reportName);
      if (!reportNameRef?.current?.value || (!isNameTouched && !defaultReportDetails.name)) {
        setFieldValue('name', reportName);
      }
    }
  };

  const handleSelectDate = (date) => {
    setSelectedDate(date);
  };

  const modifiers = buildDatePickerModifiers(hoverRange, selectedDays);

  const handleValidation = (values) => {
    const errors = {};
    if (!values.product) {
      errors.product = CONSTANTS.REQUIRED_FIELD;
    }
    if (!values.program) {
      errors.program = CONSTANTS.REQUIRED_FIELD;
    }
    if (!values.status) {
      errors.status = CONSTANTS.REQUIRED_FIELD;
    }
    if (values.date === CONSTANTS.SELECT_DATE || !values.date.length) {
      errors.date = CONSTANTS.REQUIRED_FIELD;
    }
    if (Object.keys(errors).length) {
      NotificationManager.error('Please enter all Required details', 'Sorry');
    }
    return errors;
  };

  const handleFormSubmit = (values) => {
    const { name, product, program, highlights, weekHighlight, status, attributes: formAttributes } = values;
    const attributeValues = buildAttributesArray(formAttributes);

    // As report is not refetched after save, report attributes are also to be update manually after change
    // Report attributes are the only fields with enumerable values, so their values to be updated from attributes array
    const newAttributes = attributeValues.map(({ attributeCode, attributeValue }) => {
      const fullAttribute = attributes?.find(({ id: attributeId }) => attributeId === attributeCode) || {};
      const fullValue = fullAttribute?.values?.find(({ id: valueId }) => valueId === attributeValue);

      return {
        attributeCode,
        id: attributeValue,
        attribute: {
          ...fullAttribute,
          values: [fullValue],
        },
      };
    });

    onSubmit(
      {
        ...reportDetails,
        attributes: newAttributes,
        name: name || reportName,
        product,
        program,
        highlights,
        weekHighlight,
        status,
        date: convertToWeekString(values.date),
        attributeValues,
        isRemoved: isSaveAndProceed,
        isLocked: isSaveAndProceed,
      },
      isSaveAndProceed
    );
  };

  const initialValues = buildInitialValues(defaultReportDetails, attributes);

  if (loading) {
    return <Spinner title="Please wait.." />;
  }

  return (
    <div className={styles.editReport}>
      <Formik
        initialValues={initialValues}
        validate={handleValidation}
        onSubmit={handleFormSubmit}
        enableReinitialize={true}
        validateOnChange={false}
        validateOnBlur={false}
      >
        <Form>
          <div className={styles.formWrapper}>
            <div className={styles.calendarWrapper}>
              <div className={styles.label}>Pick a week (Required):</div>

              {isWeekSelected && (
                <Button
                  data-testid="open-calendar"
                  variant="outline-success"
                  onClick={() => setIsWeekSelected(false)}
                >
                  {selectedDays.length > 0 ? convertToWeekString(selectedDays, true) : CONSTANTS.SELECT_DATE}
                </Button>
              )}

              {!isWeekSelected && (
                <Field name="date" type="hidden">
                  {({ field: { value }, form: { setFieldValue } }) => (
                    <>
                      <DayPicker
                        selectedDays={value}
                        showWeekNumbers
                        showOutsideDays
                        renderWeek={(weekNumber, week) => <>{convertDateToRetailWeek(week[0]).fullRetailWeek}</>}
                        modifiers={modifiers}
                        onDayClick={(value) => {
                          handleDayChange(value);
                          setFieldValue('date', getWeekDays(getWeekRange(value).from));
                          handleSelectDate(value);
                        }}
                        onWeekClick={(weekNumber, days) => {
                          handleWeekClick(weekNumber, days);
                          setFieldValue('date', days);
                          handleSelectDate(days[0]);
                        }}
                        onDayMouseEnter={handleDayEnter}
                        onDayMouseLeave={handleDayLeave}
                      />
                      {selectedDays.length !== 0 && (
                        <Button variant="success" onClick={() => handleSelectWeek(setFieldValue)}>
                          Done
                        </Button>
                      )}
                    </>
                  )}
                </Field>
              )}
              <ErrorMessage name="date" component={ValidationError} />
            </div>

            <div className={styles.rowWrapper}>
              <div className={styles.label}>Report Name (Optional):</div>
              <Field
                type="text"
                name="name"
                placeholder="Enter Report Name"
                onBlur={handleNameBlur}
                onFocus={handleNameFocus}
                innerRef={reportNameRef}
              />
            </div>

            <div className={styles.statusWrapper}>
              <div className={styles.label}>Status (Required):</div>
              <Field name="status" type="hidden">
                {({ field: { value }, form: { setFieldValue } }) => (
                  <ReportStatusPicker
                    value={value}
                    colors={['#dc3545', '#ffc107', '#28a745']}
                    onChange={( hex ) => setFieldValue('status', hex)}
                  />
                )}
              </Field>
              <ErrorMessage name="status" component={ValidationError} />
            </div>

            <div className={styles.productWrapper}>
              <div className={styles.rowWrapper}>
                <div className={styles.label}>Product (Required):</div>
                <Field type="text" name="product" />
                <ErrorMessage name="product" component={ValidationError} />
              </div>
            </div>

            <div className={styles.programWrapper}>
              <div className={styles.rowWrapper}>
                <div className={styles.label}>Program (Required):</div>
                <Field name="program" type="text" />
                <ErrorMessage name="program" component={ValidationError} />
              </div>
            </div>

            {attributes.map(({ id, name, values }) => (
              <div key={id} data-testid="attributes" className={styles.attributeWrapper}>
                <div className={styles.rowWrapper}>
                  <div className={styles.label}>{name} (Optional):</div>
                  <Field as="select" name={`attributes[${id}]`}>
                    <option value="">Select value</option>
                    {values.map(({ id, name }) => (
                      <option key={id} value={id}>
                        {name}
                      </option>
                    ))}
                  </Field>
                </div>
              </div>
            ))}

            <div className={styles.rowWrapper}>
              <div className={styles.label}>Highlight of the week (Optional, max 100 characters):</div>
              <Field
                name="weekHighlight"
                type="text"
                maxLength="100"
                placeholder="Add highlight of the week" />
            </div>

            <div className={styles.rowWrapper}>
              <div className={styles.label}>Product highlights (Optional):</div>
              <Field name="highlights" type="hidden">
                {({ field: { value }, form: { setFieldValue } }) => (
                  <QuillEditor
                    placeholder="Add Highlights"
                    name="highlights"
                    value={value}
                    onChange={setFieldValue}
                  />
                )}
              </Field>
            </div>

            <div className={styles.actionsWrapper}>
              {onChangesSkip && (
                <Button variant="info" className={styles.actionButton} onClick={onChangesSkip}>
                  <i className="fa fa-ban" title="Cancel changes" />
                  <span className={styles.actionText}>Cancel</span>
                </Button>
              )}
              <Button variant="primary" className={styles.actionButton} type="submit">
                <i className="fa fa-arrow-right" title="Save & Proceed" />
                <span className={styles.actionText}>Save & Proceed</span>
              </Button>
              <Button variant="success" type="submit" onClick={() => setIsSaveAndProceed(false)}>
                <i className="fa fa-save" title="Save & Close" />
                <span className={styles.actionText}>Save & Close</span>
              </Button>
            </div>
          </div>
        </Form>
      </Formik>
    </div>
  );
};
