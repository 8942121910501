import React from 'react';
import classNames from 'clsx';
import { VARIANTS } from './constants';
import styles from './tag.module.css';

export const Tag = ({ variant = VARIANTS.default, onClick, children }) => {
  return (
    <span
      onClick={onClick}
      className={classNames(styles.tag, {
        [styles.active]: typeof onClick === 'function',
        [styles.red]: variant === VARIANTS.red,
        [styles.green]: variant === VARIANTS.green,
      })}
    >
      {children}
    </span>
  );
};
