import gql from 'graphql-tag';

export const GET_ALL_PROJECTS = gql`
  query getAllProjects {
    getAllProjects {
      items {
        id
        name
        product
        program
        metadata {
          id
          lastModified
          createdOn
          createdBy
          lastModifiedBy
        }
        attributes {
          id
          attributeCode
          attribute {
            id
            name
            values {
              id
              name
            }
          }
        }
      }
    }
  }
`;
