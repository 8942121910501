import React from 'react';
import classNames from 'clsx';
import { StatusPickerLegend } from '../status-picker-legend';
import { STATUSES, UNKNOWN_STATUS } from '../../utils/statuses-constants';
import { groupKeyValues, sortByDate, sortKeyValues } from './helpers';
import styles from './report-key-values-section.module.css';

export const ReportKeyValuesSection = ({ keyValuesData }) => {
  if (!keyValuesData.items) {
    return null;
  }

  const groupedKeyValues = groupKeyValues(keyValuesData);
  const statusIcons = STATUSES.reduce((acc, status) => {
    return {
      ...acc,
      [status.value]: status,
    };
  }, {});

  return (
    <>
      <div className={styles.legend}>
        <span className="fa fa-info-circle" /> Hover the key value text to see exact date if set.
      </div>
      <div className={styles.keyValues}>
        {groupedKeyValues.sort(sortKeyValues).map(({ title, valueDrops }, index) => (
          <div key={index} className={classNames(styles.keyValueCard)}>
            <h4 className={styles.keyValueTitle}>
              {title}
            </h4>
            <div className={styles.keyValueTextWrapper}>
              {valueDrops?.sort(sortByDate).map(({ status, text, title, date }, valueIndex) => {
                const statusIcon = statusIcons[status] || UNKNOWN_STATUS;
                const dateTooltip = date && (new Date(Number(date))).toLocaleDateString();
                return (
                  <div key={valueIndex}>
                    <img
                      className={styles.keyValueIcon}
                      src={statusIcon?.icon}
                      alt={statusIcon?.title}
                      title={statusIcon?.title}
                    />
                    <span title={dateTooltip}>
                      {text || title}
                    </span>
                  </div>
                );
              })}
            </div>
          </div>
        ))}
      </div>
      <StatusPickerLegend />
    </>
  );
};
