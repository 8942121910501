import gql from 'graphql-tag';
import { REPORT_FRAGMENT } from '../../utils/fragments/report';

export const UPDATE_WEEK_HIGHLIGHT = gql`
  mutation updateWeekHighlight($reportId: String!, $weekHighlight: String) {
    updateWeekHighlight(reportId: $reportId, weekHighlight: $weekHighlight) {
      ...ReportFields
    }
  }
  ${REPORT_FRAGMENT}
`;

export const GET_REPORTS_BY_WEEK_AND_ATTRIBUTE = gql`
  query($attributeValueId: [String]!, $week: String!) {
    getReportsByAttributeValueAndWeek(attributeValueId: $attributeValueId, week: $week) {
      items {
        report {
          ...ReportFields
        }
      }
    }
  }
  ${REPORT_FRAGMENT}
`;
