import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '../button';
import styles from './section-selector.module.css';

export const SectionSelector = ({
  sections = [],
  switchSection,
}) => {
  if (sections.length === 0) {
    return null;
  }

  return (
    <div className={styles.wrapper}>
      <h4 className={styles.heading}>Choose a section to Add</h4>
      <div className={styles.buttonsList}>
        {sections.map((section) => (
          <div className={styles.buttonWrapper} key={section.key}>
            <Button
              variant="outline-primary"
              className={styles.button}
              onClick={() => switchSection(section.key)}
            >
              {section.title}
            </Button>
          </div>
        ))}
      </div>
    </div>
  );
};

SectionSelector.propTypes = {
  switchSection: PropTypes.func.isRequired,
  sections: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    title: PropTypes.string,
  })),
};
