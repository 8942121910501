import React, { useState, useEffect } from 'react';
import { Tabs } from '../tabs';
import { CONSTANTS } from '../../constants';
import { ReportHighlightSection } from '../report-highlight-section';
import { ReportPerformanceSection } from '../report-performance-section';
import { ReportKeyFeaturesSection } from '../report-key-features-section';
import { ReportKeyValuesSection } from '../report-key-values-section';
import { ReportMainRisksSection } from '../report-main-risks-section';
import { ReportResultsSection } from '../report-results-section';
import { ReportUploadsSection } from '../report-uploads-section';
import styles from './report-section.module.css';

export const ReportSection = ({ report, handleSaveReport, user, isPrintVersion }) => {
  const [tabIndex, setTabIndex] = useState(0);

  useEffect(() => {
    setTabIndex(0);
  }, [report]);

  const handleChangeTab = (tabIndex) => {
    setTabIndex(tabIndex);
  };

  const tabs = Object.entries(report).reduce((acc, [key, data]) => {
    const names = {
      performanceSection: CONSTANTS.performanceTab,
      keyFeaturesSection: CONSTANTS.keyFeaturesTab,
      keyValuesSection: CONSTANTS.keyValuesTab,
      mainRisksSection: CONSTANTS.mainRisksTab,
      resultsSection: CONSTANTS.resultsTab,
      resultsUploadSection: CONSTANTS.resultsUploadsTab,
    };

    if (data?.id && names[key]) {
      return [
        ...acc,
        {
          name: names[key],
          data,
        },
      ];
    }

    return acc;
  }, []);

  const tabPanels = tabs.map(({ name, data }) => {
    switch (name) {
      case CONSTANTS.performanceTab:
        return (
          <ReportPerformanceSection performanceData={data} />
        );
      case CONSTANTS.keyFeaturesTab:
        return (
          <ReportKeyFeaturesSection keyFeaturesData={data} />
        );
      case CONSTANTS.keyValuesTab:
        return (
          <ReportKeyValuesSection keyValuesData={data} />
        );
      case CONSTANTS.mainRisksTab:
        return (
          <ReportMainRisksSection mainRisksData={data} />
        );
      case CONSTANTS.resultsTab:
        return (
          <ReportResultsSection resultsData={data} />
        );
      case CONSTANTS.resultsUploadsTab:
        return (
          <ReportUploadsSection resultsUploadData={data} />
        );
      default:
        return null;
    }
  });

  return (
    <div>
      <ReportHighlightSection
        report={report}
        user={user}
        handleSaveReport={handleSaveReport}
        isPrintVersion={isPrintVersion}
      />
      {isPrintVersion ? (
        <div className={styles.printTabs}>
          {tabs.map(({ name }, index) => (
            <div className={styles.printTab}>
              <h2>{name}</h2>
              <div>
                {tabPanels[index]}
              </div>
            </div>
          ))}
        </div>
      ) : (
        <>
          {tabs.length > 0 && (
            <div className={styles.reportTabs}>
              <Tabs
                tabs={tabs.map(({ name }) => name)}
                selectedTabIndex={tabIndex}
                onTabClick={handleChangeTab}
              >
                {tabPanels[tabIndex]}
              </Tabs>
            </div>
          )}
        </>
      )}
    </div>
  );
};
