import React, { useRef } from 'react';
import { Button } from '../button';

export const FilePicker = ({ children, allowedExtensions = [], onChange, ...rest }) => {
  const hiddenFileInput = useRef(null);

  const handleClick = () => {
    hiddenFileInput.current?.click();
  };

  const handleChange = (event) => {
    const file = event.target.files[0];
    onChange(file, () => {
      if (hiddenFileInput.current?.value) {
        hiddenFileInput.current.value = '';
      }
    });
  };

  return (
    <>
      <Button {...rest} onClick={handleClick} data-testid="file-picker-button">
        {children}
      </Button>
      <input
        type="file"
        accept={allowedExtensions.join(',')}
        ref={hiddenFileInput}
        onChange={handleChange}
        style={{ display: 'none' }}
        data-testid="file-picker"
      />
    </>
  );
};
