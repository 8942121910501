import React from 'react';
import PropTypes from 'prop-types';
import { useOktaAuth } from '@okta/okta-react';
import { CONSTANTS } from '../../constants';
import { Logo } from '../../components/logo';
import { CustomButton } from '../../components/custom-button';
import styles from './login-page.module.css';

export const LoginPage = ({ location }) => {
  const { oktaAuth } = useOktaAuth();

  const searchParams = new URLSearchParams(location.search);
  const redirectAfterLoginUrl = searchParams.get('redirectTo') || '/';

  const handleLoginClick = async () => oktaAuth.signInWithRedirect(redirectAfterLoginUrl);

  return (
    <div className={styles.loginPage}>
      <div className={styles.wrapper}>
        <Logo noLink />
          <h1 className={styles.heading}>{CONSTANTS.mainTitle}</h1>
          <div className={styles.buttonWrapper}>
            <CustomButton onClick={handleLoginClick}>{CONSTANTS.loginTitle}</CustomButton>
          </div>
      </div>
    </div>
  );
};

LoginPage.propTypes = {
  location: PropTypes.shape({
    path: PropTypes.string,
    search: PropTypes.string,
  }),
};
