import React, { useRef, useState } from 'react';
import classNames from 'clsx';
import styles from './tabs.module.css';

export const Tabs = ({ tabs, onTabClick, selectedTabIndex, children }) => {
  const scrollableTabRef = useRef(null);
  const [isScrolling, setIsScrolling] = useState(false);
  const [clientX, setClientX] = useState(0);
  const [scrollX, setScrollX] = useState(0);

  const handleMouseDown = (event) => {
    setIsScrolling(true);
    setClientX(event.clientX);
  };

  const handleMouseUp = () => {
    setIsScrolling(false);
  };

  const handleMouseLeave = () => {
    setIsScrolling(false);
  };

  const handleMouseMove = (event) => {
    if (isScrolling) {
      const newScrollX = scrollX + event.clientX - clientX;
      scrollableTabRef.current.scrollLeft = -newScrollX;
      setScrollX(-scrollableTabRef.current.scrollLeft);
      setClientX(event.clientX);
    }
  };

  return (
    <div className={styles.wrapper}>
      <ul
        ref={scrollableTabRef}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        onMouseMove={handleMouseMove}
        onMouseLeave={handleMouseLeave}
        className={styles.tabsList}
      >
        {tabs.map((text, index) => (
          <li className={styles.tabWrapper} key={`${text}${index}`}>
            <button
              type="button"
              className={classNames(styles.tabButton, { [styles.tabButtonSelected]: index === selectedTabIndex })}
              onClick={() => onTabClick(index)}
            >
              {text}
            </button>
          </li>
        ))}
      </ul>
      <div className={styles.content}>
        {children}
      </div>
    </div>
  );
};
