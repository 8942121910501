import gql from 'graphql-tag';

export const GET_ATTRIBUTES = gql`
  query getAttributes {
    getAttributes {
      attributes {
        id
        name
        values {
          id
          name
        }
      }
    }
  }
`;

export const ADD_ATTRIBUTE = gql`
  mutation addAttribute($name: String!) {
    addAttribute(name: $name) {
      name
    }
  }
`;

export const DELETE_ATTRIBUTE = gql`
  mutation deleteAttribute($id: String!) {
    deleteAttribute(id: $id) {
      id
      name
    }
  }
`;

export const ADD_ATTRIBUTE_VALUE = gql`
  mutation addAttributeValue($attributeCode: String!, $name: String) {
    addAttributeValue(attributeCode: $attributeCode, name: $name) {
      name
    }
  }
`;

export const DELETE_ATTRIBUTE_VALUE = gql`
  mutation deleteAttributeValue($attributeCode: String!, $id: String!) {
    deleteAttributeValue(attributeCode: $attributeCode, id: $id) {
      id
      name
    }
  }
`;

