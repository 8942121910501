import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery, useQuery } from '@apollo/client';
import { Header } from '../../components/header';
import { ReportEditor } from '../../components/report-editor';
import { GET_PROJECT, GET_REPORT_BY_ID } from './queries';

export const EditorPage = ({ match, history }) => {
  const { projectId, reportId } = match.params;
  const { data } = useQuery(GET_PROJECT, {
    fetchPolicy: 'cache-and-network',
    variables: {
      id: projectId,
    },
  });

  const [loadReport, { data: reportData }] = useLazyQuery(GET_REPORT_BY_ID, {
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (reportId && reportId !== 'new') {
      loadReport({ variables: { id: reportId } });
    }
  }, [reportId, loadReport]);

  const project = data?.getProject;
  const report = reportData?.getReportById;

  useEffect(() => {
    if (report?.projectId && project?.id && report.projectId !== project.id) {
      history.replace(`/editor/projects/${report.projectId}/reports/${report.id}/edit`);
    }
  }, [history, project?.id, report?.id, report?.projectId]);

  if (!project) {
    return null;
  }

  return (
    <>
      <Header title={project.name} backButtonHref={`/editor/projects/${project.id}`}/>
      <ReportEditor
        reportToEdit={report}
        project={project}
        isWeekSelected={false}
      />
    </>
  );
};

EditorPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      projectId: PropTypes.string,
      reportId: PropTypes.string,
    }),
  }),
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
};
