import React, { useState, useRef } from 'react';
import classNames from 'clsx';
import { Link } from 'react-router-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useOnClickOutside } from '../../utils/use-click-outside';
import styles from './report-three-dots.module.css';

export const ReportThreeDots = ({
  onExportClick,
  onShareClick,
  reportId,
  onReportSelect,
  isReportSelected,
  hasSelectedReports,
  isExportInProgress,
  onPrintClick,
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuRef = useRef(null);
  useOnClickOutside(menuRef, () => setIsMenuOpen(false));

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleExportClick = () => {
    onExportClick();
    toggleMenu();
  };

  const handleShareClick = () => {
    onShareClick();
    toggleMenu();
  };

  const handlePrintClick = () => {
    onPrintClick();
    toggleMenu();
  };

  return (
    <div ref={menuRef} className={styles.menu}>
      <button onClick={toggleMenu} className={styles.menuButton} data-testid="menu-open">
        <i className={classNames('fa fa-ellipsis-v', styles.menuIcon)}/>
      </button>
      {isMenuOpen && (
        <div className={styles.menuItems} data-testid="menu-content">
          <div className={classNames(styles.exportCheckbox, styles.dotsMenuItem)}>
            {isExportInProgress ? (
              <span className={styles.exportIcon}>
                <span className="fa fa-cog fa-spin" />
              </span>
            ) : (
              <input
                type="checkbox"
                className={styles.exportIcon}
                onChange={onReportSelect}
                checked={isReportSelected}
                id="export"
              />
            )}
            <label htmlFor="export">Add to export</label>
          </div>
          <button onClick={handleExportClick} className={styles.dotsMenuItem} disabled={!hasSelectedReports || isExportInProgress}>
            <i className="fa fa-table" />
            <span>Export selected highlights</span>
          </button>
          <span className={styles.divider} />
          <Link to='/presenter-filter/' className={styles.dotsMenuItem}>
            <i className="fa fa-calendar" />
            <span>Go to main presenter page</span>
          </Link>
          <CopyToClipboard
            text={window.location.origin + '/report/' + reportId}
            onCopy={handleShareClick}
          >
            <button data-testid="share" className={styles.dotsMenuItem}>
              <i className="fa fa-share" />
              <span>Share report</span>
            </button>
          </CopyToClipboard>
          <button onClick={handlePrintClick} className={styles.dotsMenuItem}>
            <i className="fa fa-cloud-download" />
            <span>Print as PDF</span>
          </button>
        </div>
      )}
    </div>
  );
};
