import React from 'react';
import PropTypes from 'prop-types';
import styles from './page-title.module.css';

export const PageTitle = ({ title }) => (
  <h2 className={styles.title}>{title}</h2>
);

PageTitle.propTypes = {
  title: PropTypes.string,
};

PageTitle.defaultProps = {
  title: '',
};
