import gql from 'graphql-tag';

export const GET_ATTRIBUTES = gql`
  query getAttributes {
    getAttributes {
      attributes {
        id
        name
        values {
          id
          name
        }
      }
    }
  }
`;
