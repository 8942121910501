import React from 'react';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { buildDetailsData } from './helpers';
import styles from './report-uploads-section.module.css';

dayjs.extend(localizedFormat);

export const ReportUploadsSection = ({ resultsUploadData }) => {
  const details = buildDetailsData(resultsUploadData);

  if (details.length === 0) {
    return <div>No files uploaded yet</div>;
  }

  return (
    <div className={styles.uploads}>
      {details.map(({ filePath, fileAlias, fileName, metadata }, index) => (
        <div key={index} className={styles.uploadsCard}>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={filePath}
              download={fileAlias || fileName}
            >
              {fileAlias || fileName}
            </a>
            <div className={styles.uploadData}>
              <p className={styles.uploadItem}>
                <span className={styles.uploadItemTitle}>Added By:</span>
                {metadata.createdBy}
              </p>
              <p className={styles.uploadItem}>
                <span className={styles.uploadItemTitle}>On:</span>
                {dayjs(Number(metadata.createdOn)).format('ll LTS')}
              </p>
            </div>
        </div>
      ))}
    </div>
  );
};
