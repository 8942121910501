import React from 'react';
import PropTypes from 'prop-types';
import styles from './progress-bar.module.css';

export const ProgressBar = ({ min = 0, max = 100, now = 0 }) => {
  const percent = now / (max - min) * 100;

  return (
    <div className={styles.wrapper}>
      <div
        className={styles.bar}
        role="progressbar"
        aria-valuenow={now}
        aria-valuemax={max}
        aria-valuemin={min}
        style={{ width: `${percent}%` }}
      />
    </div>
  );
};

ProgressBar.propTypes = {
  min: PropTypes.number,
  max: PropTypes.number,
  now: PropTypes.number,
};
