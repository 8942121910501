import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'clsx';
import styles from './group-selector.module.css';

export const GroupSelector = ({ handleSetGroup, groups, loading }) => {
  const defaultValues = {
    attribute: groups[0],
    values: groups[0] ? groups[0].values : [],
    valuesIds: groups[0] ? groups[0].values.map(({ id }) => id) : null,
  };
  const [selectedAttribute, setSelectedAttribute] = useState(defaultValues.attribute);
  const [activeValues, setActiveValues] = useState(defaultValues.values);
  const [selectedValuesIds, setSelectedValues] = useState(defaultValues.valuesIds);

  useEffect(() => {
    setSelectedAttribute(defaultValues.attribute);
    setActiveValues(defaultValues.values);
    setSelectedValues(defaultValues.valuesIds);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groups]);

  useEffect(() => {
    if (selectedAttribute && selectedAttribute.name) {
      handleSetGroup(selectedValuesIds);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedValuesIds, selectedAttribute]);

  const handleSelectChange = ({ target: { value } }) => {
    const activeAttribute = groups.find(({ id }) => id === value);
    setSelectedAttribute(activeAttribute);
    setActiveValues(activeAttribute.values);
    setSelectedValues(activeAttribute.values.map(({ id }) => id));
  };

  const handleValueChange = (id) => {
    const newIds = selectedValuesIds.includes(id) ?
      selectedValuesIds.filter((currentId) => currentId !== id) :
      [...selectedValuesIds, id];
    setSelectedValues(newIds);
  };

  if (loading) {
    return (
      <div className={styles.groupSelector}>
        <div className={styles.loader}>
          Loading...
        </div>
      </div>
    );
  }

  if (!groups.length && !loading) {
    return (
      <div className={styles.groupSelector}>
        <div className={styles.loader}>
          No attributes...
        </div>
      </div>
    );
  }

  return (
    <div className={styles.groupSelector}>
      <div className={classNames(styles.selectWrapper, styles.attrSelector)}>
        <h4 className={styles.selectTitle}>Present by</h4>
        <select
          className={styles.select}
          onChange={handleSelectChange}
        >
          {groups.map(({ id, name }) => (
            <option key={id} value={id}>{name}</option>
          ))}
        </select>
      </div>
      <div className={styles.selectWrapper}>
        <h4
          className={styles.selectTitle}
          data-testid="selected-option-title"
        >
          {selectedAttribute ? selectedAttribute.name : ''}
        </h4>
        <div>
          {activeValues.map(({ id, name }) => (
            <label className={styles.checkbox} key={id}>
              <input
                onChange={() => handleValueChange(id)}
                type="checkbox"
                name={name}
                checked={selectedValuesIds.includes(id)}
              />
              <span className={styles.label}>{name}</span>
            </label>
          ))}
        </div>
      </div>
    </div>
  );
};

GroupSelector.propTypes = {
  handleSetGroup: PropTypes.func.isRequired,
  groups: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    values: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    })),
  })),
  loading: PropTypes.bool,
};

GroupSelector.defaultProps = {
  groups: [],
  loading: true,
};
