import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classNames from 'clsx';
import { Button } from '../button';
import styles from './icon-text-button.module.css';

export const IconTextButton = ({
  to,
  title,
  onClick,
  variant,
  children,
  className,
  btnClassName,
  isHorizontal,
  ...rest
}) => (
  <div className={classNames(styles.wrapper, className, { [styles.horizontal]: isHorizontal })}>
    {to ? (
      <Link
        className={btnClassName}
        to={to}
        {...rest}
      >
        {children}
      </Link>
    ) : (
      <Button
        title={title}
        onClick={onClick}
        variant={variant}
        className={classNames(styles.button, btnClassName)}
        {...rest}
      >
        {children}
      </Button>
    )}
    {title && (<p className={styles.label}>{title}</p>)}
  </div>
);

IconTextButton.propTypes = {
  to: PropTypes.string,
  variant: PropTypes.string,
  title: PropTypes.string,
  onClick: PropTypes.func,
  isHorizontal: PropTypes.bool,
  className: PropTypes.string,
};

IconTextButton.defaultProps = {
  to: '',
  title: '',
  variant: 'success',
  isHorizontal: false,
  className: '',
};
