import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import styles from './custom-button.module.css';

const isExternalUrl = (url) => /^https?:\/\//.test(url);

export const CustomButton = ({ to, onClick, children }) => {
  if (typeof onClick === 'function') {
    return (
      <button className={styles.customButton} type="button" onClick={onClick}>{children}</button>
    );
  }

  return (
    <>
      {isExternalUrl(to) ? (
        <a href={to} className={styles.customButton}>
          {children}
        </a>
      ) : (
        <Link to={to} className={styles.customButton}>
          {children}
        </Link>
      )}
    </>
  );
};

CustomButton.propTypes = {
  to: PropTypes.string,
  title: PropTypes.string,
};
