import gql from 'graphql-tag';

export const GENERATE_UPLOAD_URL = gql`
  mutation generateUploadUrl($type: String!, $fileName: String!, $fileType: String!) {
    generateUploadUrl(type: $type, fileName: $fileName, fileType: $fileType) {
      signedRequest
    }
  }
`;

export const ADD_RESULTS_UPLOAD_SECTION = gql`
  mutation addResultsUploadSection($reportData: ResultsUploadDataInput!) {
    addResultsUploadSection(reportData: $reportData) {
      id
    }
  }
`;
