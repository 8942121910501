import React from 'react';
import PropTypes from 'prop-types';
import { STATUSES } from '../../utils/statuses-constants';
import styles from './status-picker.module.css';

export const StatusPicker = ({ onSelectedCallback }) => (
  <ul className={styles.statusPicker}>
    {STATUSES.map(({ icon, value, title }) => (
      <li key={value} className={styles.statusIcon}>
        <img
          src={icon}
          width="45px"
          height="45px"
          alt={title}
          title={title}
          onClick={() => onSelectedCallback(value)}
          style={{ cursor: 'pointer' }}
        />
      </li>
    ))}
  </ul>
);

StatusPicker.propTypes = {
  onSelectedCallback: PropTypes.func.isRequired,
};
