import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import orangeLogo from '../../images/swoosh.png';
import blackLogo from '../../images/swooshBlack.png';
import styles from './logo.module.css';

export const Logo = ({ noLink }) => (
  <>
    {noLink ? (
      <img
        src={orangeLogo}
        width="100px"
        alt="Nike"
        className={styles.logo}
        data-testid="image-logo"
      />
    ) : (
      <Link className={styles.link} to="/home">
        <img
          src={blackLogo}
          width="120px"
          alt="Nike"
          data-testid="link-logo"
        />
      </Link>
    )}
  </>
);

Logo.propTypes = {
  noLink: PropTypes.bool,
};

Logo.defaultProps = {
  noLink: false,
};
