import React, { useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import { addWindowEventListener } from '../../utils/window-event-listener';
import { useOnClickOutside } from '../../utils/use-click-outside';
import styles from './modal.module.css';

export const Modal = ({
  onClose,
  children,
  closeOnEsc,
  closeOnOutsideClick,
  ...rest
}) => {
  const modalRef = useRef(null);

  const handleClickOutside = () => {
    if (closeOnOutsideClick) {
      onClose();
    }
  };

  const modalTestId = rest?.['data-testid'] || 'modal';

  const renderModal = () => (
    <div className={styles.windowContainer} data-testid="modal-wrapper">
      <div className={styles.modalContainer} data-testid="overlay">
        <div className={styles.modal} ref={modalRef} data-testid={modalTestId}>
          {children}
        </div>
      </div>
    </div>
  );

  useOnClickOutside(modalRef, handleClickOutside);

  useEffect(() => {
    let removeKeyDownListener;

    if (closeOnEsc) {
      const handleKeyDown = (event) => {
        if (event.key === 'Escape') {
          onClose();
        }
      };

      removeKeyDownListener = addWindowEventListener('keydown', handleKeyDown);
    }

    return () => {
      if (closeOnEsc && removeKeyDownListener) {
        removeKeyDownListener();
      }
    };
  }, [closeOnEsc, onClose]);

  return createPortal(
    renderModal(),
    document.body
  );
};
