import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import omit from 'lodash.omit';
import { NotificationManager } from 'react-notifications';
import classNames from 'clsx';
import { Button } from '../button';
import { Spinner } from '../spinner';
import { useUser } from '../../utils/use-user';
import { CONSTANTS } from '../../constants';
import { ReportSection } from '../report-section';
import { SectionSelector } from '../section-selector';
import { EditReportDetails } from '../edit-report-details';
import { EditReportMainRisks } from '../edit-report-main-risks';
import { EditReportKeyValues } from '../edit-report-key-values';
import { EditReportFilesUploader } from '../edit-report-files-uploader';
import { EditReportPerformance } from '../edit-report-performance';
import { EditReportResults } from '../edit-report-results';
import { NavigationPrompt } from '../navigation-prompt';
import { pluralize } from '../../utils/pluralize';
import { Tag, VARIANTS as TAG_VARIANTS } from '../tag';
import { Modal } from '../modal';
import { ConfirmPublishModal } from './confirm-publish-modal';
import { isReportCurrentlyLockedByUser, getRedirectLocation } from './helpers';
import { ADD_REPORT, DELETE_SECTION } from './queries';
import 'react-day-picker/lib/style.css';
import styles from './report-editor.module.css';

export const ReportEditor = ({
  reportToEdit,
  project,
}) => {
  const { user } = useUser();

  const [isLoading, setIsLoading] = useState(false);
  const [isModalShown, setIsModalShown] = useState(false);
  const [nextLocation, setNextLocation] = useState(null);
  const [isConfirmedNavigation, setIsConfirmedNavigation] = useState(false);
  const [isWeekSelected, setIsWeekSelected] = useState(Boolean(reportToEdit?.date));
  const [isInitialEdit, setIsInitialEdit] = useState(true);

  const [reportDetails, setReportDetails] = useState({});
  const [performanceSection, setPerformanceSection] = useState({});
  const [keyValuesSection, setKeyValuesSection] = useState({});
  const [mainRisksSection, setMainRisksSection] = useState({});
  const [resultsSection, setResultsSection] = useState({});
  const [resultsUploadSection, setResultsUploadSection] = useState({});

  const EDITOR_SECTIONS = {
    REPORT_DETAILS: {
      key: 'REPORT_DETAILS',
      title: 'Report Details',
      selectable: !Boolean(reportDetails.id),
    },
    SECTION_SELECTOR: {
      key: 'SECTION_SELECTOR',
      title: 'Section Selector',
      selectable: false,
    },
    REPORT_PREVIEW: {
      key: 'REPORT_PREVIEW',
      title: 'Report Preview',
      selectable: Boolean(reportDetails.id),
    },
    RESULTS_UPLOADER: {
      key: 'RESULTS_UPLOADER',
      title: 'Upload Reporting templates',
      selectable: !Boolean(resultsUploadSection.id),
    },
    PERFORMANCE: {
      key: 'PERFORMANCE',
      title: 'Product Performance',
      selectable: !Boolean(performanceSection.id),
    },
    KEY_VALUES: {
      key: 'KEY_VALUES',
      title: 'Key Values',
      selectable: !Boolean(keyValuesSection.id),
    },
    MAIN_RISKS: {
      key: 'MAIN_RISKS',
      title: 'Main Risks',
      selectable: !Boolean(mainRisksSection.id),
    },
    RESULTS: {
      key: 'RESULTS',
      title: 'Results',
      selectable: !Boolean(resultsSection.id),
    },
  };
  const [activeSection, setActiveSection] = useState(EDITOR_SECTIONS.REPORT_DETAILS.key);

  const [saveReport] = useMutation(ADD_REPORT);
  const [removeSection] = useMutation(DELETE_SECTION);

  const switchSection = (section) => {
    setActiveSection(section);
  };

  const handleNavigationBlock = (location) => {
    setIsModalShown(true);
    setNextLocation(location);
  };

  const handleModalClose = () => {
    setIsModalShown(false);
    setNextLocation(null);
  };

  useEffect(() => {
    const reportData = reportToEdit ? JSON.parse(JSON.stringify(reportToEdit)) : {
      project: project,
      projectId: project?.id,
      program: project?.program,
      product: project?.product,
      date: CONSTANTS.SELECT_DATE,
      metadata: {},
    };

    const reportDetails = {
      id: reportData.id,
      status: reportData.status,
      date: reportData.date,
      projectId: reportData.project?.id,
      product: reportData.product,
      program: reportData.program,
      attributes: reportData.attributes?.length ? reportData.attributes : reportData.project?.attributes,
      highlights: reportData.highlights,
      weekHighlight: reportData.weekHighlight,
      isRemoved: reportData.isRemoved,
      isLocked: reportData.isLocked,
      metadata: reportData.metadata,
      name: reportData.name,
    };

    setReportDetails(reportDetails);

    if (reportToEdit?.performanceSection) {
      setPerformanceSection(JSON.parse(JSON.stringify(reportToEdit.performanceSection)));
    }

    if (reportToEdit?.performanceSection) {
      setPerformanceSection(JSON.parse(JSON.stringify(reportToEdit.performanceSection)));
    }

    if (reportToEdit?.keyValuesSection) {
      setKeyValuesSection(JSON.parse(JSON.stringify(reportToEdit.keyValuesSection)));
    }

    if (reportToEdit?.mainRisksSection) {
      setMainRisksSection(JSON.parse(JSON.stringify(reportToEdit.mainRisksSection)));
    }

    if (reportToEdit?.resultsSection) {
      setResultsSection(JSON.parse(JSON.stringify(reportToEdit.resultsSection)));
    }

    if (reportToEdit?.resultsUploadSection) {
      setResultsUploadSection(JSON.parse(JSON.stringify(reportToEdit.resultsUploadSection)));
    }
  }, [reportToEdit, project]);

  const editIfSaved = async (editFn) => {
    if (activeSection === EDITOR_SECTIONS.SECTION_SELECTOR.key || activeSection === EDITOR_SECTIONS.REPORT_PREVIEW.key) {
      await editFn();
    } else {
      NotificationManager.error('First save/discard this section', 'Please');
    }
  };

  /**Report details Start**/
  const saveReportDetails = async (details) => {
    const updatedReportDetails = omit(details, ['attributes', 'metadata', 'project', '__typename']);

    const { data } = await saveReport({
      variables: {
        reportData: updatedReportDetails,
      },
    });
    const savedReportData = data?.addReport;

    return {
      ...details,
      ...savedReportData,
    };
  };

  const handleSaveDetails = async (details) => {
    const savedDetails = await saveReportDetails(details);

    NotificationManager.success('Successfully saved the report', 'Ok');
    setIsWeekSelected(true);
    setIsInitialEdit(false);
    setActiveSection(EDITOR_SECTIONS.SECTION_SELECTOR.key);
    setReportDetails(savedDetails);
    if (!details?.id) {
      window.history.pushState({}, '', `/editor/projects/${reportDetails.projectId}/reports/${savedDetails.id}/edit`);
    }
  };

  const publishReport = async () => {
    setIsLoading(true);
    const reportToPublish = {
      ...JSON.parse(JSON.stringify(reportDetails)),
      isRemoved: false,
      isLocked: false,
    };
    await saveReportDetails(reportToPublish);
    NotificationManager.success('Successfully published the report', 'Done');
    setIsLoading(false);
    setIsConfirmedNavigation(true);
  };

  const unlockReport = async () => {
    setIsLoading(true);
    let reportToPublish = {
      ...JSON.parse(JSON.stringify(reportDetails)),
      isLocked: false,
    };
    await saveReportDetails(reportToPublish);
    NotificationManager.success('Successfully unlocked the report', 'Done');
    setIsLoading(false);
    setIsConfirmedNavigation(true);
  };

  const keepReport = () => {
    setIsConfirmedNavigation(true);
  };

  const editReportDetails = () => {
    editIfSaved(() => {
      setActiveSection(EDITOR_SECTIONS.REPORT_DETAILS.key);
    });
  };
  /**Report details End**/

  const skipSectionChanges = () => {
    setActiveSection(EDITOR_SECTIONS.SECTION_SELECTOR.key);
  };

  /**Report performance Start**/
  const saveReportPerformance = (performanceDetails) => {
    setActiveSection(EDITOR_SECTIONS.SECTION_SELECTOR.key);
    setPerformanceSection(performanceDetails);
  };

  const editReportPerformanceSection = () => {
    editIfSaved(() => {
      setActiveSection(EDITOR_SECTIONS.PERFORMANCE.key);
    });
  };
  /**Report performance End**/

  /**Report Key Values Start**/
  const saveReportKeyValues = (keyValuesDetails) => {
    setActiveSection(EDITOR_SECTIONS.SECTION_SELECTOR.key);
    setKeyValuesSection(keyValuesDetails);
  };

  const editReportKeyValuesSection = () => {
    editIfSaved(() => {
      setActiveSection(EDITOR_SECTIONS.KEY_VALUES.key);
    });
  };
  /**Report key Values End**/

  /**Report Main Risks Start**/
  const saveReportMainRisks = (risksSection) => {
    setActiveSection(EDITOR_SECTIONS.SECTION_SELECTOR.key);
    setMainRisksSection(risksSection);
  };

  const editReportMainRisksSection = () => {
    editIfSaved(() => {
      setActiveSection(EDITOR_SECTIONS.MAIN_RISKS.key);
    });
  };
  /**Report Main Risks End**/

  /**Report Results Start**/
  const saveReportResults = (resultsDetails) => {
    setActiveSection(EDITOR_SECTIONS.SECTION_SELECTOR.key);
    setResultsSection(resultsDetails);
  };

  const editReportResultsSection = () => {
    editIfSaved(() => {
      setActiveSection(EDITOR_SECTIONS.RESULTS.key);
    });
  };
  /**Report Results End**/

  /**Report Results Upload Section Start**/
  const saveReportResultsUploadSection = (details, saveAndClose) => {
    setResultsUploadSection(details);
    if(Boolean(saveAndClose)) {
      setActiveSection(EDITOR_SECTIONS.SECTION_SELECTOR.key);
    }
  };

  const editReportResultsUploadSection = () => {
    editIfSaved(() => {
      setActiveSection(EDITOR_SECTIONS.RESULTS_UPLOADER.key);
    });
  };
  /**Report Results Upload Section End**/

  const deleteSection = async ({ id: sectionId }) => {
    setIsLoading(true);
    const sections = [
      performanceSection,
      keyValuesSection,
      mainRisksSection,
      resultsSection,
      resultsUploadSection,
    ];
    const sectionToDelete = sections.find((section) => section?.id === sectionId);

    if(sectionToDelete?.id) {
      await removeSection({
        variables: {
          id: sectionId,
        },
      });
    }

    setActiveSection(EDITOR_SECTIONS.SECTION_SELECTOR.key);
    switch (sectionId) {
      case performanceSection.id:
        setPerformanceSection({});
        break;
      case keyValuesSection.id:
        setKeyValuesSection({});
        break;
      case mainRisksSection.id:
        setMainRisksSection({});
        break;
      case resultsSection.id:
        setResultsSection({});
        break;
      case resultsUploadSection.id:
        setResultsUploadSection({});
        break;
      default:
        break;
    }
    setIsLoading(false);
  };

  if (isLoading) {
    return <Spinner title="Please wait..." />;
  }

  const renderTags = () => (
    <>
      <Tag variant={reportDetails.isRemoved ? TAG_VARIANTS.red : TAG_VARIANTS.green}>
        {reportDetails.isRemoved ? 'unpublished' : 'published'}
      </Tag>
      {reportDetails.isLocked && (
        <Tag>
          locked <i className="fa fa-lock" />
        </Tag>
      )}
    </>
  );

  return (
    <div className={styles.editorWrapper}>
      <NavigationPrompt
        redirectTo={getRedirectLocation(nextLocation, project?.id)}
        isConfirmed={isConfirmedNavigation}
        onNavigationBlock={handleNavigationBlock}
        shouldBlockPageUnload={isReportCurrentlyLockedByUser(reportDetails)}
      />
      {isModalShown && (
        <Modal closeOnEsc closeOnOutsideClick onClose={handleModalClose}>
          <ConfirmPublishModal
            onPublish={publishReport}
            onCancel={keepReport}
            onUnlock={unlockReport}
          />
        </Modal>
      )}
      {!isInitialEdit && (
        <div className={styles.editorSections}>
          <div className={styles.editorSectionButton} style={{ backgroundColor: reportDetails.status }}>
            <div>
              <div>Report For: {reportDetails.date}</div>
              <div data-testid="created-by-name">Created By: {reportDetails.metadata?.createdBy}</div>
              {renderTags()}
            </div>
            <i
              onClick={editReportDetails}
              className={classNames('fa fa-edit', styles.editorSectionIcon)}
              data-testid="edit-details"
              title="Edit"
            />
          </div>

          {performanceSection?.id && (
            <div className={styles.editorSectionButton}>
              {EDITOR_SECTIONS.PERFORMANCE.title}
              <i
                onClick={editReportPerformanceSection}
                className={classNames('fa fa-edit', styles.editorSectionIcon)}
                data-testid="edit-performance"
                title="Edit"
              />
            </div>
          )}
          {keyValuesSection?.id && (
            <div className={styles.editorSectionButton}>
              {EDITOR_SECTIONS.KEY_VALUES.title}
              <i
                onClick={editReportKeyValuesSection}
                className={classNames('fa fa-edit', styles.editorSectionIcon)}
                data-testid="edit-key-values"
                title="Edit"
              />
            </div>
          )}
          {mainRisksSection?.id && (
            <div className={styles.editorSectionButton}>
              {EDITOR_SECTIONS.MAIN_RISKS.title}
              <i
                onClick={editReportMainRisksSection}
                className={classNames('fa fa-edit', styles.editorSectionIcon)}
                data-testid="edit-risks"
                title="Edit"
              />
            </div>
          )}
          {resultsSection?.id && (
            <div className={styles.editorSectionButton}>
              {EDITOR_SECTIONS.RESULTS.title}
              <i
                onClick={editReportResultsSection}
                className={classNames('fa fa-edit', styles.editorSectionIcon)}
                data-testid="edit-results"
                title="Edit"
              />
            </div>
          )}
          {resultsUploadSection?.id && (
            <div className={styles.editorSectionButton}>
              {EDITOR_SECTIONS.RESULTS_UPLOADER.title}: {pluralize(resultsUploadSection.details?.length, 'file', 'files', 'no')} available
              <i
                onClick={editReportResultsUploadSection}
                className={classNames('fa fa-edit', styles.editorSectionIcon)}
                data-testid="edit-files"
                title="Edit"
              />
            </div>
          )}

          {activeSection === EDITOR_SECTIONS.REPORT_PREVIEW.key && (
            <div className={classNames(styles.editorSectionButton, styles.addSection)}>
              <i
                onClick={() => switchSection(EDITOR_SECTIONS.SECTION_SELECTOR.key)}
                className={classNames('fa fa-plus', styles.editorSectionIconPlus)}
                title="Add Section"
              />
            </div>
          )}
        </div>
      )}

      {(() => {
        switch (activeSection) {
          case EDITOR_SECTIONS.REPORT_DETAILS.key:
            return (
              <EditReportDetails
                onSubmit={handleSaveDetails}
                onChangesSkip={!isInitialEdit && skipSectionChanges}
                isWeekSelected={isWeekSelected}
                reportDetails={reportDetails}
                projectName={project.name}
              />
            );
          case EDITOR_SECTIONS.PERFORMANCE.key:
            return (
              <EditReportPerformance
                performanceSection={performanceSection}
                onSave={saveReportPerformance}
                onChangesSkip={skipSectionChanges}
                deleteCallback={() => { deleteSection(performanceSection); }}
                reportId={reportDetails.id}
              />
            );
          case EDITOR_SECTIONS.KEY_VALUES.key:
            return (
              <EditReportKeyValues
                keyValuesSection={keyValuesSection}
                onSave={saveReportKeyValues}
                onChangesSkip={skipSectionChanges}
                onCancelClick={() => { deleteSection(keyValuesSection); }}
                reportId={reportDetails.id}
              />
            );
          case EDITOR_SECTIONS.MAIN_RISKS.key:
            return (
              <EditReportMainRisks
                mainRisksSection={mainRisksSection}
                onSave={saveReportMainRisks}
                onChangesSkip={skipSectionChanges}
                onCancelClick={() => { deleteSection(mainRisksSection); }}
                reportId={reportDetails.id}
              />
            );
          case EDITOR_SECTIONS.RESULTS.key:
            return (
              <EditReportResults
                resultsSection={resultsSection}
                onSave={saveReportResults}
                onChangesSkip={skipSectionChanges}
                deleteCallback={() => { deleteSection(resultsSection); }}
                reportId={reportDetails.id}
                reportDates={reportDetails.date}
              />
            );
          case EDITOR_SECTIONS.RESULTS_UPLOADER.key:
            return (
              <EditReportFilesUploader
                uploadSection={resultsUploadSection}
                onSave={saveReportResultsUploadSection}
                onChangesSkip={skipSectionChanges}
                onCancelClick={() => { deleteSection(resultsUploadSection); }}
                reportId={reportDetails.id}
              />
            );
          case EDITOR_SECTIONS.REPORT_PREVIEW.key:
            return (
              <>
                <div className={styles.publish}>
                  <Button variant="success" onClick={publishReport}>
                    Publish Report
                  </Button>
                </div>
                <ReportSection
                  user={user}
                  report={{
                    ...reportDetails,
                    performanceSection,
                    keyValuesSection,
                    mainRisksSection,
                    resultsSection,
                    resultsUploadSection,
                  }}
                />
              </>
            );
          case EDITOR_SECTIONS.SECTION_SELECTOR.key:
            return (
              <SectionSelector
                switchSection={switchSection}
                sections={Object.values(EDITOR_SECTIONS).filter(({ selectable }) => selectable)}
              />
            );
          default:
            return null;
        }
      })()}
    </div>
  );
};
