import React, { useState, useEffect, useMemo } from 'react';
import { Modal } from '../modal';
import { addIdToNoShowList, removeRestrictedCharacters, isIdInNoShowList } from './helpers';

export const ChangelogPopup = ({ id, children }) => {
  const cleanId = useMemo(() => removeRestrictedCharacters(id), [id]);
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    if (!isIdInNoShowList(cleanId)) {
      setIsModalVisible(true);
    }
  }, [cleanId]);

  const handleModalClose = () => {
    setIsModalVisible(false);
  };

  const handleCloseAndDoNotShowAgain = () => {
    addIdToNoShowList(cleanId);
    handleModalClose();
  };

  const childrenIsNotRenderProp = typeof children !== 'function';
  if (childrenIsNotRenderProp) {
    return null;
  }

  return (
    <>
      {isModalVisible && (
        <Modal onClose={handleModalClose} data-testid="popup">
          {children(handleModalClose, handleCloseAndDoNotShowAgain)}
        </Modal>
      )}
    </>
  );
};
