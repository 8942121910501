export const CONSTANTS = {
  clientId: 'nike.serve.value-dash',
  oktaAuthURL: process.env.REACT_APP_OKTA_URL,
  USER_GROUP: 'Application.FutureRetail.Users',
  ADMIN_GROUP: 'App.FutureRetail.ValueDash.Admins',
  presentationTitle: 'Presentation Mode',
  editorTitle: 'Editor Mode',
  adminTitle: 'Admin Mode',
  loginTitle: 'Login',
  mainTitle: 'Value Optimization Dashboard',
  errorTitle: 'Oops you are not authorized to access this information!!!',
  SELECT_DATE: 'Select a date',
  REQUIRED_FIELD: 'Required field',
  LOCAL_STORAGE_ORDER_KEY: 'projectsOrder',
  performanceTab: 'Performance',
  keyFeaturesTab: 'Key Features',
  keyValuesTab: 'Key Values',
  mainRisksTab: 'Main Risks',
  resultsTab: 'Results',
  resultsUploadsTab: 'Uploads',
};
