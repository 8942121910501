import dayjs from 'dayjs';

export const replaceTemplateVariables = (string, replaceMap) => {
  const templateRegexp = new RegExp(Object.keys(replaceMap).join('|'), 'g');
  const replaceFunction = (match) => replaceMap[match];
  return string.replace(templateRegexp, replaceFunction);
};

export const parseReportDates = (reportDates) => {
  if(!reportDates) {
    return null;
  }

  const dates = reportDates.split('-');
  const startDate = dayjs(dates[0]);
  const endDate = dayjs(dates[1]);

  return {
    start: `'${startDate.format('YYYY-MM-DD')} 00:00:00'`,
    end: `'${endDate.format('YYYY-MM-DD')} 23:59:59'`,
  };
};

export const replaceQueryTemplateVariables = (query, dates) => {
  if(!query) {
    return null;
  } else if (!dates) {
    return query;
  }

  const START_DATE_TEMPLATE_VARIABLE = '%START_DATE%';
  const END_DATE_TEMPLATE_VARIABLE = '%END_DATE%';
  const replaceMap = {
    [START_DATE_TEMPLATE_VARIABLE]: dates.start,
    [END_DATE_TEMPLATE_VARIABLE]: dates.end,
  };

  return replaceTemplateVariables(query, replaceMap);
};
