import React from 'react';
import PropTypes from 'prop-types';
import styles from './report-performance-section.module.css';

export const ReportPerformanceSection = ({ performanceData }) => (
  <div className={styles.performance}>
    {performanceData.items.map(({ title, values }, index) => (
      <div key={index} className={styles.card}>
        <h4 className={styles.cardTitle}>
          {title}
        </h4>
        <div className={styles.cardTextWrapper}>
          {values.map((value, i) => (
            <p key={i} className={styles.cardText}>
              {value}
            </p>
          ))}
        </div>
      </div>
    ))}
  </div>
);

ReportPerformanceSection.propTypes = {
  performanceData: PropTypes.shape({
    items: PropTypes.arrayOf(PropTypes.shape({
      title: PropTypes.string,
      values: PropTypes.arrayOf(PropTypes.string),
    })),
  }).isRequired,
};
