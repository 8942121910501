import React from 'react';
import Slider from 'react-slick';
import { Button } from '../../button';
import NewRelicQueryImage from '../../../images/new-relic-query.jpg';
import RunQuery from '../../../images/query-run.jpg';
import styles from './results-changelog-slider.module.css';
import './slick.css';

export const ResultsChangelogSlider = ({ onCloseClick, onCloseAndForgetClick }) => {
  const sliderSettings = {
    dots: true,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    draggable: false,
  };

  const sampleQuery = 'SELECT count(*) as Created FROM  Assist where appName= \'retail:prd-assist\' and Geo  IN (\'EM\') and name = \'Unite\' and MemberEvent = \'CreateNewMemberSuccess\'  and StoreNumber in (764) since %START_DATE% until %END_DATE%';

  return (
    <div className={styles.popupWrapper}>
      <Slider {...sliderSettings}>
        <div>
          <h2 className={styles.headline}>New Integration</h2>
          <h3 className={styles.headline}>New Relic</h3>
          <p className={styles.catch}>Do you want to fill the Results section in 5 seconds?</p>
        </div>
        <div>
          <h2 className={styles.headline}>1</h2>
          <img src={NewRelicQueryImage} alt="New Relic Query button"/>
          <p className={styles.catch}>Click the <span className="fa fa-pie-chart"/> button to add the New Relic query. Try this one:
          </p>
          <p>
            <code>{sampleQuery}</code>
          </p>
        </div>
        <div>
          <h2 className={styles.headline}>2</h2>
          <img src={RunQuery} alt="Run Query Button"/>
          <p className={styles.catch}>Click the <span className="fa fa-play"/> button run the query.</p>
        </div>
        <div>
          <h2 className={styles.headline}>3</h2>
          <p className={styles.catch}>Next week, just click the <span className="fa fa-play"/> button. The query is copied alongside the report.</p>
          <p>Use %START_DATE% and %END_DATE% template variables to set Report dates to your query.</p>
          <div className={styles.actions}>
            <Button variant="success" onClick={onCloseClick} data-testid="close-popup">
              Close
            </Button>
            <Button variant="info" onClick={onCloseAndForgetClick} data-testid="close-and-dont-show">
              Close and don't show again
            </Button>
          </div>
        </div>
      </Slider>
    </div>
  );
};
