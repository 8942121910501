import gql from 'graphql-tag';
import { REPORT_FRAGMENT } from '../../utils/fragments/report';

export const GET_PROJECT = gql`
  query($id: String!) {
    getProject(id: $id) {
      id
      name
      product
      program
      attributes {
        id
        attributeCode
      }
    }
  }
`;

export const GET_REPORT_BY_ID = gql`
  query($id: String!) {
    getReportById(id: $id) {
      ...ReportFields
    }
  }
  ${REPORT_FRAGMENT}
`;
