import { useQuery } from '@apollo/client';

/**
 * https://github.com/apollographql/react-apollo/issues/3499#issuecomment-586039082
 */
export const useImperativeQuery = (query) => {
  const { refetch, loading } = useQuery(query, { skip: true });

  const imperativelyCallQuery = ({ variables }) => {
    return refetch(variables);
  };

  return [imperativelyCallQuery, { loading }];
};
