import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'clsx';
import { Formik, Form, Field } from 'formik';
import { CONSTANTS } from '../../constants';
import { ExpandableHighlights } from '../expandable-highlights';
import styles from './report-highlight-section.module.css';

export const ReportHighlightSection = ({ report, handleSaveReport, user, isPrintVersion }) => {
  const {
    id,
    highlights,
    weekHighlight,
    product,
    status,
    program,
    date,
    attributes,
  } = report;
  const [isEdit, setIsEdit] = useState(false);

  const handleSubmitReport = ({ weekHighlight: inputWeekHighlight }) => {
    weekHighlight !== inputWeekHighlight
      ? handleSaveReport(id, inputWeekHighlight)
      : setIsEdit(false);
  };

  const initialValues = {
    weekHighlight,
  };

  const isWeekHighlightsEditable = Boolean(handleSaveReport) && user?.groups?.includes(CONSTANTS.USER_GROUP);

  return (
    <div className={styles.highlight}>
      <div className={styles.status}>
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60" className={styles.circleStatus}>
          <circle cx="30" cy="30" r="30" fill={status}/>
        </svg>
      </div>
      <div className={styles.wrapper}>
        {weekHighlight && (
          <div className={styles.weekHighlightWrapper}>
            <h2 className={styles.weekHighlight}>
              <i className="fa fa-exclamation-circle" />
              Highlight of the week:
            </h2>
            {isEdit ? (
              <div className={styles.inputWrapper}>
                <Formik
                  initialValues={initialValues}
                  onSubmit={handleSubmitReport}
                  enableReinitialize={true}
                  validateOnChange={false}
                  validateOnBlur={false}
                >
                  <Form>
                    <Field name="weekHighlight" type="text" placeholder="Enter Week Highlight" autoFocus />
                    <span className={styles.inputActions}>
                      <button className={styles.submitButton} type="submit" title="Save week highlight">
                        <i className={classNames(styles.icon, styles.iconSave, 'fa fa-check')}/>
                      </button>
                      <button
                        type="reset"
                        onClick={() => setIsEdit(false)}
                        className={styles.cancelButton}
                        title="Cancel"
                      >
                        <i className={classNames(styles.icon, styles.iconCancel, 'fa fa-times')} />
                      </button>
                    </span>
                  </Form>
                </Formik>
              </div>
            ) : (
              <div className={styles.highlightText}>
                <span>{weekHighlight}</span>
                {isWeekHighlightsEditable && (
                  <button
                    onClick={() => setIsEdit(true)}
                    className={styles.editButton}
                    title="Edit week highlight"
                  >
                    <i className={classNames(styles.icon, styles.iconEdit, 'fa fa-pencil')} />
                  </button>
                )}
              </div>
            )}
          </div>
        )}
        <ExpandableHighlights highlights={highlights} isPrintVersion={isPrintVersion} />
      </div>
      <div className={styles.data}>
        <div className={styles.reportDataSection}>
          <i className={classNames('fa fa-calendar', styles.icon)} />
          <span className={styles.reportDataTitle}>Week:</span>
          {date}
        </div>
        <div className={styles.reportDataSection}>
          <i className={classNames('fa fa-id-card', styles.icon)} />
          <span className={styles.reportDataTitle}>Product:</span>
          {product}
        </div>
        <div className={styles.reportDataSection}>
          <i className={classNames('fa fa-id-card', styles.icon)} />
          <span className={styles.reportDataTitle}>Program:</span>
          {program}
        </div>
        {attributes?.length > 0 && (
          attributes.map(({ attribute, id }) => (
            <div key={attribute.id} className={styles.reportDataSection}>
              <i className={classNames('fa fa-briefcase', styles.icon)} />
              <span className={styles.reportDataTitle}>{attribute.name}:</span>
              {attribute.values.find(({ id: valueId }) => id === valueId )?.name || '-'}
            </div>
          ))
        )}
      </div>
    </div>
  );
};

ReportHighlightSection.propTypes = {
  report: PropTypes.shape({
    status: PropTypes.string,
    highlights: PropTypes.string,
    weekHighlight: PropTypes.string,
    reportDate: PropTypes.string,
    product: PropTypes.string,
    program: PropTypes.string,
  }),
  handleSaveReport: PropTypes.func,
};

ReportHighlightSection.defaultProps = {
  report: {
    status: '',
    highlights: '',
    weekHighlight: '',
    reportDate: '',
    product: '',
    program: '',
    attributes: [],
  },
  handleSaveReport: null,
};
