import React, { useState } from 'react';
import { NotificationManager } from 'react-notifications';
import classNames from 'clsx';
import { FilePicker } from '../../file-picker';
import { Modal } from '../../modal';
import importExcel from '../../../images/results-import-excel.jpg';
import importValues from '../../../images/results-import-values.jpg';
import { DEFAULT_OPTION } from '../constants';
import styles from '../edit-report-results.module.css';

export const ResultsImport = ({ onImportComplete }) => {
  const [isImportInProgress, setIsImportInProgress] = useState(false);
  const [isModalShown, setIsModalShown] = useState(false);

  const handleXlsImport = (file, resetInputValue) => {
    setIsImportInProgress(true);
    import('xlsx').then((XLSX) => {
      try {
        const reader = new FileReader();
        reader.onload = (event) => {
          const data = new Uint8Array(event.target.result);
          const workbook = XLSX.read(data, { type: 'array' });
          const resultTitle = workbook.SheetNames[0];
          const sheet = workbook.Sheets[resultTitle];
          const sheetJson = XLSX.utils.sheet_to_json(sheet, { defval: '' });
          const result = {
            title: resultTitle,
            items: sheetJson.map((item) => ({
              key: item[Object.keys(item)[0]]?.toString() || '',
              value1: item[Object.keys(item)[1]]?.toString() || '',
              value1Query: '',
              value2: item[Object.keys(item)[2]]?.toString() || '',
              value3: item[Object.keys(item)[3]]?.toString() || '',
              value1Color: DEFAULT_OPTION,
              value2Color: DEFAULT_OPTION,
              value3Color: DEFAULT_OPTION,
            })),
          };
          onImportComplete(result);
          if (typeof resetInputValue === 'function') {
            resetInputValue();
          }
          setIsImportInProgress(false);
        };
        reader.readAsArrayBuffer(file);
      } catch (e) {
        setIsImportInProgress(false);
        NotificationManager.error('Error while parsing Excel file');
        console.log(e);
      }
    });
  };

  return (
    <div className={styles.import}>
      {isModalShown && (
        <Modal onClose={() => setIsModalShown(false)} closeOnEsc closeOnOutsideClick>
          <p data-testid="import-results-help">You can import results data from Excel file like this:</p>
          <img src={importExcel} alt="Results Excel file example" width={250} />
          <p>Sheet title will be used as Results title (multiple sheets files are supported)</p>
          <p>Column A will be converted to Keys</p>
          <p>Columns B-D will be converted to "This week", "vs Target" and "vs Last week" accordingly</p>
          <img src={importValues} alt="Imported results" width={700}/>
        </Modal>
      )}
      <button
        data-testid="import-results-help-button"
        onClick={() => setIsModalShown(true)}
        type="button"
        className={styles.importHelpButton}
      >?</button>
      <FilePicker
        variant="outline-secondary"
        onChange={handleXlsImport}
        allowedExtensions={['.xls', '.xlsx']}
        disabled={isImportInProgress}
      >
        <span className={classNames('fa', {
          'fa-upload': !isImportInProgress,
          'fa-cog fa-spin': isImportInProgress,
        })} /> Import from .xlsx
      </FilePicker>
    </div>
  );
};
