import React, { Fragment } from 'react';
import classNames from 'clsx';
import styles from './report-main-risks-section.module.css';

const colorMap = {
  red: '#dc3545',
  yellow: '#ffc107',
  green: '#28a745',
};

export const ReportMainRisksSection = ({ mainRisksData }) => (
  <div className="table-responsive">
    <table className={styles.table}>
      <thead>
        <tr className={styles.tableHeadRow}>
          <th>Help</th>
          <th>Impact</th>
          <th colSpan="5">Description</th>
          <th colSpan="5">Mitigation</th>
          <th>Due</th>
          <th colSpan="2">Owner</th>
        </tr>
      </thead>
      <tbody>
        {[...mainRisksData.risks]
          .sort((a, b) => a.isResolved - b.isResolved)
          .map(({ risk, helpNeeded, isResolved, impact, description, mitigation, due, owner }, index) => (
          <Fragment key={index}>
            <tr className={classNames(styles.riskTitleRow, { [styles.isResolvedRow]: isResolved })}>
              <td colSpan="15" className={styles.riskTitle}>
                {isResolved ? (
                  <span>{`${risk} (Resolved)`}</span>
                ) : (
                  <span>{risk}</span>
                )}
              </td>
            </tr>
            <tr className={classNames(styles.tableDataRow, { [styles.isResolvedRow]: isResolved })}>
              <td>
                {helpNeeded && (
                  <i className={classNames('fa fa-exclamation-circle', styles.helpIcon)} title="Help Needed" />
                )}
              </td>
              <td>
                <div
                  className={styles.circleSmallStatus}
                  style={{ backgroundColor: colorMap[impact.toLowerCase()] }}
                />
              </td>
              <td colSpan="5">{description}</td>
              <td colSpan="5">{mitigation}</td>
              <td>{due}</td>
              <td colSpan="2">{owner}</td>
            </tr>
          </Fragment>
        ))}
      </tbody>
    </table>
  </div>
);
