import React, { createContext, useContext, useReducer } from 'react';

export const UserContext = createContext();
const initialUserState = {
  favoriteProjects: [],
};
export const userReducer = (state, payload) => ({
  ...state,
  ...payload,
});

export const UserProvider = ({ initialState = initialUserState, children }) => {
  return (
    <UserContext.Provider value={useReducer(userReducer, initialState)}>
      {children}
    </UserContext.Provider>
  );
};
export const useUserState = () => useContext(UserContext);
