import React from 'react';
import PropTypes from 'prop-types';
import { ProgressBar } from '../progress-bar';
import styles from './report-key-features-section.module.css';

export const ReportKeyFeaturesSection = ({ keyFeaturesData }) => (
  <div className={styles.keyFeatures}>
    {keyFeaturesData.items.map(({ title, features, overallProgress }, index) => (
      <div key={index} className={styles.keyFeaturesCard}>
        <h4 className={styles.keyFeaturesTitle}>
          {title}
        </h4>
        <div className={styles.keyFeaturesItems}>
          {features.map(({ key, progress }, valueIndex) => (
            <div key={valueIndex} className={styles.keyFeaturesText}>
              <span>{key}</span>
              <span className={styles.percent}>{progress}</span>
            </div>
          ))}
        </div>
        <div className={styles.keyFeaturesProgress}>
          <ProgressBar now={overallProgress} />
          <span className={styles.percent}>{overallProgress}%</span>
        </div>
      </div>
    ))}
  </div>
);

ReportKeyFeaturesSection.propTypes = {
  keyFeaturesData: PropTypes.shape({
    items: PropTypes.arrayOf(PropTypes.shape({
      title: PropTypes.string,
      overallProgress: PropTypes.number,
      features: PropTypes.arrayOf(PropTypes.shape({
        key: PropTypes.string,
        progress: PropTypes.string,
      })),
    })),
  }).isRequired,
};
