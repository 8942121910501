import React, { useState } from 'react';
import { v1 as uuid } from 'uuid';
import { useMutation } from '@apollo/client';
import { FilePicker } from 'react-file-picker';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { NotificationManager } from 'react-notifications';
import omit from 'lodash.omit';
import { Button } from '../button';
import { Spinner } from '../spinner';
import { ButtonsGroup } from '../buttons-group';
import { IconTextButton } from '../icon-text-button';
import { useUser } from '../../utils/use-user';
import { GENERATE_UPLOAD_URL, ADD_RESULTS_UPLOAD_SECTION } from './queries';
import styles from './edit-report-files-uploader.module.css';

dayjs.extend(localizedFormat);

export const EditReportFilesUploader = ({
  uploadSection,
  onSave,
  onCancelClick,
  reportId,
  onChangesSkip,
}) => {
  const { user } = useUser();

  const [isLoading, setIsLoading] = useState(false);
  const [fileChosen, setFileChosen] = useState(null);
  const [fileDetails, setFileDetails] = useState(uploadSection?.details || []);
  const [generateUploadUrl] = useMutation(GENERATE_UPLOAD_URL);
  const [saveResultsUploadSection] = useMutation(ADD_RESULTS_UPLOAD_SECTION);

  const sectionId = `${reportId}_RESULTS_UPLOAD`;

  const handleFileUpload = async (file) => {
    const fileAlias = file.name;
    const re = /(?:\.([^.]+))?$/;
    const fileType = re.exec(file.name)[1];
    const fileToUpload = new File([file], uuid() + '.' + fileType, {
      type: 'application/' + fileType,
    });

    const { data: uploadUrlData } = await generateUploadUrl({
      variables: {
        type: 'UPLOAD_URL',
        fileName: fileToUpload.name,
        fileType: fileToUpload.type,
      },
    });
    const uploadUrl = uploadUrlData?.generateUploadUrl?.signedRequest;

    await fetch(uploadUrl, {
      method: 'PUT',
      headers: {
        'Content-Type': fileToUpload.type,
      },
      body: fileToUpload,
    });

    const { data: getUrlData } = await generateUploadUrl({
      variables: {
        type: 'GET_URL',
        fileName: fileToUpload.name,
        fileType: fileToUpload.type,
      },
    });

    const newFileId = uuid();
    const now = String((new Date()).getTime());
    return [
      ...fileDetails.map((detailsItem) => ({
        ...omit(detailsItem, '__typename'),
        metadata: omit(detailsItem.metadata, '__typename'),
      })),
      {
        id: newFileId,
        fileName: fileToUpload.name,
        fileAlias,
        filePath: getUrlData?.generateUploadUrl?.signedRequest,
        metadata: {
          id: newFileId,
          lastModified: now,
          createdOn: now,
          createdBy: user.email,
          lastModifiedBy: user.email,
        },
      },
    ];
  };

  const uploadFile = async () => {
    setIsLoading(true);
    const newFileDetails = await handleFileUpload(fileChosen.file);
    setFileDetails(newFileDetails);
    setIsLoading(false);
    setFileChosen(null);
  };

  const handleSaveClick = async () => {
    setIsLoading(true);
    const newUploadsSection = {
      id: sectionId,
      details: fileDetails.map((detailsItem) => ({
        ...omit(detailsItem, '__typename'),
        metadata: omit(detailsItem.metadata, '__typename'),
      })),
    };
    await saveResultsUploadSection({
      variables: {
        reportData: newUploadsSection,
      },
    });
    setIsLoading(false);
    onSave(newUploadsSection, true);
  };

  const removeEntry = (index) => {
    setFileDetails(fileDetails.filter((_, i) => i !== index));
  };

  const chooseFile = (file) => {
    const { name: fileName, size } = file;
    const fileChosenSize = `${parseFloat(size / 1024).toFixed(2)} KB`;
    setFileChosen({
      file,
      fileName,
      fileChosenSize,
    });
  };

  const handleChooseFileError = (errMsg) => {
    NotificationManager.error(errMsg, 'Oops');
  };

  if (isLoading) {
    return <Spinner title="Please wait..." />;
  }

  return (
    <>
      <div className={styles.sectionHeading}>
        <h3 className={styles.sectionTitle}>Upload Reporting Templates Section</h3>
      </div>
      <div className={styles.wrapper}>
        <div className={styles.uploadForm}>
          <div className={styles.files}>
            {fileDetails.map(({ filePath, fileAlias, fileName, metadata }, index) => (
              <div key={index} className={styles.uploadsCard} data-testid="file-card">
                <div>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={filePath}
                    download={fileAlias || fileName}
                  >
                    {fileAlias || fileName}
                  </a>
                  <div className={styles.uploadData}>
                    <p className={styles.uploadItem}>
                      <span className={styles.uploadItemTitle}>Added By:</span>
                      {metadata.createdBy}
                    </p>
                    <p className={styles.uploadItem}>
                      <span className={styles.uploadItemTitle}>On:</span>
                      {dayjs(Number(metadata.createdOn)).format('ll LTS')}
                    </p>
                  </div>
                </div>
                <div>
                  <IconTextButton
                    title="Delete"
                    variant="outline-danger"
                    onClick={() => removeEntry(index)}
                    data-testid="delete-file"
                  >
                    <i className="fa fa-trash" title="delete" />
                  </IconTextButton>
                </div>
              </div>
            ))}
          </div>

          <div className={styles.uploadField}>
            {Boolean(fileChosen) && (
              <div className={styles.uploadFile}>
                <p className={styles.description}>{`File chosen: ${fileChosen.fileName}`}</p>
                <div className={styles.smallDescription}>Size: {fileChosen.fileChosenSize}</div>
                <Button variant="outline-primary" className={styles.action} onClick={uploadFile}>
                  <i className="fa fa-upload" />
                  <span className={styles.actionText}>Upload</span>
                </Button>
              </div>
            )}

            <p className={styles.description}>Choose a file to upload:</p>
            <div className={styles.smallDescription}>
              Supports almost all the document/image types up to 20MB in size
            </div>
            <label className={styles.uploaderLabel}>
              <span className={styles.uploaderLabelText}>file-uploader</span>
              <FilePicker
                extensions={['pdf']}
                maxSize={20}
                onChange={chooseFile}
                onError={handleChooseFileError}
                id="file-uploader"
              >
              <Button variant="primary" className={styles.action}>
                <i className="fa fa-search-plus" />
                <span className={styles.actionText}>Browse</span>
              </Button>
            </FilePicker>
            </label>
          </div>
        </div>
        <ButtonsGroup
          onCancelClick={onCancelClick}
          onSaveClick={handleSaveClick}
          onSkipClick={onChangesSkip}
        />
      </div>
    </>
  );
};
