import React, { useRef, useState, useEffect } from 'react';
import classNames from 'clsx';
import { isContentBiggerThanWrapper } from './helpers';
import { MAX_COLLAPSED_HEIGHT } from './constants';
import styles from './expandable-highlights.module.css';

export const ExpandableHighlights = ({ highlights, isPrintVersion }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isExpandable, setIsExpandable] = useState(false);
  const contentRef = useRef(null);

  useEffect(() => {
    const content = contentRef.current;
    setIsExpanded(false);
    setIsExpandable(isContentBiggerThanWrapper(content));
  }, [highlights]);

  const toggleExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className={styles.highlightWrapper}>
      <div className={styles.expandableWrapper} style={{ maxHeight: (isExpanded || isPrintVersion) ? '100%' : MAX_COLLAPSED_HEIGHT }}>
        <div
          className="ql-editor"
          dangerouslySetInnerHTML={{ __html: highlights }}
          ref={contentRef}
        />
      </div>
      {isExpandable && (
        <div className={classNames(styles.toggleButtonWrapper, {
          [styles.toggleButtonWrapperExpanded]: isExpanded,
        })}>
          <span className={styles.toggleButton} onClick={toggleExpanded}>
            <i className={isExpanded ? 'fa fa-chevron-up' : 'fa fa-chevron-down'} />
            <span className={styles.toggleButtonText}>
              {isExpanded ? 'Show less' : 'Show more'}
            </span>
          </span>
        </div>
      )}
    </div>
  );
};
