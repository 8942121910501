import { useEffect } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { useLazyQuery } from '@apollo/client';
import omit from 'lodash.omit';
import { useUserState } from './user-context';
import { GET_USER_DATA } from './queries';

export const useUser = (useExtendedData) => {
  const { authState, oktaAuth } = useOktaAuth();
  const [userState, updateUser] = useUserState();

  const [loadUserData, { data }] = useLazyQuery(GET_USER_DATA, {
    fetchPolicy: 'cache-and-network',
  });
  const userData = data?.getUserData;

  useEffect(() => {
    if (useExtendedData) {
      loadUserData();
    }
  }, [loadUserData, useExtendedData]);

  useEffect(() => {
    updateUser(omit(userData, '__typename'));
  }, [userData, updateUser]);

  return {
    user: oktaAuth?.token?.decode(authState?.idToken?.idToken)?.payload || {},
    ...userState,
    updateUser,
  };
};
