import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'clsx';
import DayPicker from 'react-day-picker';
import dayjs from 'dayjs';
import { useOnClickOutside } from '../../utils/use-click-outside';
import { convertDateToRetailWeek } from '../../utils/date-to-retail-week';
import styles from './input-datepicker.module.css';

export const InputDatepicker = ({ value, name, setFieldValue, disallowUserInput, formatDisplayValue, formatFieldValue }) => {
  const inputRef = useRef(null);

  const [showCalendar, setShowCalendar] = useState(false);

  useOnClickOutside(inputRef, () => setShowCalendar(false));

  const toggleCalendar = () => {
    setShowCalendar(!showCalendar);
  };

  const handleDayChange = (date) => {
    const selectedDay = formatFieldValue(date);
    setFieldValue(name, selectedDay);
    setShowCalendar(false);
  };

  const inputDateValue = new Date(value);
  const selectedDay = inputDateValue.toString() === 'Invalid Date' ? '' : inputDateValue;

  return (
    <div ref={inputRef} className={styles.wrapper}>
      <input
        type="text"
        value={formatDisplayValue ? formatDisplayValue(value) : value}
        onChange={({ target }) => setFieldValue(name, target.value)}
        onFocus={() => setShowCalendar(false)}
        className={styles.input}
        disabled={disallowUserInput}
      />
      <button
        type="button"
        data-testid="calendar-button"
        onClick={toggleCalendar}
        className={styles.button}
      >
        <i className={classNames('fa fa-calendar', styles.icon)} />
      </button>
      {showCalendar && (
        <DayPicker
          selectedDays={[selectedDay]}
          className={styles.calendar}
          showWeekNumbers
          showOutsideDays
          onDayClick={handleDayChange}
          renderWeek={(weekNumber, week) => (
            <>
              {convertDateToRetailWeek(week[0]).fullRetailWeek}
            </>
          )}
        />
      )}
    </div>
  );
};

InputDatepicker.defaultProps = {
  formatFieldValue: (date) => dayjs(date).format('MM/DD/YY'),
};

InputDatepicker.propTypes = {
  value: PropTypes.string,
  name: PropTypes.string,
  disallowUserInput: PropTypes.bool,
  setFieldValue: PropTypes.func,
  formatDisplayValue: PropTypes.func,
  formatFieldValue: PropTypes.func,
};
