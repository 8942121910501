import React from 'react';
import PropTypes from 'prop-types';
import styles from './validation-error.module.css';

export const ValidationError = ({ children }) => (
  <div className={styles.wrapper}>
    <span className={styles.message}>
      {children}
    </span>
  </div>
);

ValidationError.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
  ]),
};
