import React from 'react';
import { Formik, Form, Field, ErrorMessage, FieldArray } from 'formik';
import { NotificationManager } from 'react-notifications';
import { useMutation } from '@apollo/client';
import omit from 'lodash.omit';
import { Button } from '../button';
import { ButtonsGroup } from '../buttons-group';
import { ValidationError } from '../validation-error';
import { Spinner } from '../spinner';
import { CONSTANTS } from '../../constants';
import { ChangelogPopup } from '../changelog-popup';
import { ADD_RESULTS_SECTION } from './queries';
import { ResultsRow } from './results-row';
import styles from './edit-report-results.module.css';
import { DEFAULT_RESULT_ITEM, DEFAULT_RESULT } from './constants';
import { ResultsImport } from './results-import';
import { ResultsChangelogSlider } from './results-changelog-slider';

export const EditReportResults = ({
  resultsSection,
  reportId,
  deleteCallback,
  onSave,
  onChangesSkip,
  reportDates,
}) => {
  const sectionId = resultsSection?.id || `${reportId}_RESULTS`;

  const [saveResultsSection, { loading }] = useMutation(ADD_RESULTS_SECTION);

  const handleValidation = ({ results }) => {
    const errors = {};
    const requiredFields = ['title'];
    const innerRequiredFields = ['key', 'value1'];

    results.forEach((result, index) => {
      requiredFields.forEach((requiredField) => {
        if (!result[requiredField]) {
          errors[index] = {
            ...errors[index],
            [requiredField]: CONSTANTS.REQUIRED_FIELD,
          };
        }
      });

      result?.items.forEach((resultItem, resultItemIndex) => {
        innerRequiredFields.forEach((innerRequiredField) => {
          const currentErrors = errors[index]?.items || [];
          let valueErrors = [...currentErrors];
          if (!resultItem[innerRequiredField]) {
            valueErrors[resultItemIndex] = {
              ...valueErrors[resultItemIndex],
              [innerRequiredField]: CONSTANTS.REQUIRED_FIELD,
            };
            errors[index] = {
              ...errors[index],
              items: valueErrors,
            };
          }
        });
      });
    });

    if (Object.keys(errors).length) {
      NotificationManager.error('Please enter all Required details', 'Sorry');
      return {
        results: errors,
      };
    }

    return {};
  };

  const handleFormSubmit = async ({ results }) => {
    const updatedResults = [
      ...results.map((resultItem) => ({
        ...omit(resultItem, '__typename'),
        items: [
          ...resultItem.items.map((item) => ({
            ...omit(item, '__typename'),
          })),
        ],
      })),
    ];

    try {
      const newResults =  {
        id: sectionId,
        results: updatedResults,
      };
      await saveResultsSection({
        variables: {
          reportData: newResults,
        },
      });
      onSave(newResults);
    } catch {
      NotificationManager.error('Error while saving results section, please try again', 'Error');
    }
  };

  let initialValues = {
    results: resultsSection?.results || [DEFAULT_RESULT],
  };

  if (loading) {
    return <Spinner title="Please wait.." />;
  }

  return (
    <>
      <ChangelogPopup id="results-new-relic">
        {(handleCloseClick, handleCloseAndForget) => (
          <>
            <ResultsChangelogSlider onCloseClick={handleCloseClick} onCloseAndForgetClick={handleCloseAndForget} />
          </>
        )}
      </ChangelogPopup>
      <Formik
        initialValues={initialValues}
        onSubmit={handleFormSubmit}
        validate={handleValidation}
        enableReinitialize={true}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <FieldArray
              name="results"
              render={(arrayHelpers) => {
                const hasMultipleItems = values?.results.length > 1;
                return (
                  <>
                    <div className={styles.sectionHeading}>
                      <h3 className={styles.sectionTitle}>Results Section</h3>
                      <div className={styles.sectionActions}>
                        <ResultsImport onImportComplete={arrayHelpers.unshift} />
                        <Button
                          variant="outline-primary"
                          onClick={() => arrayHelpers.unshift(DEFAULT_RESULT)}
                        >
                          Add More
                        </Button>
                      </div>
                    </div>
                    <div className={styles.wrapper}>
                      {values?.results.length > 0 && values.results.map((result, resultIndex) => (
                        <div key={resultIndex} className={styles.resultItem} data-testid="result-item">
                          <FieldArray
                            name={`results[${resultIndex}].items`}
                            render={(innerArrayHelpers) => (
                              <>
                                <div className={styles.result}>
                                  <div className={styles.resultTitle}>
                                    <div className={styles.label}>Results Title (Required):</div>
                                    <Field type="text" name={`results[${resultIndex}].title`} placeholder="Results Title" />
                                    <ErrorMessage name={`results[${resultIndex}].title`} component={ValidationError} />
                                  </div>
                                  <div className={styles.resultActions}>
                                    <div className={styles.actionsWrapper}>
                                      <Button
                                        variant="outline-success"
                                        className={styles.actionButton}
                                        onClick={() => innerArrayHelpers.push(DEFAULT_RESULT_ITEM)}
                                        data-testid="add-value"
                                      >
                                        <i className="fa fa-plus" title="Add results section" />
                                      </Button>
                                      {hasMultipleItems && (
                                        <Button
                                          variant="outline-danger"
                                          onClick={() => arrayHelpers.remove(resultIndex)}
                                          data-testid="remove-item"
                                        >
                                          <i className="fa fa-trash" title="Remove results section" />
                                        </Button>
                                      )}
                                    </div>
                                  </div>
                                </div>
                                {result.items.map((value, index) => {
                                  const item = values.results[resultIndex].items[index];
                                  const fieldNamePrefix = `results[${resultIndex}].items[${index}]`;
                                  return (
                                    <div key={index} className={styles.resultRow} data-testid="result-value">
                                      <ResultsRow
                                        item={item}
                                        fieldNamePrefix={fieldNamePrefix}
                                        onNewRelicRequestCompleted={(result) => setFieldValue(`${fieldNamePrefix}.value1`, result)}
                                        onDeleteButtonClick={() => innerArrayHelpers.remove(index)}
                                        reportDates={reportDates}
                                      />
                                    </div>
                                  );
                                })}
                              </>
                            )}
                          />
                        </div>
                      ))}
                      <ButtonsGroup onCancelClick={deleteCallback} onSkipClick={onChangesSkip}/>
                    </div>
                  </>
                );
              }}
            />
          </Form>
        )}
      </Formik>
    </>
  );
};
