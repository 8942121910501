import React from 'react';
import { useMutation } from '@apollo/client';
import { Formik, Form, Field, ErrorMessage, FieldArray } from 'formik';
import { NotificationManager } from 'react-notifications';
import omit from 'lodash.omit';
import { Button } from '../button';
import { ButtonsGroup } from '../buttons-group';
import { ValidationError } from '../validation-error';
import { CONSTANTS } from '../../constants';
import { Spinner } from '../spinner';
import { TextareaField } from '../textarea-field';
import { ADD_PERFORMANCE_SECTION } from './queries';
import styles from './edit-report-performance.module.css';

const EMPTY_PERFORMANCE_ITEM = {
  title: '',
  values: [''],
};

export const EditReportPerformance = ({ performanceSection, reportId, deleteCallback, onSave, onChangesSkip }) => {
  const sectionId = performanceSection?.id || `${reportId}_PERFORMANCE`;

  const [savePerformanceSection, { loading }] = useMutation(ADD_PERFORMANCE_SECTION);

  const handleValidation = ({ performance }) => {
    const errors = {};
    const requiredFields = ['title'];

    performance.forEach((risk, index) => {
      requiredFields.forEach((requiredField) => {
        if (!risk[requiredField]) {
          errors[index] = {
            ...errors[index],
            [requiredField]: CONSTANTS.REQUIRED_FIELD,
          };
        }
      });

      risk.values.forEach((value, valueIndex) => {
        const currentErrors = errors[index]?.values || [];
        let valueErrors = [...currentErrors];
        if (!value) {
          valueErrors[valueIndex] = CONSTANTS.REQUIRED_FIELD;
          errors[index] = {
            ...errors[index],
            values: valueErrors,
          };
        }
      });
    });

    if (Object.keys(errors).length) {
      NotificationManager.error('Please enter all Required details', 'Sorry');
      return {
        performance: errors,
      };
    }

    return {};
  };

  const handleFormSubmit = ({ performance }) => {
    const updatedPerformance = performance.map((performanceItem) => omit(performanceItem, '__typename'));

    const newPerformance =  {
      id: sectionId,
      items: updatedPerformance,
    };

    savePerformanceSection({
      variables: {
        reportData: newPerformance,
      },
    });
    onSave(newPerformance);
  };

  let initialValues = {
    performance: performanceSection?.items || [EMPTY_PERFORMANCE_ITEM],
  };

  if (loading) {
    return <Spinner title="Please wait.." />;
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleFormSubmit}
      validate={handleValidation}
      enableReinitialize={true}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {({ values }) => (
        <Form>
          <FieldArray
            name="performance"
            render={(arrayHelpers) => {
              const hasMultipleItems = values?.performance.length > 1;
              return (
                <>
                  <div className={styles.sectionHeading}>
                    <h3 className={styles.sectionTitle}>Performance Section</h3>
                    <div className={styles.sectionActions}>
                      <Button
                        variant="outline-primary"
                        onClick={() => arrayHelpers.unshift(EMPTY_PERFORMANCE_ITEM)}
                      >
                        Add More
                      </Button>
                    </div>
                  </div>
                  <div className={styles.wrapper}>
                    {values?.performance.length > 0 && values.performance.map(({ title, values }, performanceIndex) => (
                      <div key={performanceIndex} className={styles.performanceItem} data-testid="performance-item">
                        <FieldArray
                          name={`performance[${performanceIndex}].values`}
                          render={(innerArrayHelpers) => (
                            <>
                              <div className={styles.performance}>
                                <div className={styles.title}>
                                  <div className={styles.label}>Performance Title (Required):</div>
                                  <Field type="text" name={`performance[${performanceIndex}].title`} placeholder="Performance Title" />
                                  <ErrorMessage name={`performance[${performanceIndex}].title`} component={ValidationError} />
                                </div>
                                <div className={styles.titleActions}>
                                  <div className={styles.actionsWrapper}>
                                    <Button
                                      variant="outline-success"
                                      className={styles.actionButton}
                                      onClick={() => innerArrayHelpers.push('')}
                                      data-testid="add-value"
                                    >
                                      <i className="fa fa-plus" title="Add performance section" />
                                    </Button>
                                    {hasMultipleItems && (
                                      <Button
                                        variant="outline-danger"
                                        className={styles.actionButton}
                                        onClick={() => arrayHelpers.remove(performanceIndex)}
                                        data-testid="remove-item"
                                      >
                                        <i className="fa fa-trash" title="Remove performance section" />
                                      </Button>
                                    )}
                                  </div>
                                </div>
                              </div>

                              {values.map((value, index) => (
                                <div key={index} className={styles.value} data-testid="performance-value">
                                  <div className={styles.valueText}>
                                    <div className={styles.label}>Value (Required):</div>
                                    <TextareaField name={`performance[${performanceIndex}].values[${index}]`} minRows={3} />
                                    <ErrorMessage name={`performance[${performanceIndex}].values[${index}]`} component={ValidationError} />
                                  </div>
                                  <div className={styles.valueActions}>
                                    <div className={styles.actionsWrapper}>
                                      <Button
                                        variant="outline-danger"
                                        onClick={() => innerArrayHelpers.remove(index)}
                                        data-testid="remove-value"
                                      >
                                        <i className="fa fa-trash" title="Remove performance" />
                                      </Button>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </>
                          )}
                        />
                      </div>
                    ))}
                    <ButtonsGroup onCancelClick={deleteCallback} onSkipClick={onChangesSkip} />
                  </div>
                </>
              );
            }}
          />
        </Form>
      )}
    </Formik>
  );
};
