import React, { useState, useEffect } from 'react';
import { NotificationManager } from 'react-notifications';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useUser } from '../../utils/use-user';
import { CONSTANTS } from '../../constants';
import { IconTextButton } from '../../components/icon-text-button';
import { Spinner } from '../../components/spinner';
import { Header } from '../../components/header';
import { Button } from '../../components/button';
import {
  GET_ATTRIBUTES,
  ADD_ATTRIBUTE,
  ADD_ATTRIBUTE_VALUE,
  DELETE_ATTRIBUTE_VALUE,
} from './queries';
import styles from './admin-page.module.css';

export const AdminPage = ({ history }) => {
  const { user } = useUser();
  const [allGroups, setAllGroups] = useState([]);
  const [isAddAttr, setIsAddAttr] = useState(false);
  const [isAddValue, setIsAddValue] = useState({ groupId: null, isOpen: false });
  const [attrName, setAttrName] = useState('');
  const [valueName, setValueName] = useState('');

  const [loadAttributes, { data, loading }] = useLazyQuery(GET_ATTRIBUTES, {
    fetchPolicy: 'cache-and-network',
  });

  const [addAttribute] = useMutation(ADD_ATTRIBUTE, {
    onCompleted: () => {
      NotificationManager.success('New attribute is added', 'Success');
      loadAttributes();
    },
  });
  const [addAttributeValue] = useMutation(ADD_ATTRIBUTE_VALUE, {
    onCompleted: () => {
      NotificationManager.success('New attribute value is added', 'Success');
      loadAttributes();
    },
  });
  const [deleteAttributeValue] = useMutation(DELETE_ATTRIBUTE_VALUE, {
    onCompleted: () => {
      NotificationManager.success('Attribute value was deleted', 'Success');
      loadAttributes();
    },
  });

  useEffect(() => {
    if (
      !user.groups ||
      !user.groups.includes(CONSTANTS.USER_GROUP) ||
      !user.groups.includes(CONSTANTS.ADMIN_GROUP)) {
      history.push('/error-page');
    } else {
      loadAttributes();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, loadAttributes]);

  useEffect(() => {
    if (data && data.getAttributes) {
      const groups = Array.from(data.getAttributes.attributes);
      setAllGroups(groups);
    }
  }, [data]);

  const handleCancelSaveAttr = () => {
    setIsAddAttr(false);
    setAttrName('');
  };

  const handleSaveAttr = () => {
    if (attrName.trim()) {
      addAttribute({ variables: { name: attrName } });
    }
    handleCancelSaveAttr();
  };

  const handleCancelSaveValue = () => {
    setIsAddValue({ groupId: null, isOpen: false });
    setValueName('');
  };

  const handleSaveValue = (attrId) => {
    if (valueName.trim()) {
      addAttributeValue({ variables: { name: valueName, attributeCode: attrId } });
    }
    handleCancelSaveValue();
  };

  const handleDeleteValue = (deleteAttrId, deleteValId) => {
    deleteAttributeValue( { variables: { attributeCode: deleteAttrId, id: deleteValId } } );
  };

  if (loading) {
    return <Spinner title="Please wait..." />;
  }

  return(
    <div className={styles.groups}>
      <Header title="Admin Mode" />
      <div className={styles.wrapper}>
        {allGroups.map(({ id, name, values }) => (
          <div key={id} data-testid="attribute">
            <div className={styles.groupBlock}>
              <h2 className={styles.groupTitle}>
                {name}
              </h2>
              <div className={styles.groupValues}>
                {values.map(({ id: valId, name }) => (
                  <div key={valId} className={styles.valueWrapper} data-testid="value">
                    {name}
                    <IconTextButton
                      title="Delete"
                      variant="outline-danger"
                      onClick={() => handleDeleteValue(id, valId)}
                      data-testid="delete-value"
                    >
                      <i className="fa fa-trash" title="Delete" />
                    </IconTextButton>
                  </div>
                ))}
                {isAddValue.isOpen && isAddValue.groupId === id && (
                  <div className={styles.addValueWrapper}>
                    <div className={styles.addingInput}>
                      <input
                        type="text"
                        placeholder="Value Name"
                        value={valueName}
                        onChange={({ target }) => setValueName(target.value)}
                        autoFocus
                      />
                    </div>
                    <div className={styles.addingActions}>
                      <IconTextButton
                        title="Add"
                        variant="outline-success"
                        onClick={() => handleSaveValue(id)}
                        data-testid="save-value"
                      >
                        <i className="fa fa-plus" title="Add" />
                      </IconTextButton>
                      <IconTextButton
                        title="Cancel"
                        variant="outline-danger"
                        onClick={handleCancelSaveValue}
                        className={styles.addingValueCancel}
                        data-testid="cancel-save-value"
                      >
                        <i className="fa fa-ban" title="Cancel" />
                      </IconTextButton>
                    </div>
                  </div>
                )}
              </div>
              <div className={styles.actions}>
                <Button
                  variant="outline-success"
                  onClick={() => setIsAddValue({ groupId: id, isOpen: true })}
                >
                  <i className="fa fa-plus" title="Create new" />
                  <span className={styles.createButtonText}>Add new value</span>
                </Button>
              </div>
            </div>
          </div>
        ))}
        {isAddAttr && (
          <div className={styles.groupBlock}>
              <h2 className={styles.addingTitle}>
                Add Attribute Name
              </h2>
              <div className={styles.addingInput}>
                <input
                  type="text"
                  placeholder="Attribute Name"
                  value={attrName}
                  onChange={({ target }) => setAttrName(target.value)}
                  autoFocus
                />
              </div>
              <div className={styles.addingActions}>
                <Button
                  variant="outline-danger"
                  className={styles.addingCancel}
                  onClick={handleCancelSaveAttr}
                  data-testid="cancel-save-attr"
                >
                  Cancel
                </Button>
                <Button
                  variant="outline-success"
                  className={styles.addingSuccess}
                  onClick={handleSaveAttr}
                  data-testid="save-attr"
                >
                  Add
                </Button>
              </div>
          </div>
        )}
        <div className={styles.actions}>
          <Button
            variant="outline-success"
            onClick={() => setIsAddAttr(true)}
          >
            <i className="fa fa-plus" title="Created By Me" />
            <span className={styles.createButtonText}>Add New Attribute</span>
          </Button>
        </div>
      </div>
    </div>
  );
};
