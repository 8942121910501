import React, { useState, useMemo } from 'react';
import { ErrorMessage, Field } from 'formik';
import { ValidationError } from '../../validation-error';
import { Button } from '../../button';
import { Modal } from '../../modal';
import { TextareaField } from '../../textarea-field';
import { NewRelicQueryButton } from '../new-relic-query-button';
import { RESULT_OPTIONS } from '../constants';
import styles from '../edit-report-results.module.css';
import { replaceQueryTemplateVariables, parseReportDates } from './helpers';

const colorMap = {
  red: '#ff0000',
  yellow: '#ff8e00',
  green: '#008000',
  black: '#000',
};

export const ResultsRow = ({
  fieldNamePrefix,
  onDeleteButtonClick,
  item,
  onNewRelicRequestCompleted,
  reportDates,
}) => {
  const [isQueryInputVisible, setIsQueryInputVisible] = useState(false);
  const [isQueryModalVisible, setIsQueryModalVisible] = useState(false);
  const [queryResult, setQueryResult] = useState(null);

  const value2Style = { color: colorMap[item.value2Color.toLowerCase()] };
  const value3Style = { color: colorMap[item.value3Color.toLowerCase()] };

  const handleNewRelicQueryButtonClick = (result) => {
    onNewRelicRequestCompleted(result);
    setQueryResult(result);
    setIsQueryInputVisible(false);
  };

  const parsedReportDates = useMemo(() => parseReportDates(reportDates), [reportDates]);
  const query = useMemo(() => replaceQueryTemplateVariables(item.value1Query, parsedReportDates), [item.value1Query, parsedReportDates]);

  const handleCloseModalClick = () => {
    setIsQueryModalVisible(false);
  };

  return (
    <>
      {isQueryModalVisible && (
        <Modal closeOnEsc closeOnOutsideClick onClose={handleCloseModalClick} data-testid="query-hints-modal">
          <p>Insert New Relic query and press <span className="fa fa-play"/> button. You can use following query template variables:</p>
          <ul>
            <li>%START_DATE% – to use Report week start date{parsedReportDates?.start && ` (${parsedReportDates.start})`}</li>
            <li>%END_DATE% – to use Report week end date{parsedReportDates?.end && ` (${parsedReportDates.end})`}</li>
          </ul>
        </Modal>
      )}
      <div className={styles.keyWrapper}>
        <div className={styles.label}>Key:</div>
        <Field type="text" name={`${fieldNamePrefix}.key`} placeholder="Key" />
        <ErrorMessage name={`${fieldNamePrefix}.key`} component={ValidationError} />
      </div>
      {isQueryInputVisible ? (
        <>
          <div className={styles.queryWrapper}>
            <div className={styles.label}>This week (New Relic query <button
                type="button"
                className={styles.queryModalButton}
                onClick={() => { setIsQueryModalVisible(true); }}
                data-testid="query-hints"
                title="Show query hints"
              >
                <span className="fa fa-question-circle"/>
              </button>
              ):</div>
            <div className={styles.itemWrapper}>
              <TextareaField name={`${fieldNamePrefix}.value1Query`} testId="query-input" minRows={1.54} />
            </div>
          </div>
          <div className={styles.queryActionsWrapper}>
            <div className={styles.actionsWrapper}>
              <NewRelicQueryButton
                onNewRelicQueryComplete={handleNewRelicQueryButtonClick}
                query={query}
              />
              <Button
                className={styles.actionButton}
                variant="outline-info"
                title="Cancel"
                onClick={() => { setIsQueryInputVisible(false); }}
                data-testid="hide-query"
              >
                <span className="fa fa-check" />
              </Button>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className={styles.weekWrapper}>
            <div className={styles.label}>This week:</div>
            <div className={styles.itemWrapper}>
              <Field type="text" name={`${fieldNamePrefix}.value1`} placeholder="This Week" autoComplete="off" />
              { queryResult === item.value1 && (
                <div className={styles.queryWarning} title="This value is imported from New Relic">
                  <span className="fa fa-cloud-download"/>
                </div>
              )}
              <Button
                variant={item.value1Query?.length ? 'info' : 'outline-info'}
                title="Use New Relic query"
                onClick={() => { setIsQueryInputVisible(true); }}
                data-testid="add-query"
              >
                <span className="fa fa-pie-chart" />
              </Button>
              <NewRelicQueryButton
                onNewRelicQueryComplete={handleNewRelicQueryButtonClick}
                query={query}
              />
            </div>
            <ErrorMessage name={`${fieldNamePrefix}.value1`} component={ValidationError} />
          </div>
          <div className={styles.targetWrapper}>
            <div className={styles.label}>vs Target:</div>
            <div className={styles.itemWrapper}>
              <Field type="text" name={`${fieldNamePrefix}.value2`} placeholder="vs Target" style={value2Style} />
              <Field as="select" name={`${fieldNamePrefix}.value2Color`} style={value2Style}>
                {RESULT_OPTIONS.map(({ value, title }) => (
                  <option key={value} value={value}>{title}</option>
                ))}
              </Field>
            </div>
            <ErrorMessage name={`${fieldNamePrefix}.value2`} component={ValidationError} />
          </div>
          <div className={styles.lastweekWrapper}>
            <div className={styles.label}>vs Last Week:</div>
            <div className={styles.itemWrapper}>
              <Field type="text" name={`${fieldNamePrefix}.value3`} placeholder="vs Last Week" style={value3Style} />
              <Field as="select" name={`${fieldNamePrefix}.value3Color`} style={value3Style}>
                {RESULT_OPTIONS.map(({ value, title }) => (
                  <option key={value} value={value}>{title}</option>
                ))}
              </Field>
            </div>
            <ErrorMessage name={`${fieldNamePrefix}.value3`} component={ValidationError} />
          </div>
          <div className={styles.rowActions}>
            <div className={styles.actionsWrapper}>
              <Button
                variant="outline-danger"
                onClick={onDeleteButtonClick}
                data-testid="remove-value"
              >
                <i className="fa fa-trash" title="Remove result" />
              </Button>
            </div>
          </div>
        </>
      )}
    </>
  );
};
