import gql from 'graphql-tag';
import { REPORT_FRAGMENT } from '../../utils/fragments/report';

export const GET_REPORT_BY_ID = gql`
  query($id: String!) {
    getReportById(id: $id) {
      ...ReportFields
    }
  }
  ${REPORT_FRAGMENT}
`;
