import React, { useEffect } from 'react';
import { useMutation, useLazyQuery, useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { NotificationManager } from 'react-notifications';
import { Button } from '../../components/button';
import { Spinner } from '../../components/spinner';
import { ValidationError } from '../../components/validation-error';
import { useImperativeQuery } from '../../utils/use-imperative-query';
import { buildAttributesArray } from '../../utils/build-attributes-array';
import { Header } from '../../components/header';
import { CONSTANTS } from '../../constants';
import {
  GET_PROJECT,
  GET_ATTRIBUTES,
  ADD_PROJECT,
  GET_REPORTS_FOR_PROJECT,
  DELETE_PROJECT,
  DELETE_SECTION,
  DELETE_REPORT,
} from './queries';
import styles from './add-project-page.module.css';
import { buildInitialValues } from './helpers';

export const AddProjectPage = ({ match, history }) => {
  const { projectId } = match.params;

  const { data: attributesData, loading: attributesLoading } = useQuery(GET_ATTRIBUTES, {
    fetchPolicy: 'cache-and-network',
  });
  const [loadProject, { data, loading }] = useLazyQuery(GET_PROJECT, { fetchPolicy: 'cache-and-network' });
  const project = data?.getProject;

  const [saveProject] = useMutation(ADD_PROJECT, {
    onCompleted: (data) => {
      NotificationManager.success('Successfully added the project', 'Nice!!');
      history.push(`/editor/projects/${data.addProject.id}`);
    },
  });
  const [deleteProject, { loading: deleteProjectLoading }] = useMutation(DELETE_PROJECT, {
    onCompleted: () => {
      NotificationManager.success('Removed the project and all the related reports', 'Successfully');
      history.push('/editor/projects');
    },
  });
  const [deleteSection, { loading: deleteSectionLoading }] = useMutation(DELETE_SECTION);
  const [deleteReport, { loading: deleteReportLoading }] = useMutation(DELETE_REPORT);
  const [loadReports, { loading: reportsLoading }] = useImperativeQuery(GET_REPORTS_FOR_PROJECT, {
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (projectId !== 'new') {
      loadProject({ variables: { id: projectId } });
    }
  }, [loadProject, projectId]);

  const handleSubmit = (values) => {
    const { name, product, program, attributes } = values;
    const attributeValues = buildAttributesArray(attributes);

    const projectToSave = {
      id: project?.id,
      name,
      program,
      product,
      attributeValues,
    };

    saveProject({
      variables: projectToSave,
    });
  };

  const handleSaveCancel = () => {
    history.push('/editor/projects');
  };

  const handleDelete = async () => {
    if (window.confirm('Are you sure to remove this project and all the related reports permanently?')) {
      const { data: reports } = await loadReports({ variables: { id: projectId } });

      await Promise.all(
        reports.getReportsForProject.items.map(async (report) => {
          const reportId = report.id;
          await deleteSection({ variables: { id: `${reportId}_PERFORMANCE` } });
          await deleteSection({ variables: { id: `${reportId}_KEYFEATURES` } });
          await deleteSection({ variables: { id: `${reportId}_KEYVALUES` } });
          await deleteSection({ variables: { id: `${reportId}_MAINRISKS` } });
          await deleteSection({ variables: { id: `${reportId}_RESULTS` } });
          await deleteSection({ variables: { id: `${reportId}_RESULTS_UPLOAD` } });
          await deleteReport({ variables: { id: reportId } });
        })
      );

      await deleteProject({ variables: { id: projectId } });
    }
  };

  const handleValidation = (values) => {
    const requiredFields = ['name', 'program', 'product'];
    const errors = requiredFields.reduce((acc, field) => {
      if (!values[field]) {
        return {
          ...acc,
          [field]: CONSTANTS.REQUIRED_FIELD,
        };
      }
      return acc;
    }, {});

    if (Object.keys(errors).length) {
      NotificationManager.error('Please enter all Required details', 'Sorry');
    }
    return errors;
  };

  const attributes = attributesData?.getAttributes?.attributes || [];

  const initialValues = buildInitialValues(project, attributes);

  if (deleteProjectLoading || deleteReportLoading || deleteSectionLoading || reportsLoading) {
    return <Spinner title="Deleting the project..." />;
  }

  if (loading || attributesLoading) {
    return <Spinner title="Please wait..." />;
  }

  return (
    <div className={styles.addProject}>
      <Header title={project ? 'Edit project' : 'Create project'} />

      <div className={styles.pageContainer}>
        <Formik
          initialValues={initialValues}
          validate={handleValidation}
          onSubmit={handleSubmit}
          enableReinitialize={true}
          validateOnChange={false}
          validateOnBlur={false}
        >
          <Form className={styles.formWrapper}>
            <div className={styles.titleContainer}>
              <div className={styles.label}>Project Name (Required):</div>
              <Field name="name" type="text" placeholder="Enter Project Name" />
              <ErrorMessage name="name" component={ValidationError} />
            </div>

            <div className={styles.fieldWrapper}>
              <div className={styles.label}>Product (Required):</div>
              <Field name="product" type="text" placeholder="Enter Product" />
              <ErrorMessage name="product" component={ValidationError} />
            </div>

            <div className={styles.fieldWrapper}>
              <div className={styles.label}>Program (Required):</div>
              <Field name="program" type="text" placeholder="Enter Program" />
              <ErrorMessage name="program" component={ValidationError} />
            </div>

            {attributes.map(({ id, name, values }) => (
              <div className={styles.fieldWrapper}>
                <div className={styles.label}>{name} (Optional):</div>
                <Field as="select" name={`attributes[${id}]`}>
                  <option value="">Select value</option>
                  {values.map(({ id, name }) => (
                    <option key={id} value={id}>{name}</option>
                  ))}
                </Field>
              </div>
            ))}

            <div className={styles.actionsWrapper}>
              {project && (
                <Button
                  className={styles.actionButton}
                  variant="danger"
                  disabled={loading}
                  onClick={handleDelete}
                >
                  <i className="fa fa-trash" title="Delete" />
                  <span className={styles.actionText}>Delete</span>
                </Button>
              )}
              <Button
                className={styles.actionButton}
                variant="info"
                onClick={handleSaveCancel}
              >
                <i className="fa fa-ban" title="Cancel" />
                <span className={styles.actionText}>Cancel</span>
              </Button>
              <Button type="submit" variant="success">
                <i className="fa fa-save" title="Save" />
                <span className={styles.actionText}>Save</span>
              </Button>
            </div>
          </Form>
        </Formik>
      </div>
    </div>
  );
};

AddProjectPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      projectId: PropTypes.string,
    }),
  }),
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
};
