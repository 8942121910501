const BACKLOG_TITLE = 'Backlog';

export const sortByDate = (a, b) => a.date - b.date;

export const sortKeyValues = (a, b) => {
  if (a.title === BACKLOG_TITLE || a.old) {
    return 1;
  }

  if (b.title === BACKLOG_TITLE || b.old) {
    return -1;
  }

  return a.date - b.date;
};

export const getLocalizedKeyValueMonth = (date) => {
  if (!date || isNaN(Number(date))) {
    return null;
  }

  return (new Date(Number(date))).toLocaleString('default', { month: 'long', year: 'numeric' });
};

export const groupKeyValues = (keyValuesData) => {
  const items = JSON.parse(JSON.stringify(keyValuesData.items));
  return items.reduce((acc, keyValue) => {
    if (keyValue.valueDrops?.length > 0) {
      return [
        ...acc,
        {
          ...keyValue,
          old: true,
        },
      ];
    }

    const keyValueMonth = getLocalizedKeyValueMonth(keyValue.date) || BACKLOG_TITLE;

    const groupedByDate = acc.findIndex(({ title }) => title === keyValueMonth);
    if (groupedByDate !== -1) {
      acc[groupedByDate].valueDrops = [
        ...acc[groupedByDate].valueDrops,
        keyValue,
      ];
      return acc;
    }

    return [
      ...acc,
      {
        title: keyValueMonth,
        date: keyValue.date,
        valueDrops: [
          keyValue,
        ],
      },
    ];
  }, []);
};
