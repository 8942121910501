import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classNames from 'clsx';
import styles from './button.module.css';

export const Button = ({
  variant,
  className,
  type = 'button',
  href,
  ...rest
}) => {
  const classes = classNames({
    [styles.button]: true,
    [styles.variantInfo]: variant === 'info',
    [styles.variantDanger]: variant === 'danger',
    [styles.variantPrimary]: variant === 'primary',
    [styles.variantSuccess]: variant === 'success',
    [styles.variantSecondary]: variant === 'secondary',
    [styles.variantOutlineInfo]: variant === 'outline-info',
    [styles.variantOutlineDanger]: variant === 'outline-danger',
    [styles.variantOutlinePrimary]: variant === 'outline-primary',
    [styles.variantOutlineSuccess]: variant === 'outline-success',
    [styles.variantOutlineSecondary]: variant === 'outline-secondary',
  }, className);

  if (href) {
    return <Link to={href} className={classes} {...rest} />;
  }

  return (
    <button className={classes} type={type} {...rest} />
  );
};

Button.propTypes = {
  variant: PropTypes.string,
  className: PropTypes.string,
  type: PropTypes.string,
};
