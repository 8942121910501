import green_circle from '../images/green_circle.svg';
import green_check from '../images/green_check.svg';
import amber_circle from '../images/amber_circle.svg';
import blue_star from '../images/blue_star.svg';
import red_circle from '../images/red_circle.svg';
import red_star from '../images/red_star.svg';
import on_hold from '../images/on_hold.svg';
import unknown from '../images/unknown.svg';

export const STATUSES = [
  { title: 'On track', icon: green_circle, value: 'green_circle' },
  { title: 'Completed', icon: green_check, value: 'green_check' },
  { title: 'Risk exists', icon: amber_circle, value: 'amber_circle' },
  { title: 'Key Decision', icon: blue_star, value: 'blue_star' },
  { title: 'Risk realised, help needed', icon: red_circle, value: 'red_circle' },
  { title: 'Key Dependency', icon: red_star, value: 'red_star' },
  { title: 'Not started / On hold', icon: on_hold, value: 'on_hold' },
];

export const UNKNOWN_STATUS = {
  title: 'Unknown',
  icon: unknown,
  value: 'unknown',
};
