import React from 'react';
import { NotificationManager } from 'react-notifications';
import classNames from 'clsx';
import { useLazyQuery } from '@apollo/client';
import { Button } from '../../button';
import styles from '../edit-report-results.module.css';
import { GET_NEW_RELIC_QUERY_RESULT } from './queries';

export const NewRelicQueryButton = ({ onNewRelicQueryComplete, query = '' }) => {
  const [runNewRelicQuery, { loading }] = useLazyQuery(GET_NEW_RELIC_QUERY_RESULT, {
    fetchPolicy: 'network-only',
    onError: (error) => {
      NotificationManager.error(error.message, 'Error while querying New Relic data');
    },
    onCompleted: (data) => {
      onNewRelicQueryComplete(data?.getNewRelicQueryResult?.result);
    },
  });

  const handleButtonClick = () => {
    runNewRelicQuery({
      variables: {
        query,
      },
    });
  };

  return (
    <Button
      variant="outline-success"
      title="Run New Relic query"
      className={styles.actionButton}
      onClick={handleButtonClick}
      disabled={loading || !query}
      data-testid="run-query"
    >
      <span className={classNames('fa', {
        'fa-play': !loading,
        'fa-spinner fa-pulse': loading,
      })} />
    </Button>
  );
};
