import React from 'react';
import classNames from 'clsx';
import { Link } from 'react-router-dom';
import { Logo } from '../logo';
import { PageTitle } from '../page-title';
import styles from './header.module.css';

export const Header = ({ title, renderLeftColumn, renderRightColumn, renderBottom, backButtonHref }) => {
  return (
    <div className={styles.headerWrapper}>
      <header className={styles.header} data-testid="header">
        <div className={styles.headerLine}>
          <div className={styles.logo}>
            <Logo />
            {backButtonHref && (
              <nav className={styles.backButton}>
                <Link className={styles.iconBack} to={backButtonHref}>
                  <i className="fa fa-arrow-circle-left icon" title="Back" />
                </Link>
              </nav>
            )}
          </div>
          <div className={styles.left}>
          {renderLeftColumn && renderLeftColumn()}
          </div>
          <div className={classNames(styles.title)}>
            <PageTitle title={title} />
          </div>
          <div className={styles.right}>
          {renderRightColumn && renderRightColumn()}
          </div>
        </div>
        {renderBottom && (
          <div className={styles.headerLine}>
            {renderBottom()}
          </div>
        )}
      </header>
    </div>
  );
};
