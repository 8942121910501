import React from 'react';
import PropTypes from 'prop-types';
import styles from './spinner.module.css';

export const Spinner = ({ title }) => (
  <div className={styles.center}>
    <div className={styles.loader}>
      <i className="fa fa-cog fa-spin" />
      <p className={styles.text}>{title}</p>
    </div>
  </div>
);

Spinner.propTypes = {
  title: PropTypes.string,
};
