import dayjs from 'dayjs';

export const EMPTY_VALUE = '-';

export const getAcceleratorValue = (attributes) => {
  if (!attributes?.length) {
    return EMPTY_VALUE;
  }
  const currentAttribute = attributes.find(({ attribute }) => attribute.id === 'accelerator');

  return currentAttribute
  ? currentAttribute.attribute.values.find(({ id }) => currentAttribute.id === id)?.name
  : EMPTY_VALUE;
};

export const buildExportData = (reports, exportReportIds, user) => {
  const { name } = user;
  const currentDate = dayjs().format('MM/DD/YY');
  const exportReports = reports.filter(({ id }) => exportReportIds.includes(id));
  const exportData = [['Submitted by', 'Date of Submission', 'Accelerator', 'Capability', 'Highlight']];

  exportReports.forEach(({ attributes, weekHighlight, project }) => {
    const accelerator = getAcceleratorValue(attributes);
    const projectName = project?.name || EMPTY_VALUE;
    const reportWeekHighlight = weekHighlight || EMPTY_VALUE;
    exportData.push([name, currentDate, accelerator, projectName, reportWeekHighlight]);
  });

  return exportData;
};
