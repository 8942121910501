export const FORMATS = [
  'background',
  'bold',
  'color',
  'font',
  'code',
  'italic',
  'link',
  'size',
  'strike',
  'script',
  'underline',
  'blockquote',
  'header',
  'indent',
  'list',
  'align',
  'direction',
  'code-block',
  'formula',
];

const bindings = {
  tab: {
    key: 9,
    handler() {
      this.quill.format('indent', '+1');
    },
  },

  indention: {
    key: 'Tab',
    shiftKey: true,
    handler() {
      this.quill.format('indent', '-1');
    },
  },
};

export const CONFIG = {
  keyboard: {
    bindings,
  },
  toolbar: [
    [{ indent: '+1' }, { indent: '-1' }],
    ['bold', 'italic', 'underline'],
    [{ list: 'bullet' }, { list: 'ordered' }],
    [{ script: 'sub' }, { script: 'super' }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
  ],
};
