import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'clsx';
import { Button } from '../button';
import { moveProject } from './helpers';
import styles from './reorder-projects.module.css';

export const ReorderProjects = ({ onSaveClick, projects }) => {
  const [unorderedProjects, setUnorderedProjects] = useState(projects);
  const [orderedProjects, setOrderedProjects] = useState([]);

  useEffect(() => {
    if (projects.length > 0) {
      setUnorderedProjects(projects);
    }
  }, [projects]);

  const putToOrderedList = (id) => {
    const {
      source: newUnorderedProjects,
      target: newOrderedProjects,
    } = moveProject(unorderedProjects, orderedProjects, id);
    setOrderedProjects(newOrderedProjects);
    setUnorderedProjects(newUnorderedProjects);
  };

  const putToUnOrderedList = (id) => {
    const {
      source: newOrderedProjects,
      target: newUnorderedProjects,
    } = moveProject(orderedProjects, unorderedProjects, id);
    setOrderedProjects(newOrderedProjects);
    setUnorderedProjects(newUnorderedProjects);
  };

  const handleSelectAllClick = () => {
    setOrderedProjects([...orderedProjects, ...unorderedProjects]);
    setUnorderedProjects([]);
  };

  const handleSaveClick = () => {
    onSaveClick(orderedProjects);
    setOrderedProjects([]);
  };

  const handleCancelClick = () => {
    setUnorderedProjects(projects);
    setOrderedProjects([]);
  };

  const isSaveDisabled = !Boolean(orderedProjects.length);
  const isNoProjects = orderedProjects.length === 0 && unorderedProjects.length === 0;

  return (

    <div className={styles.section}>
      <div className={styles.projects}>
        <div className={styles.column} data-testid="unordered-projects">
          <h3 className={styles.sectionHeading}>Click to add to the Sequence:</h3>
          <ol>
            {unorderedProjects.map(({ id, name }) => (
              <li key={id} data-testid="unordered-project" className={styles.listItem}>
                <button
                  className={styles.reorderButton}
                  onClick={() => putToOrderedList(id)}
                  data-testid="unordered-button"
                >
                  {name || 'Unnamed project'}
                </button>
              </li>
            ))}
          </ol>
        </div>
        <div className={styles.column} data-testid="ordered-projects">
          <h3 className={styles.sectionHeading}>Click to remove from the Sequence:</h3>
          <ol>
            {orderedProjects.map(({ id, name }) => (
              <li key={id} data-testid="ordered-project" className={styles.listItem}>
                <button
                  className={classNames(styles.reorderButton, styles.orderedButton)}
                  onClick={() => putToUnOrderedList(id)}
                >
                  {name || 'Unnamed project'}
                </button>
              </li>
            ))}
          </ol>
        </div>
        {isNoProjects && (
          <h2 className={styles.emptyState}>There are no projects to sort.</h2>
        )}
        <div className={styles.actions}>
          {unorderedProjects.length > 0 && (
            <Button disabled={isNoProjects} variant="primary" className={styles.actionButton} onClick={handleSelectAllClick}>
              <i className="fa fa-check-square-o" />
              <span className={styles.actionText}>Select all</span>
            </Button>
          )}
          <Button variant="danger" className={styles.actionButton} onClick={handleCancelClick}>
            <i className="fa fa-ban" />
            <span className={styles.actionText}>Cancel</span>
          </Button>
          <Button className={styles.actionButton} disabled={isSaveDisabled || isNoProjects} variant="success" onClick={handleSaveClick}>
            <i className="fa fa-save" />
            <span className={styles.actionText}>Save</span>
          </Button>
        </div>
      </div>
    </div>
  );
};

ReorderProjects.propTypes = {
  onSaveClick: PropTypes.func,
  projects: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  })),
};

ReorderProjects.defaultProps = {
  projects: [],
};
