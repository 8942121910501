import React, { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { Spinner } from '../../components/spinner';
import { ReportsView } from '../../components/report-view';
import { ErrorPage } from '../error-page';
import { GET_REPORT_BY_ID } from './queries';

export const ReportPage = ({ history, match }) => {
  const { reportId } = match.params;
  const [report, setReport] = useState(null);

  const [loadReport, { data, loading }] = useLazyQuery(GET_REPORT_BY_ID, {
    fetchPolicy: 'network-only',
    variables: { id: reportId },
  });

  useEffect(() => {
    if (!reportId) {
      history.push('/error-page');
    } else {
      loadReport();
    }
  }, [reportId, loadReport, history]);

  useEffect(() => {
    if (data && data.getReportById) {
      setReport(data.getReportById);
    }
  }, [data]);

  if (loading) {
    return <Spinner title="Please wait..." />;
  }

  if (!report) {
    return <ErrorPage />;
  }

  return (
    <ReportsView
      reports={[report]}
      selectedIndex={0}
    />
  );
};
