import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { NotificationManager } from 'react-notifications';
import { ErrorPage } from '../../pages/error-page';
import { ReportSectionTitle } from '../report-section-title/report-section-title';
import { ReportSection } from '../report-section';
import { useUser } from '../../utils/use-user';
import { buildExportData } from './helpers';

export const ReportsView = ({ reports, handleSaveReport, onPrevClick, onNextClick, selectedIndex, retailWeek }) => {
  const { user } = useUser();

  const [exportReportIds, setExportReportIds] = useState([]);
  const [isPrintVersion, setIsPrintVersion] = useState(false);
  const [isExportInProgress, setIsExportInProgress] = useState(false);

  const reportShareClick = () => {
    NotificationManager.success('The URL for the project has been copied to clipboard', 'Copied');
  };

  const handleReportSelect = (reportId) => {
    const currentSelectedIds = exportReportIds.includes(reportId)
    ? exportReportIds.filter((id) => id !== reportId)
    : [...exportReportIds, reportId];
    setExportReportIds(currentSelectedIds);
  };

  const handleExportClick = () => {
    setIsExportInProgress(true);
    import('xlsx').then((XLSX) => {
      const exportData = buildExportData(reports, exportReportIds, user);
      const workSheet = XLSX.utils.aoa_to_sheet(exportData);
      const workBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workBook, workSheet, 'Reports');
      XLSX.writeFile(workBook, `${retailWeek}-reports.xlsx`);
      setIsExportInProgress(false);
    });
  };

  const handlePrintClick = () => {
    setIsPrintVersion(true);
    setTimeout(() => {
      window.print();
      setIsPrintVersion(false);
    });
  };

  const report = reports[selectedIndex];

  if (!report) {
    return <ErrorPage message="Sorry there are no reports found for this week, choose another week or try again." />;
  }

  return (
    <>
      {!isPrintVersion ? (
        <ReportSectionTitle
          showNextButton={selectedIndex < reports.length - 1}
          showPreviousButton={selectedIndex > 0}
          nextReportClick={onNextClick}
          prevReportClick={onPrevClick}
          projectName={report.project.name}
          reportId={report.id}
          reportShareClick={reportShareClick}
          onExportClick={handleExportClick}
          onReportSelect={() => handleReportSelect(report.id)}
          isReportSelected={exportReportIds.includes(report.id)}
          hasSelectedReports={Boolean(exportReportIds.length)}
          isExportInProgress={isExportInProgress}
          onPrintClick={handlePrintClick}
        />
      ) : (
        <h1>{report?.name}</h1>
      )}
      <ReportSection
        report={report}
        handleSaveReport={handleSaveReport}
        user={user}
        isPrintVersion={isPrintVersion}
      />
    </>
  );
};

ReportsView.propTypes = {
  reports: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    project: PropTypes.shape({
      name: PropTypes.string,
    }),
  })),
  handleSaveReport: PropTypes.func,
  retailWeek: PropTypes.string,
};

ReportsView.defaultProps = {
  reports: [],
  retailWeek: '',
};
