import { buildAttributesDefaultValues } from '../../utils/build-attributes-default-values';

export const buildInitialValues = (project, attributes) => {
  return {
    name: project?.name || '',
    product: project?.product || '',
    program: project?.program || '',
    attributes: buildAttributesDefaultValues(attributes, project),
  };
};
